import React, { memo } from 'react';
import cn from 'classnames';
import styles from './index.module.scss';
import type * as I from './types/interfaces';
import { Partition } from './components/Partition';
import { PARTITIONS_COURSE_DATA } from '../../constants';
import { Container } from '../ui/Container';
import Frame1 from '../../images/frame1.gif';
import Frame2 from '../../images/frame2.gif';
import Frame3 from '../../images/frame3.gif';
import WhiteCloud from '../../images/white_clouds.svg';
import FlowersBg from '../../images/flowers_bg.svg';

const frameList = [Frame1, Frame2, Frame3];

export const PartitionsSection = memo(function PartitionsSection({
  className,
}: I.PartitionsSectionProps) {
  const classesRoot = cn(styles.root, className);

  return (
    <section className={classesRoot}>
      <div className={styles.backgroundImageFlower}>
        <img src={FlowersBg} alt="FlowersBg" />
      </div>
      <Container className={styles.inner}>
        <h2 className={styles.title}>{PARTITIONS_COURSE_DATA.title}</h2>
        <div className={styles.partitions}>
          {PARTITIONS_COURSE_DATA.partitions.map((partition, index) => (
            <div key={partition.id} className={styles.partition}>
              <Partition
                tag={partition.tag}
                srcImage={frameList[index]}
                srcIcon={partition.srcIcon}
                altImage={partition.altImage}
                title={partition.title}
                text={partition.text}
                withBuyButton={partition.withBuyButton}
                reverse={index % 2 === 1}
              />
            </div>
          ))}
        </div>
      </Container>
      <div className={styles.backgroundImage}>
        <img src={WhiteCloud} alt="WhiteCloud" />
      </div>
    </section>
  );
});
