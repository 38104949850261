import React, { memo, useState } from 'react';
import cn from 'classnames';
import styles from './index.module.scss';
import { TEXT } from '../../constants';

export const GameNowBlock = memo(function GameNowBlock({ link }: { link: string }) {
  const [isActivated, setIsActivated] = useState(false);
  const handleClick = () => {
    setIsActivated(false);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <div className={styles.header}>{TEXT.gameNow}</div>
        <a href={link} target="_blank" rel="noreferrer">
          <div
            className={cn(styles.button, isActivated && styles.button_activated)}
            onMouseDown={() => setIsActivated(true)}
            onMouseUp={handleClick}
          >
            {TEXT.tryFree}
          </div>
        </a>
      </div>
    </div>
  );
});
