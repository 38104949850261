import React, { useState } from 'react';
import { Header } from 'shared/components/Header';
import { Footer } from 'shared/components/Footer';
import { useEffectOnMount } from 'shared/hooks/useEffectOnMount';
import { fetchPaymentData } from 'shared/api/fetchPaymentData';
import { fetchProfileData, isAuthorizedProfile } from 'shared/api/fetchProfileData';
import { getPremium } from './helpers/getPremium';
import { Advantages } from './components/Advantages';
import { Comics } from './components/Comics';
import { MyEmotional } from './components/MyEmotional';
import { Slider } from './components/Slider';
import { Bottom } from './components/Bottom';
import { Description, Link, SLIDER, TEXT, Title, BASE_PRICE, PRICE } from './constants';
import styles from './EmotionalQuotient.module.scss';
import { TextBlock } from './components/TextBlock/TextBlock';

function EmotionalQuotient() {
  const [paymentLink, setPaymentLink] = useState('');
  const [basePrice, setBasePrice] = useState(BASE_PRICE);
  const [price, setPrice] = useState(PRICE);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isNeedBuyButton, setIsNeedBuyButton] = useState(false);
  const [isNeedLoginButton, setIsNeedLoginButton] = useState(false);

  useEffectOnMount(() => {
    async function fetchData() {
      if (process.env.NODE_ENV !== 'development') {
        try {
          const profileData = await fetchProfileData();

          if (isAuthorizedProfile(profileData)) {
            const [eqPremium, paymentOptions] = await Promise.all([
              getPremium(),
              fetchPaymentData('my_emotions'),
            ]);
            if (!eqPremium) {
              setIsNeedBuyButton(true);
              setPrice(paymentOptions.price);
              setBasePrice(paymentOptions.basePrice);
              setPaymentLink(paymentOptions.paymentPath);
            }
          } else {
            setIsNeedLoginButton(true);
          }
        } catch (error) {
          // eslint-disable-next-line no-console
          console.log('error:', error);
        }
        setIsLoaded(true);
      } else {
        setPaymentLink(Link.my_emotions);
        setIsLoaded(true);
      }
    }

    fetchData();
    document.title = Title.my_emotions;
  });

  if (!isLoaded) return null;

  return (
    <div className={styles.main}>
      <h1 className="visually-hidden">{Title.my_emotions}</h1>
      <Header type="gray" />
      <MyEmotional
        bubbleText={TEXT.description.bubble}
        description={Description.my_emotions}
        paymentLink={paymentLink}
        title={Title.my_emotions}
        price={price}
        basePrice={basePrice}
        isNeedBuyButton={isNeedBuyButton}
        isNeedLoginButton={isNeedLoginButton}
      />
      <Slider data={SLIDER} title={TEXT.title.slider} />
      <Advantages data={TEXT.advantages} title={TEXT.title.advantages} />
      <Comics data={TEXT.comics} title={TEXT.title.comics} />
      <TextBlock title={TEXT.textBlock.title} text={TEXT.textBlock.text} />
      <Bottom
        paymentLink={paymentLink}
        text={TEXT.bottom.bubble}
        price={price}
        basePrice={basePrice}
        isNeedBuyButton={isNeedBuyButton}
        isNeedLoginButton={isNeedLoginButton}
      />
      <Footer />
    </div>
  );
}

export { EmotionalQuotient };
