import React, { memo } from 'react';

import stylesCommon from '../../Programming2035.module.scss';
import styles from './SixthScreen.module.scss';

export const SixthScreen = memo(function SixthScreen() {
  return (
    <section className={styles['sixth-screen']}>
      <div className={styles.background} />
      <div className={styles.content}>
        <h2 className={stylesCommon.alwaysWhite}>1 300 000</h2>
        <p>
          школьников уже выбрали
          <br /> нас для изучения Python
        </p>
      </div>
    </section>
  );
});
