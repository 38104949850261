import React, { memo, useState } from 'react';
import { Accordion as AccordionProgram, useAccordionButton, Card } from 'react-bootstrap';
import cn from 'classnames';
import { TEXT } from '../../constants';
import { AccordionHeaderButtonProps } from '../../types';
import styles from './Program.module.scss';

const ShowAllButton = function ShowAllButton({ eventKey }: AccordionHeaderButtonProps) {
  const [isDisabled, disabled] = useState(false);
  const decoratedOnClick = useAccordionButton(eventKey, () => disabled(true));

  const styleButton = cn(styles.showAll, styles.animateFlash, {
    [styles.disabledButton]: isDisabled,
  });

  return (
    <div className={styleButton} onClick={decoratedOnClick}>
      {TEXT.showAll}
    </div>
  );
};

export const Program = memo(function Program() {
  return (
    <section className={styles.programSection}>
      <div className={styles.innerWrapper}>
        <h2 className={styles.title}>{TEXT.titles.program}</h2>
        <div className={styles.programListContainer}>
          <div className={styles.programList}>
            {TEXT.programCourse.map(({ text, nameImage }, index) =>
              index < 3 ? (
                <div className={styles.programItem} key={`program_${index + 1}`}>
                  <img
                    className={styles.imageProgram}
                    src={nameImage}
                    alt={`program_${index + 1}`}
                  />
                  <p className={styles.textProgram}>{text}</p>
                </div>
              ) : null,
            )}
          </div>
          <AccordionProgram bsPrefix={styles.customAccordion}>
            <Card bsPrefix={styles.customCard}>
              <AccordionProgram.Collapse eventKey="0" bsPrefix={styles.content}>
                <div className={styles.programList}>
                  {TEXT.programCourse.map(({ text, nameImage }, index) =>
                    index > 3 ? (
                      <div className={styles.programItem} key={`program_${index + 1}`}>
                        <img
                          className={styles.imageProgram}
                          src={nameImage}
                          alt={`program_${index + 1}`}
                        />
                        <p className={styles.textProgram}>{text}</p>
                      </div>
                    ) : null,
                  )}
                </div>
              </AccordionProgram.Collapse>
              <Card.Header bsPrefix={styles.accordionContainerButton}>
                <ShowAllButton eventKey="0" />
              </Card.Header>
            </Card>
          </AccordionProgram>
        </div>
      </div>
    </section>
  );
});
