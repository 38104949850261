import React, { memo } from 'react';
import { Python } from './components/Python';
import { Profession } from './components/Profession';
import styles from './LastScreen.module.scss';
import { BuyButton } from '../BuyButton';
import { ButtonBuyPlace } from '../../../WordsBattle/components/Buttons/types';

interface LastScreenProps {
  onBuyClick: (place: ButtonBuyPlace) => void;
  buyHref: string | null;
}

export const LastScreen = memo(function LastScreen({ buyHref, onBuyClick }: LastScreenProps) {
  return (
    <section className={styles.lastScreenSection}>
      <Python />
      <Profession />
      <BuyButton onClick={onBuyClick} href={buyHref} place={ButtonBuyPlace.bottom} />
    </section>
  );
});
