import React, { memo } from 'react';
import cn from 'classnames';
import { Slider } from 'shared/components/Slider';
import styles from './index.module.scss';
import type * as I from './types/interfaces';
import { Title } from '../../ui/Title';
import { REVIEWS_COURSE_DATA } from '../../../data';
import { Container } from '../../ui/Container';

const reviewsList = REVIEWS_COURSE_DATA.reviews.map(({ altImage, srcImage }) => (
  <img className={styles.image} key={altImage} alt={altImage} src={srcImage} />
));

export const ReviewsSection = memo(function ReviewsSection({ className }: I.ReviewsSectionProps) {
  const classesRoot = cn(styles.root, className);

  return (
    <section className={classesRoot}>
      <Container className={styles.inner}>
        <Title className={styles.title}>{REVIEWS_COURSE_DATA.title}</Title>
        <Slider className={styles.slider} itemComponents={reviewsList} />
        <div className={styles.grid}>{reviewsList}</div>
      </Container>
    </section>
  );
});
