import React, { memo } from 'react';
import DocumentMeta from 'react-document-meta';
import { Container, Row, Col } from 'react-bootstrap';
import { Header } from 'shared/components/Header';
import { Slider } from 'shared/components/Slider';
import { Accordion } from 'shared/components/Accordion';
import { CountdownTimer } from 'shared/components/CountdownTimer';

import { accordions } from './content';

export const itemComponents = [
  <img alt="" src={`${process.env.PUBLIC_URL}/temp/1.webp`} key={1} />,
  <img alt="" src={`${process.env.PUBLIC_URL}/temp/2_2.webp`} key={2_2} />,
  <img alt="" src={`${process.env.PUBLIC_URL}/temp/3.webp`} key={3} />,
];

const meta = {
  title: 'Base Template',
  description: 'this is the base template and nothing else',
  meta: {
    name: {
      keywords: 'react,meta,document,html,tags',
    },
  },
};

export const BaseTemplate = memo(function BaseTemplate() {
  return (
    <div className="baseTemplate-wrapper">
      <DocumentMeta {...meta} />
      <Container fluid>
        <Row>
          <Header type="gray" />
        </Row>
        <Row>
          <Col>
            <Slider itemComponents={itemComponents} />
          </Col>
        </Row>
      </Container>
      <Container>
        <Row>
          <Accordion accordions={accordions} />
        </Row>
        <Row>
          <CountdownTimer date={20.01} repeatAfterDays={7} />
        </Row>
      </Container>
    </div>
  );
});
