export async function pingProfile(successCb: () => void) {
  try {
    const response = await fetch(`${process.env.REACT_APP_PROG_2035_PROFILE_URL}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    const data = await response.json();
    if (data.premium) {
      successCb();
      return;
    }

    await new Promise<void>((resolve) => {
      window.setTimeout(resolve, 500);
    });
    await pingProfile(successCb);
  } catch (err) {
    throw new Error('something went wrong');
  }
}
