export const TEXT = {
  titles: {
    firstScreen: 'Программирование',
    courseStructure: 'Из чего состоит курс?',
    uniquePlatform: 'Уникальная платформа',
    convenientInterface: 'Удобный интерфейс',
    python: 'Что такое Python?',
    profession: 'Профессия будущего для\u00A0вашего ребенка',
  },
  courseStructureText:
    'Курс состоит из последовательных\nчастей — 9 островов, на которых\nпо ходу сюжета вводятся\nи отрабатываются новые темы.',
  simple: {
    title: 'От простого',
    text: 'Все начинается с азов: простого языка и небольших\nдискретных алгоритмов. Основная цель на этом\nэтапе — научиться писать и редактировать код.',
    image: 'https://static.uchi.ru/files/content-1-4/landings/programming/simple_img_new.png',
  },
  complicated: {
    title: 'К сложному',
    text: 'И заканчивается условными конструкциями,\nциклами и функциями. Полноценное знакомство\nс программированием на Python.',
    image: 'https://static.uchi.ru/files/content-1-4/landings/programming/complicated_img_new.png',
  },
  uniquePlatformList: [
    {
      text: 'Программирование развивает\nпространственное и логическое мышление,\nа также тренирует точность и аккуратность.',
      image: 'bulb',
    },
    {
      text: 'Уникальная среда разработки\nдля детей: писать код быстро,\nисправлять ошибки легко!',
      image: 'mouse',
    },
    {
      text: 'Наш курс — это не только увлекательная\nигра, но и начало большого пути\nв современной индустрии.',
      image: 'lever',
    },
    {
      text: 'Задания и комментарии в карточках\nозвучены: больше теории, меньше усилий.',
      image: 'speaker',
    },
    {
      text: 'Код на английском, но все понятно:\nновые слова вводятся постепенно,\nимеют озвучку и пиктограммы.',
      image: 'bus',
    },
    {
      text: 'Можно заниматься на интерактивной\nдоске, на компьютере или на телефоне\u00A0—\nудобно на любом устройстве!',
      image: 'cellphone',
    },
  ],
  python: [
    {
      text: 'Язык программирования Python входит в\u00A0пятерку самых популярных языков программирования в\u00A0мире.',
      image: 'https://static.uchi.ru/files/content-1-4/landings/programming/first.svg',
    },
    {
      text: 'У этого языка очень простой, краткий\nи читаемый синтаксис. Поэтому он отлично подходит для знакомства\nс программированием.',
      image: 'https://static.uchi.ru/files/content-1-4/landings/programming/second.svg',
    },
    {
      text: 'Он используется\nво множестве реальных приложений в тысячах компаний по всему миру.',
      image: 'https://static.uchi.ru/files/content-1-4/landings/programming/third.svg',
    },
  ],
  professionText:
    'Программирование тренирует логику, точность и\u00A0аккуратность. Это не\u00A0просто зарядка для\u00A0ума, а\u00A0начало большого пути в\u00A0современной индустрии.',
  cursList: [
    {
      text: 'Развитие цифровой\nграмотности',
      icon: 'screen',
    },
    {
      text: '150 заданий — больше\n20 часов обучения',
      icon: 'clock',
    },
    {
      text: 'Тренировка логики\nи внимательности',
      icon: 'brain',
    },
  ],
  text: 'курс по Python в игровой форме для учеников 1–9 классов',
  buy: 'Купить',
};

export const PAYMENT_LINK = 'https://uchi.ru/payments/products';
