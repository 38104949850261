// import { makeObservable } from 'mobx';

export class AppStore {
  // constructor() {
  //   super();
  //   makeObservable(this);
  // }
}

// makeObservable(AppStore, {
// });
