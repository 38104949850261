import { fetchProfileData, isAuthorizedProfile } from '../api/fetchProfileData';

export type ClickStreamEventParamsType = {
  eventSource: string;
  eventType: string;
  // Обычно stringified JSON, просто строка-строка или пустая строка
  eventInfo: string;
};

const EVENTS_HOST = process.env.REACT_APP_CLICKSTREAM_HOST || 'https://events.shot-uchi.ru';

export const sendEvent = ({ eventType, eventInfo, eventSource }: ClickStreamEventParamsType) =>
  fetchProfileData().then((data) => {
    const payload = {
      subject: 'uchiru.page_events',
      version: 2,
      payload: {
        full_url: window.location.href,
        created_at: '%%time%%',
        user_id: 0,
        user_type: 'student',
        guest_id: 0,
        event_source: eventSource,
        event_type: eventType,
        event_info: eventInfo,
        event_info_type: 'json',
        cd1: '',
        cd2: '',
        cd3: '',
      },
    };

    if (isAuthorizedProfile(data)) {
      payload.payload.user_id = data.id;
    }

    return fetch(EVENTS_HOST, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });
  });
