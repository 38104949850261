import React, { memo } from 'react';
import styles from './Demo.module.scss';
import { Button } from '../../shared/Button';

export const Demo = memo(function Demo({ link }: { link: string }) {
  return (
    <section className={styles.section_demo}>
      <div className={styles.window} />
      <div className={styles.demo_container}>
        <div className={styles.content}>
          <p className={styles.text}>Играйте прямо сейчас</p>
          <div className={styles.button_wrapper}>
            <Button
              link={link}
              btnClassName="green_btn"
              hasPrice={false}
              text="Попробовать бесплатно"
            />
          </div>
        </div>
      </div>
    </section>
  );
});
