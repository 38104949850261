import React, { memo } from 'react';
import { TEXT } from '../../constants';
import styles from './AboutBlock.module.scss';

const { title, subtitle } = TEXT.aboutBlock;

export const AboutBlock = memo(function AboutBlock() {
  return (
    <section className={styles.aboutBlock}>
      <div className={styles.ornament} />
      <h1 className={styles.title}>{title}</h1>
      <h2 className={styles.subtitle}>{subtitle}</h2>
      <div className={styles.image} />
    </section>
  );
});
