import React from 'react';
import { observer } from 'mobx-react';
import cn from 'classnames';

import styles from './TrainBlock.module.scss';
import { Screencast } from '../Screencast';

export const TrainBlock = observer(function TrainBlock() {
  return (
    <section className={styles.trainBlock}>
      <Screencast className={styles.screencast} variant={2} />
      <div className={styles.reeds}>
        <div className={cn(styles.reed, styles.firstReed)} />
        <div className={cn(styles.reed, styles.secondReed)} />
        <div className={cn(styles.reed, styles.thirdReed)} />
      </div>
    </section>
  );
});
