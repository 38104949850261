export const TEXT = {
  titles: {
    firstScreen: 'онлайн-курс «Планирование и достижение целей» для 1–4 классов',
    howItWorks: 'как это работает?',
    reviews: '<span>более 3 млн родителей</span> <br/> <span>доверяют обучение детей Учи.ру</span>',
    banner: 'попробуйте прямо сейчас!',
    courseTitle: 'Планирование и достижение целей',
  },
  firstScreenSubTitle: 'помогает добиваться успехов в учебе и жизни',
  listCurs: [
    {
      text: 'развивает ключевые навыки XXI века',
      icon: 'https://static.uchi.ru/files/content-1-4/landings/planning/icon_1.svg',
    },
    {
      text: 'помогает избегать стресса в учебе и в жизни',
      icon: 'https://static.uchi.ru/files/content-1-4/landings/planning/icon_2.svg',
    },
    {
      text: 'помогает детям больше успевать и меньше уставать',
      icon: 'https://static.uchi.ru/files/content-1-4/landings/planning/icon_3.svg',
    },
    {
      text: 'способствует более успешной учебе*',
      icon: 'https://static.uchi.ru/files/content-1-4/landings/planning/icon_4.svg',
    },
  ],
  howItWorksList: [
    'Интерактивный игровой формат уроков.',
    'Продуманная программа, разработанная методистами и педагогами.',
    'Задания включают в себя основы таких дисциплин, как «Тайм-менеджмент», «Планирование» и «Целеполагание».',
    'Онлайн-формат позволит заниматься где и когда удобно ребенку.',
  ],
  bannerText:
    'Курс «Планирование и достижение целей» учит ребенка эффективно организовать свое время, помогает повышать успеваемость в школе* и добиваться успеха в жизни.',
  reviewsList: [
    {
      text: 'Очень доступно для понимания. Ребенок сам с удовольствием занимается, без помощи взрослых, помогает дублирование текста голосовыми подсказками. Интерфейс понятный, яркий, детский. Очень мотивируют награды за достижения. Довольны чрезвычайно!',
      name: 'Моро Валери',
      subTitle: 'Мама первоклассника',
    },
    {
      text: 'Ребенок развивается быстрее своих одноклассников. Лучше усваивает школьный материал, который дает преподаватель.',
      name: 'Куус Инна Викторовна',
      subTitle: 'мама третьеклассника',
    },
  ],
  saleTimer: 'скидка действует:',
  sonyText: 'В «Осеннюю распродажу» курсы доступнее!',
  allInclusive: {
    title: 'всё включено',
    text: '<span>Все школьные курсы</span><br/>Курс по программированию<br/>Курс по планированию<br/>«Тайная лаборатория»<br/>Все развивающие игры<br/>Доступ к прошедшим олимпиадам',
  },
  access: 'доступ на год',
  everything: 'успею всё!',
  valuta: '₽',
};
