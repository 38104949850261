import React, { memo } from 'react';
import ReactHtmlParser from 'react-html-parser';
import cn from 'classnames';
import { BuyButton } from '../BuyButton';
import { MyEmotionalProps } from './types';
import styles from './MyEmotional.module.scss';
import { LoginButton } from '../LoginButton';

const URL =
  'https://static.uchi.ru/files/content-1-4/landings/emotional-quotient/images/my-emotional';

const imageLink = {
  picture: `${URL}/picture.svg`,
};

export const MyEmotional = memo(function MyEmotional({
  bubbleText,
  description,
  paymentLink,
  title,
  price,
  basePrice,
  isNeedBuyButton,
  isNeedLoginButton,
}: MyEmotionalProps) {
  return (
    <div className={styles.main}>
      <div className={styles.contentWrapper}>
        <div className={styles.content}>
          <h2 className={styles.title}>{title}</h2>
          <div className={styles.description}>{ReactHtmlParser(description)}</div>
          {isNeedBuyButton && (
            <BuyButton
              className={styles.buyButton}
              paymentLink={paymentLink}
              place="top"
              price={price}
              basePrice={basePrice}
            />
          )}
          {isNeedLoginButton && <LoginButton place="top" className={styles.loginButton} />}
          <div className={styles.pictureContainer}>
            <div
              className={cn(styles.backgroundStatic, styles.picture)}
              style={{
                backgroundImage: `url(${imageLink.picture})`,
              }}
            />
            <div className={styles.bubble}>{ReactHtmlParser(bubbleText)}</div>
          </div>
        </div>
      </div>
    </div>
  );
});
