import React, { memo } from 'react';

import { TEXT } from '../../constants';

import styles from './ReadingText.module.scss';

export const ReadingText = memo(function ReadingText() {
  return (
    <section className={styles.ReadingTextSection}>
      <h2>{TEXT.titles.readingText}</h2>
      <p className={styles.readingText}>{TEXT.readingText}</p>
    </section>
  );
});
