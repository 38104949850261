import React, { memo } from 'react';
import cn from 'classnames';
import ReactHtmlParser from 'react-html-parser';
import styles from './index.module.scss';
import * as I from './types/interfaces';
import { ReactComponent as BubbleEggSVG } from './imgs/bubble_egg.svg';
import { ReactComponent as BubbleAnglesSVG } from './imgs/bubble_four_angles.svg';

export const Bubble = memo(function Bubble({
  color,
  shape,
  text,
  children,
  classNameRoot,
  classNameBubble,
  classNameText,
}: I.BubbleProps) {
  const shapes = {
    egg: BubbleEggSVG,
    fourAngles: BubbleAnglesSVG,
  };
  const BubbleImg = shapes[shape];
  const rootClasses = cn(styles.root, classNameRoot);
  const bubbleClasses = cn(styles.bubble, styles[`bubble__color_${color}`], classNameBubble);
  const textClasses = cn(styles.text, classNameText);
  return (
    <div className={rootClasses}>
      <BubbleImg className={bubbleClasses} />
      <p className={textClasses}>{ReactHtmlParser(text)}</p>
      {children}
    </div>
  );
});
