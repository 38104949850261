import React, { memo } from 'react';
import { TEXT } from '../../constants';
import styles from './Checkout.module.scss';
import { ButtonProps } from '../../types';

export const Checkout = memo(function Checkout({ button }: ButtonProps) {
  return (
    <section className={styles.checkoutSection}>
      <div className={styles.container}>
        <h2 className={styles.title}>{TEXT.titles.mainTitle}</h2>
        <ul className={styles.list}>
          {TEXT.checkoutList.map((text, index) => (
            <li className={styles.item} key={`item_${index + 1}`}>
              {text}
            </li>
          ))}
        </ul>
        {button}
      </div>
    </section>
  );
});
