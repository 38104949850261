import React, { memo } from 'react';
import cn from 'classnames';
import styles from './index.module.scss';
import { OptionType } from '../../types';

export const ResultCard = memo(function ResultCard({ text, id }: OptionType) {
  return (
    <div className={cn(styles.wrapper, styles[`wrapper_${id}`])}>
      <div className={cn(styles.illustration, styles[`illustration_${id}`])} />
      <div className={styles.text}>{text}</div>
    </div>
  );
});
