import React, { memo } from 'react';
import cn from 'classnames';
import { TEXT } from '../../constants';
import styles from './FeatureList.module.scss';

export const FeatureList = memo(function FeatureList() {
  return (
    <section className={styles.featureListSection}>
      <div className={styles.featureList}>
        {TEXT.featureList.map((text, index) => (
          <div className={cn(styles.featureItem, styles[`featureItem_${index + 1}`])} key={text}>
            <div className={cn(styles.image, styles[`image_${index + 1}`])} />
            <p className={styles.text}>{text}</p>
          </div>
        ))}
      </div>
    </section>
  );
});
