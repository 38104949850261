import React, { memo } from 'react';
import cn from 'classnames';

import { ComicsProps } from './types';
import styles from './Comics.module.scss';
import sprite1 from '../../images/comics-1.gif';
import sprite2 from '../../images/comics-2.svg';
import sprite3 from '../../images/comics-3.svg';
import sprite4 from '../../images/comics-4.gif';

type ImageLinkType = { [key: number]: string };

const imageLink: ImageLinkType = {
  0: sprite1,
  1: sprite2,
  2: sprite3,
  3: sprite4,
};

const Comics = memo(function Comics({ data, title }: ComicsProps) {
  return (
    <div className={styles.main}>
      <div className={styles.contentWrapper}>
        <h2 className={styles.title}>{title}</h2>
        <div className={styles.comicsList}>
          {data.map((text, i) => {
            const index = i;
            return (
              <div key={text} className={cn(styles.item, styles[`item_${i + 1}`])}>
                <div className={cn(styles.backgroundStatic, styles.item_image)}>
                  <img src={imageLink[index]} alt="gif" />
                </div>
                <div className={styles.item_wrap}>
                  <div className={styles.item_wrap_text}>{text}</div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
});

export { Comics };
