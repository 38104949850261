import React, { memo, useEffect, useState } from 'react';
import styles from './Training.module.scss';
import leftBrick from '../../images/bricks_3.svg';
import leftTripleBrick from '../../images/brick_left_full_triple.svg';
import rightBrick from '../../images/bricks_2.svg';
import leftLamp from '../../images/lamp_left.svg';
import rightLamp from '../../images/lamp_right.svg';
import plant from '../../images/plant.svg';
import { TRAINING_ITEM } from '../../constants';
import { TrainingItem } from '../TrainingItem/TrainingItem';

export const Training = memo(function Training() {
  const [width, setWidth] = useState(window.innerWidth);

  const updateDimensions = () => {
    setWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener('resize', updateDimensions);
    return () => window.removeEventListener('resize', updateDimensions);
  }, []);

  return (
    <section className={styles.section_training}>
      <div className={styles.plant}>
        <img src={plant} alt="plant" />
      </div>
      <div className={styles.bricks}>
        <div className={styles.left_brick}>
          <img src={width >= 1024 ? leftTripleBrick : leftBrick} alt="left_brick" />
        </div>
        <div className={styles.right_brick}>
          <img src={rightBrick} alt="right_brick" />
        </div>
      </div>

      <div className={styles.lamps}>
        <div className={styles.lamp_left}>
          <img src={leftLamp} alt="lamp_left" />
        </div>
        <div className={styles.lamp_right}>
          <img src={rightLamp} alt="lamp_right" />
        </div>
      </div>
      <div className={styles.wrapper}>
        <h2 className={styles.header}>Тренажер поможет ребенку:</h2>
        <div className={styles.block_container}>
          {TRAINING_ITEM &&
            TRAINING_ITEM.map((item) => (
              <TrainingItem key={item.content} content={item.content} position={item.position} />
            ))}
        </div>
      </div>
    </section>
  );
});
