import React, { memo } from 'react';
import { BUBBLE_TEXTS } from 'components/pages/Anatomy/data';
import styles from './index.module.scss';
import { ParallaxBlock } from '../../ui/ParallaxBlock';
import { Bubble } from '../../ui/Bubble';

export const MetabolismSection = memo(function MetabolismSection() {
  return (
    <ParallaxBlock className={styles.root}>
      <Bubble
        color="blue"
        shape="fourAngles"
        text={BUBBLE_TEXTS.metabolism}
        classNameRoot={styles.bubbleRoot}
        classNameBubble={styles.bubble}
      />
      <div className={styles.bodyImage} />
    </ParallaxBlock>
  );
});
