import React, { memo } from 'react';
import ReactHtmlParser from 'react-html-parser';

import { TEXT } from '../../constants';
import styles from './Reviews.module.scss';

export const Reviews = memo(function Reviews() {
  return (
    <section className={styles.reviewsSection}>
      <h2 className={styles.title}>{ReactHtmlParser(TEXT.titles.reviews)}</h2>
      <div className={styles.wrapperReviews}>
        <div className={(styles.review, styles.reviewFirst)}>
          <div className={styles.symbol} />
          <div className={styles.text}>{TEXT.reviewsList[0].text}</div>
          <div className={styles.name}>{TEXT.reviewsList[0].name}</div>
          <div className={styles.subTitle}>{TEXT.reviewsList[0].subTitle}</div>
        </div>
        <div className={(styles.review, styles.reviewSecond)}>
          <div className={styles.symbol} />
          <div className={styles.text}>{TEXT.reviewsList[1].text}</div>
          <div className={styles.name}>{TEXT.reviewsList[1].name}</div>
          <div className={styles.subTitle}>{TEXT.reviewsList[1].subTitle}</div>
        </div>
      </div>
    </section>
  );
});
