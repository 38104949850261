import React, { memo } from 'react';
import cn from 'classnames';
import { Button as ButtonBase } from 'shared/components/Button';
import { useContextAnatomy } from 'components/pages/Anatomy/context';
import { BUTTON_DATA } from 'components/pages/Anatomy/data';
import styles from './index.module.scss';
import type * as I from './types/interfaces';

export const Button = memo(function Button({ className }: I.ButtonProps) {
  const { type, paymentLink } = useContextAnatomy();
  const contentButton = BUTTON_DATA[type];
  const href = type === 'notBought' && paymentLink ? paymentLink : contentButton.link;

  return (
    <ButtonBase
      text={contentButton.text}
      href={href}
      className={cn(styles.root, className)}
      defaultOnClick
    />
  );
});
