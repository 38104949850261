import React, { memo } from 'react';
import { BounceLoader } from 'react-spinners';
import { Header } from 'shared/components/Header';
import { Programming2035Props } from './types';
import { pingProfile } from './api/pingProfile';
import { triggerRequest } from './api/triggerRequest';

import { FirstScreen } from './components/FirstScreen';
import { SecondScreen } from './components/SecondScreen';
import { ThirdScreen } from './components/ThirdScreen';
import { FourthScreen } from './components/FourthScreen';
import { FifthScreen } from './components/FifthScreen';
import { SixthScreen } from './components/SixthScreen';
import { SeventhScreen } from './components/SeventhScreen';

import styles from './Programming2035.module.scss';

export const Programming2035 = memo(function Programming2035({
  isProcessing,
}: Programming2035Props) {
  const redirectToProgramming = React.useCallback(() => {
    window.location.replace('https://uchi.ru/progpython/');
  }, []);

  React.useEffect(() => {
    if (isProcessing) {
      triggerRequest();
      pingProfile(redirectToProgramming);
    }
  }, []);

  return (
    <div className={styles.main}>
      <Header type="gray" isLkLinkHidden />
      <section className={styles.section}>
        {isProcessing ? (
          <div className={styles.loaderWrapper}>
            <div className={styles.spinnerContainer}>
              <BounceLoader color="#ff2c78" size={100} />
            </div>
            <div className={styles.loaderText}>
              Минуточку! Направляем тебя на курс &quot;Программирование&quot;
            </div>
          </div>
        ) : (
          <>
            <FirstScreen />
            <SecondScreen />
            <ThirdScreen />
            <FourthScreen />
            <FifthScreen />
            <SixthScreen />
            <SeventhScreen />
          </>
        )}
      </section>
    </div>
  );
});
