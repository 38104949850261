import React, { memo } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { MAIN_BANNER_DATA } from 'components/pages/Anatomy/data';
import styles from './index.module.scss';
import LogoSVG from './imgs/logo.png';
import { ParallaxBlock } from '../../ui/ParallaxBlock';
import { Button } from '../../ui/Button';

export const MainBannerSection = memo(function MainBannerSection() {
  const { targetAudience, title, subtitle } = MAIN_BANNER_DATA;

  return (
    <ParallaxBlock className={styles.root}>
      <div className={styles.container}>
        <header className={styles.header}>
          <a href="https://uchi.ru">
            <img alt="uchi.ru" src={LogoSVG} className={styles.header__logo} />
          </a>
          <p className={styles.header__text}>{ReactHtmlParser(targetAudience)}</p>
        </header>
        <main className={styles.main}>
          <h1 className={styles.main__title}>
            <span className={styles.main__title_text}>{ReactHtmlParser(title[0])}</span>
            <span className={styles.main__title_text}>{ReactHtmlParser(title[1])}</span>
          </h1>
          <p className={styles.main__subtitle}>{ReactHtmlParser(subtitle)}</p>
        </main>
        <div className={styles.button__wrapper}>
          <Button />
        </div>
      </div>
    </ParallaxBlock>
  );
});
