import React, { memo, useRef, useState } from 'react';
import { CSSTransition } from 'react-transition-group';

import { ItemProps } from './types';
import styles from './Item.module.scss';
import transitionStyles from './transition.module.scss';

export const Item = memo(function Item({
  component,
  isFadeAnimating,
  isVisible,
  order,
}: ItemProps) {
  const currentItemRef = useRef<HTMLDivElement>(null);
  const [isExiting, setExiting] = useState(false);
  const left = isFadeAnimating && isExiting ? `${(1 - order) * 100}%` : 0;

  const itemComponent = (
    <div className={styles.wrapper} ref={currentItemRef} style={{ left }}>
      {component}
    </div>
  );

  if (!isFadeAnimating) return itemComponent;

  return (
    <CSSTransition
      classNames={transitionStyles}
      in={isVisible}
      nodeRef={currentItemRef}
      timeout={500}
      onExiting={() => setExiting(true)}
      onExited={() => setExiting(false)}
    >
      {itemComponent}
    </CSSTransition>
  );
});
