import React, { memo } from 'react';
import cn from 'classnames';
import styles from './index.module.scss';
import type * as I from './types/interfaces';

export const BackButton = memo(function BackButton({
  children,
  className,
  ...otherProps
}: I.BackButtonProps) {
  const classesRoot = cn(styles.root, className);

  return (
    <button type="button" {...otherProps} className={classesRoot}>
      <div className={styles.inner}>
        <svg
          className={styles.svg}
          viewBox="0 0 30 31"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M22.5 15.042C22.5 15.8397 21.8527 16.4864 21.0542 16.4864L12.35 16.4864L14.869 19.0946C15.4235 19.6686 15.4071 20.583 14.8325 21.137C14.2579 21.6909 13.3427 21.6746 12.7882 21.1005L7.90539 16.045C7.36487 15.4854 7.36487 14.5986 7.90539 14.039L12.7882 8.98345C13.3427 8.40938 14.2579 8.39307 14.8325 8.947C15.4071 9.50094 15.4235 10.4154 14.869 10.9894L12.35 13.5975L21.0542 13.5976C21.8527 13.5976 22.5 14.2443 22.5 15.042Z"
            fill="#393939"
          />
        </svg>
      </div>
    </button>
  );
});
