import React, { memo } from 'react';
import { ReactComponent as IconSVG } from '../../images/check.svg';
import styles from './index.module.scss';

interface MarkCard {
  mark: string;
}

export const FooterMarks = memo(function FooterMarks({ mark }: MarkCard) {
  return (
    <div className={styles.marks_container}>
      <IconSVG />
      <p className={styles.mark}>{mark}</p>
    </div>
  );
});
