import React, { memo } from 'react';
import cn from 'classnames';
import styles from './index.module.scss';
import { FeedbackCardType } from '../../types';

export const FeedbackCard = memo(function FeedbackCard({ ...feedback }: FeedbackCardType) {
  const { id, author, count, text } = feedback;
  return (
    <div className={cn(styles.wrapper, styles[`wrapper_${id}`])}>
      <div className={styles.author}>{author}</div>
      <div className={styles.text}>{text}</div>
      <div className={styles.count}>
        {[...Array(count)].map((_, i) => (
          <div key={String(i)} className={styles.star} />
        ))}
      </div>
    </div>
  );
});
