import React from 'react';
import { observer } from 'mobx-react';
import cn from 'classnames';
import { TEXT } from '../../constants';
import styles from './Preview.module.scss';

export const Preview = observer(function Preview() {
  return (
    <section className={styles.previewSection}>
      <h2 className={styles.title}>{TEXT.titles.preview}</h2>
      <div className={styles.previewFeatures}>
        {TEXT.previewFeatures.map(({ text, type }) => (
          <div className={styles.previewFeature} key={text}>
            <div
              className={cn(styles.previewFeatureImage, styles[`previewFeatureImage_${type}`])}
            />
            <p className={styles.previewFeatureText}>{text}</p>
          </div>
        ))}
      </div>
    </section>
  );
});
