import React from 'react';
import cn from 'classnames';
import { observer } from 'mobx-react';
import { TEXT } from '../../constants';
import { ButtonType } from '../../types';
import { Button } from '../Button';
import { Badge } from '../Badge';
import { Classroom } from '../Classroom';
import { Price } from '../Price';
import styles from './BottomBlock.module.scss';

const { access, updates, copyright } = TEXT.bottomBlock;

export const BottomBlock = observer(function BottomBlock() {
  return (
    <section className={styles.bottomBlock}>
      <div className={cn(styles.ornament, styles.ornamentTop)} />
      <Badge className={styles.badge} />
      <div className={styles.textAccess}>{access}</div>
      <div className={styles.textUpdates}>{updates}</div>
      <Button type={ButtonType.PLAY} className={styles.buttonPlay} />
      <Button type={ButtonType.BUY} className={styles.buttonBuy} />
      <Classroom className={styles.classroom} />
      <Price className={styles.price} />
      <div className={cn(styles.ornament, styles.ornamentBottom)}>
        <div className={styles.duck} />
        <div className={styles.duck} />
        <div className={styles.copyright}>{copyright}</div>
      </div>
    </section>
  );
});
