import React, { memo } from 'react';
import styles from './LastScreen.module.scss';
import { TEXT } from '../../constants';
import Number1 from '../../images/1.svg';
import Number2 from '../../images/2.svg';
import Number3 from '../../images/3.svg';

const listNumbers = [Number1, Number2, Number3];

export const LastScreen = memo(function LastScreen() {
  return (
    <section className={styles.lastScreenSection}>
      <div className={styles.pythonSection}>
        <h2 className={styles.title}>{TEXT.title.python}</h2>
        <div className={styles.wrapperList}>
          {TEXT.python.map(({ text }, index) => (
            <div className={styles.elemList} key={text}>
              <div className={styles.wrapperImage}>
                <img className={styles.imageNumber} src={listNumbers[index]} alt="number" />
              </div>
              <p className={styles.text}>{text}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
});
