import React, { memo } from 'react';
import cn from 'classnames';
import styles from './index.module.scss';

import { OptionType } from '../../types';

export const Goal = memo(function Goal({ text, id }: OptionType) {
  return (
    <div className={styles.wrapper}>
      <div className={cn(styles.illustration, styles[`illustration_${id}`])} />
      <div className={styles.text}>{text}</div>
    </div>
  );
});
