export const accordions = [
  {
    content:
      'Неограниченный доступ к курсу со всеми дальнейшими обновлениями. Когда он выйдет полностью, цена может повыситься, но вам не нужно будет ничего доплачивать.',
    header: 'Что я получу после оплаты?',
  },
  {
    content:
      'Мы планируем выпустить курс полностью в ноябре. До этого новые задания будут постепенно открываться тем, кто оплатил курс.',
    header: 'Когда я получу полный доступ к курсу?',
  },
  {
    content:
      'При создании материалов мы сочетаем отработанные методики Учи.ру и данные исследований в области нейрофизиологии и нейропсихологии. Каждое задание тестирует группа учеников 1–4 классов — и по результатам мы корректируем курс.',
    header: 'Почему вы уверены в эффективности курса?',
  },
];
