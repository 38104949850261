import React from 'react';
import { observer } from 'mobx-react';
import { sendEvent } from 'shared/helpers/requests';
import { TEXT } from '../../constants';
import { store } from '../../store';
import { Button } from '../Button';
import styles from './FirstScreen.module.scss';

interface FirstScreenProps {
  detailsSectionRef: React.RefObject<HTMLDivElement>;
}

export const FirstScreen = observer(function FirstScreen({ detailsSectionRef }: FirstScreenProps) {
  const onPlayButtonClick = () => {
    sendEvent({
      eventType: 'landing_click_play',
      eventSource: store.eventSource,
      eventInfo: '',
    });

    window.location.assign(store.adaptedCourseUrl);
  };

  const onDetailsButtonClick = () => {
    sendEvent({
      eventType: 'landing_click_details',
      eventSource: store.eventSource,
      eventInfo: '',
    });

    detailsSectionRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <section className={styles.firstScreenSection}>
      <div className={styles.innerWrapper}>
        <div className={styles.parallelBadge}>
          <span className={styles.parallelBadgeText}>{TEXT.parallelBadge(0)}</span>
        </div>
        <h1 className={styles.title}>{TEXT.titles.firstScreen}</h1>
        <p className={styles.subtitle}>{TEXT.firstScreenSubtitle}</p>
        <div className={styles.buttonsWrapper}>
          <Button type="details" onClick={onDetailsButtonClick} />
          <Button type="play" onClick={onPlayButtonClick} />
        </div>
      </div>
    </section>
  );
});
