import { UCHI_DOMAIN } from 'shared/constants';

export const META = {
  title: 'Умножай урожай',
};

export const COURSE_URL = `${UCHI_DOMAIN}/harvest-math`;

export const EVENT = {
  source: 'harvest_math',
  play: 'landing_button_play',
  buy: 'landing_button_buy',
};

export const TEXT = {
  button: {
    tryFree: {
      text: 'Попробовать бесплатно',
      link: COURSE_URL,
    },
    play: {
      text: 'Играть',
      link: COURSE_URL,
    },
    buy: {
      text: 'Купить',
      // Ссылка на оплату получается из `store.paymentUrl`
      link: '',
    },
    purchased: {
      text: 'Куплено',
    },
  },
  topBlock: {
    subtitle: 'Тренажер таблицы умножения для учеников 1–4 классов',
  },
  faqBlock: {
    title: 'Вопросы и ответы',
    questions: [
      {
        title: 'Что появится в тренажере?',
        description:
          'Сейчас доступны две фермы с тренировкой умножения от 2 до 10. Мы планируем выпустить ещё несколько ферм с повышенной сложностью. Будет больше заданий, предметов в магазине и призов.',
      },
      {
        title: 'Почему стоит приобрести сейчас?',
        description:
          'Купив тренажер сейчас, вы фиксируете цену покупки. Для других пользователей цена будет повышаться по мере готовности тренажера.',
      },
      {
        title: 'На какой возраст рассчитан тренажер?',
        description: 'На учеников 1–4 классов.',
      },
      {
        title: 'Когда тренажер будет доступен?',
        description: 'Мы планируем выпускать тренажер по частям. Первая часть уже доступна.',
      },
    ],
  },
  bottomBlock: {
    subtitle: 'Таблица умножения в формате игры',
  },
};
