import React, { memo, useCallback } from 'react';
import cn from 'classnames';

import { sendEvent } from 'shared/helpers/requests';
import { Button } from 'shared/components/Button';
import { EVENT_BUY, TEXT } from '../../constants';
import { BuyButtonProps } from './types';
import styles from './BuyButton.module.scss';

const BuyButton = memo(function BuyButton({
  className,
  paymentLink,
  place,
  theme = 'white',
  basePrice,
  price,
}: BuyButtonProps) {
  const { buttonBuy } = TEXT;

  const clickPaymentButton = useCallback(() => {
    sendEvent({
      eventType: EVENT_BUY.type,
      eventSource: EVENT_BUY.source,
      eventInfo: JSON.stringify({ place }),
    });
  }, [place]);

  return (
    <div className={cn(styles.button, className)}>
      <a href={paymentLink} target="_blank" rel="noreferrer">
        <Button
          callback={clickPaymentButton}
          className={styles.innerButton}
          defaultOnClick={false}
          text={buttonBuy.text}
        />
      </a>
      <div className={cn(styles.price, styles[`price_${theme}`])}>{price} ₽</div>
    </div>
  );
});

export { BuyButton };
