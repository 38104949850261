import React, { memo } from 'react';
import { ReactComponent as IconSVG } from '../../images/logo.svg';
import styles from './index.module.scss';
import { FooterMarks } from '../FooterMarks/FooterMarks';
import { FOOTER_MARKS } from '../../constants';
import { Button } from '../../shared/Button';

export const Footer = memo(function Footer({ link }: { link: string | null }) {
  return (
    <section className={styles.footer_section}>
      <div className={styles.wrapper}>
        <div className={styles.logo}>
          <IconSVG className={styles.pets} />
        </div>

        <div className={styles.footer_marks}>
          {FOOTER_MARKS.map((mark, i) => (
            <FooterMarks key={mark} mark={mark} />
          ))}
        </div>
        <div className={styles.button_wrapper}>
          <Button link={link} btnClassName="orange_btn" hasPrice text="Купить курс" />
        </div>
      </div>
    </section>
  );
});
