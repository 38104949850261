import React, { memo } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { CountdownTimer } from 'shared/components/CountdownTimer';
import { PriceBlockProps } from '../../types';
import { TEXT } from '../../constants';

import styles from './PriceBlock.module.scss';

const urlImageSonya = 'https://static.uchi.ru/files/content-1-4/landings/planning/sonya.svg';

export const PriceBlock = memo(function PriceBlock({
  basePrice,
  price,
  sale,
  saleYear,
  priceYear,
  basePriceYear,
  date,
}: PriceBlockProps) {
  return (
    <section className={styles.priceBlockSection}>
      <div className={styles.container}>
        <div className={styles.allInclusiveContainer}>
          <div className={styles.sonyaElement}>
            <div className={styles.sonyaBubble}>
              <p>{TEXT.sonyText}</p>
            </div>
            <img className={styles.sonyaImage} src={urlImageSonya} alt="sonya" />
          </div>
          <div className={styles.topBlock}>
            <div className={styles.sale}>{saleYear}</div>
            <div className={styles.access}>{TEXT.access}</div>
          </div>
          <div className={styles.title}>{TEXT.allInclusive.title}</div>
          <div className={styles.text}>{ReactHtmlParser(TEXT.allInclusive.text)}</div>
          <div className={styles.priceContainer}>
            <div className={styles.priceWrapper}>
              <div className={styles.basePrice}>
                <del>{`${basePriceYear} ${TEXT.valuta}`}</del>
              </div>
              <div className={styles.price}>{`${priceYear} ${TEXT.valuta}`}</div>
            </div>
            <div className={styles.buttonWrapper}>button</div>
          </div>
        </div>
        <div className={styles.rightContainer}>
          <div className={styles.everythingContainer}>
            <div className={styles.topBlock}>
              <div className={styles.sale}>{sale}</div>
              <div className={styles.access}>{TEXT.access}</div>
            </div>
            <div className={styles.title}>{TEXT.everything}</div>
            <div className={styles.priceContainer}>
              <div className={styles.priceWrapper}>
                <div className={styles.basePrice}>
                  <del>{`${basePrice} ${TEXT.valuta}`}</del>
                </div>
                <div className={styles.price}>{`${price} ${TEXT.valuta}`}</div>
              </div>
              <div className={styles.buttonWrapper}>button</div>
            </div>
          </div>
          <CountdownTimer color="black" date={date} isLabelsVisible repeatAfterDays={7} size="m" />
        </div>
      </div>
    </section>
  );
});
