import { UCHI_DOMAIN } from '../constants';
import { ApiErrorResponseData } from '../types';

// Источник на бэке ↓ или более свежий
// https://github.com/uchiru/uchiru-login/blob/f477afedc04eacf6d5f0c524aa9f66d5535b4103/app/serializers/profile/api/students/v1/student_serializer.rb#L5-L5
export type ProfileData = {
  id: number;
  name: string;
  surname: string;
  gender: 'female' | 'male';
  class_book_parallel: number;
  class_book_letter: string;
  avatar: string;
  email: string | null;
  student_type: 'b2t' | 'b2c';
  any_premiums: boolean;
  can_pay: boolean;
  parent_code: string;
  parent_present: boolean;
  can_skip_cards: boolean;
  disabled_character_room: boolean;
  current_time: string;
  features: {
    daily_events_available: boolean;
    online_lessons_available: boolean;
    character_room_available: boolean;
  };
};

export const fetchProfileDataResponse = () =>
  fetch(`${UCHI_DOMAIN}/profile/api/students/v1/students`, {
    credentials: 'include',
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  });

export const fetchProfileData = (): Promise<ProfileData | ApiErrorResponseData> =>
  fetchProfileDataResponse().then((r) => r.json());

export const isAuthorizedProfile = (
  profileData: ProfileData | ApiErrorResponseData,
): profileData is ProfileData => !('errors' in profileData && profileData.errors.length > 0);
