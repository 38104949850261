import React, { memo } from 'react';
import cn from 'classnames';
import ReactHtmlParser from 'react-html-parser';
import styles from './index.module.scss';
import type * as I from './types/interfaces';

export const Partition = memo(function Partition({
  tag,
  srcImage,
  altImage,
  title,
  text,
  className,
  reverse = false,
}: I.PartitionProps) {
  const classesRoot = cn(styles.root, { [styles.root_reverse]: reverse }, className);

  return (
    <div className={classesRoot}>
      <div className={styles.textBox}>
        <span className={styles.tag} style={{ color: `#${tag.colorHex}` }}>
          {ReactHtmlParser(tag.text)}
        </span>
        <p className={styles.title}>{ReactHtmlParser(title)}</p>
        <p className={styles.text}>{ReactHtmlParser(text)}</p>
      </div>
      <div className={styles.imageBox}>
        <img className={styles.image} src={srcImage} alt={altImage} />
      </div>
    </div>
  );
});
