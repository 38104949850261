import React, { memo } from 'react';
import { TEXT } from '../../../../constants';
import styles from './Cloud.module.scss';
import { BuyButton } from '../../../BuyButton';
import { ButtonBuyPlace } from '../../../../../WordsBattle/components/Buttons/types';

interface CloudProps {
  onBuyClick: (place: ButtonBuyPlace) => void;
  buyHref: string | null;
}

export const Cloud = memo(function Cloud({ onBuyClick, buyHref }: CloudProps) {
  return (
    <section className={styles.cloudSection}>
      <div className={styles.cloudWrapper}>
        <div className={styles.icon} />
        <h1 className={styles.title}>{TEXT.titles.firstScreen}</h1>
        <p className={styles.text}>{TEXT.text}</p>
        <BuyButton onClick={onBuyClick} href={buyHref} place={ButtonBuyPlace.top} />
      </div>
    </section>
  );
});
