import { makeAutoObservable, flow } from 'mobx';
import { SetNonNullable } from 'type-fest';
import { UCHI_DOMAIN } from 'shared/constants';
import { getUchiLoginRedirectUrl } from 'shared/helpers/getUchiLoginRedirectUrl';
import { getUnauthorizedPaymentRedirectChainUrl } from 'shared/helpers/paymentRedirect';
import { COURSE_URL } from './constants';

class MagicMathStore {
  isReady = false;

  studentId: number | null = null;

  studentParallel: number | null = null;

  hasPremium = false;

  price = 0;

  basePrice = 0;

  // По умолчанию не авторизованы, пока не вызвали `init` с платёжной инфой.
  paymentUrl = getUnauthorizedPaymentRedirectChainUrl();

  // Класс курса, который или выбранный руками, или определённый
  // внутренней бэковой логикой по реальному классу ученика.
  // Некоторое содержимое лендинга зависит именно от класса курса,
  // и приходится ходить к бэку ММ.
  courseParallel: 1 | 3 | 5 | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  get isAuthorized() {
    return !!this.studentId;
  }

  get adaptedCourseUrl() {
    return this.isAuthorized ? COURSE_URL : getUchiLoginRedirectUrl(COURSE_URL);
  }

  get eventSource() {
    // Дичайшая дичь, но к величайшему сожалению, аналитика уже заточена на эту
    // извращённую и местами нелогичную вариацию наименований, которые включают
    // выбранный "класс" курса, который может не соответствовать классу ученика.
    // И поэтому снаружи приходится получать "класс" курса, обращаясь к бэкенду курса.

    let eventSource = 'magic_math';

    if (this.courseParallel !== null && this.studentParallel !== null) {
      if (this.courseParallel === 5) {
        eventSource = 'magic_math_5';
      }

      if (this.hasPremium) {
        eventSource = ` magic_math_paid_${this.courseParallel}_b2c`;
      }

      if ([2, 4, 6].includes(this.studentParallel)) {
        eventSource = `magic_math_paid_${this.studentParallel}_${this.courseParallel}`;
      }
    }

    return eventSource;
  }

  init = flow(function* init(this: MagicMathStore, data: MagicMathStoreData) {
    (Object.keys(data) as Array<keyof MagicMathStoreData>).forEach((key) => {
      // @ts-ignore
      this[key] = data[key];
    });

    this.courseParallel = yield fetch(`${UCHI_DOMAIN}/magic_rpg_math/api/v1/student/new_session`, {
      credentials: 'include',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((r) => r.json())
      .then((data) =>
        fetch(`${UCHI_DOMAIN}/magic_rpg_math/api/v1/profile`, {
          credentials: 'include',
          method: 'GET',
          headers: {
            'X-Student-Session': data.student_session,
            'Content-Type': 'application/json',
          },
        }),
      )
      .then((r) => r.json())
      .then((data) => data.course.grade_value);

    this.isReady = true;
  });

  setIsReadyNotLoggedIn() {
    this.isReady = true;
  }
}

type MagicMathStoreData = SetNonNullable<
  Pick<
    MagicMathStore,
    'studentId' | 'studentParallel' | 'hasPremium' | 'price' | 'basePrice' | 'paymentUrl'
  >
>;

export const store = new MagicMathStore();
