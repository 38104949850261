import React, { memo } from 'react';
import cn from 'classnames';
import styles from './index.module.scss';
import type * as I from './types/interfaces';

export const Title = memo(function Title({ children, className }: I.TitleProps) {
  const classesRoot = cn(styles.root, className);

  return <h2 className={classesRoot}>{children}</h2>;
});
