import React, { memo } from 'react';
import { TASKS_DATA } from 'components/pages/Anatomy/data';
import styles from './index.module.scss';
import { HeroSection } from '../../ui/HeroSection';
import TasksGIF from './imgs/tasks.gif';
import { Container } from '../../ui/Container';

export const Tasks = memo(function Tasks() {
  const textConfig = {
    title: TASKS_DATA.title,
    text: TASKS_DATA.text,
  };

  const imageConfig = {
    imageUrl: TasksGIF,
    description: TASKS_DATA.imageDescription,
  };

  return (
    <div className={styles.root}>
      <div className={styles.egg} />
      <Container>
        <HeroSection textConfig={textConfig} imageConfig={imageConfig} className={styles.grid} />
      </Container>
    </div>
  );
});
