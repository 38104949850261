import React, { memo } from 'react';
import { Header } from 'shared/components/Header';
import { Footer } from 'shared/components/Footer';
import { FirstScreen } from './components/FirstScreen';
import { HowItWorks } from './components/HowItWorks';
import { BabyBanner } from './components/BabyBanner';
import { PriceBlock } from './components/PriceBlock';
import { Reviews } from './components/Reviews';
import { Banner } from './components/Banner';
import { useEffectOnMount } from '../../../shared/hooks/useEffectOnMount';
import { TEXT } from './constants';

import styles from './Planning.module.scss';

const basePrice = 3000;
const price = 1190;
const sale = '60%';

const basePriceYear = 18700;
const priceYear = 3790;
const saleYear = '80%';

const date = '2022-03-30T12:00:00';

export const Planning = memo(function Planning() {
  useEffectOnMount(() => {
    document.title = TEXT.titles.courseTitle;
  });

  return (
    <div className={styles.main}>
      <Header type="gray" />
      <FirstScreen sale={sale} price={price} basePrice={basePrice} isShowPrice />
      <HowItWorks />
      <BabyBanner />
      <PriceBlock
        saleYear={saleYear}
        priceYear={priceYear}
        basePriceYear={basePriceYear}
        sale={sale}
        price={price}
        basePrice={basePrice}
        date={date}
      />
      <Reviews />
      <Banner sale={sale} price={price} basePrice={basePrice} isShowPrice date={date} />
      <Footer />
    </div>
  );
});
