import React from 'react';
import cn from 'classnames';
import { observer } from 'mobx-react';
import { sendEvent } from 'shared/helpers/requests';
import { TEXT, EVENT } from '../../constants';
import { store } from '../../store';
import { ButtonType } from '../../types';
import { ReactComponent as ButtonHalfTopBg } from './images/button_half_top.svg';
import { ReactComponent as ButtonFullBg } from './images/button_full.svg';
import styles from './Button.module.scss';

type ButtonProps = {
  className?: string;
  type: ButtonType;
};

export const Button = observer(function Button({ className = '', type }: ButtonProps) {
  const adjustedType: keyof typeof TEXT.button =
    type === 'buy' && !store.isAuthorized ? 'buyWithoutAuthWithRedirect' : type;

  const onClick = () => {
    const link = type === 'buy' ? store.paymentUrl : TEXT.button[adjustedType].link;

    sendEvent({
      eventSource: EVENT.source,
      eventType: EVENT[type],
      eventInfo: '',
    });

    if (link) {
      window.location.assign(link);
    }
  };

  return (
    <button
      type="button"
      className={cn(
        className,
        styles.button,
        styles[`button_${type}`],
        store.hasPremium && styles.button_premium,
        !store.isAuthorized && styles.button_unauthorized,
      )}
      onClick={onClick}
    >
      {/*
      В случае премиума или неавторизованности показывается только одна кнопка.
      Фоновая svg определяется здесь, а скрытие неактуальной — в стилях.
      */}
      {store.hasPremium || !store.isAuthorized ? (
        <ButtonFullBg className={styles.buttonBg} />
      ) : (
        <ButtonHalfTopBg className={styles.buttonBg} />
      )}
      <div className={styles.buttonText}>{TEXT.button[adjustedType].text}</div>
    </button>
  );
});
