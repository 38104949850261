export const EVENT_BUY = {
  source: 'my_emotions',
  type: 'Payments_from_Landing',
};

export const EVENT_LOGIN = {
  source: 'my_emotions',
  type: 'Login_from_Landing',
};

export const LANDING_URL = `https://${process.env.REACT_APP_LANDING_PAGES_SERVICE}/courses/emotional_quotient`;

export const IMAGE_PATH = `https://static.uchi.ru/files/content-1-4/landings/eq`;

export const TEXT = {
  buttonBuy: {
    text: 'Купить курс',
    link: 'https://fake.something',
  },
  title: {
    advantages: 'Ребёнок подружится с эмоциями',
    comics: 'Никаких скучных лекций!',
    slider: 'Курс состоит из 12 уроков',
    accordion: 'Часто задаваемые вопросы',
  },
  description: {
    bubble: 'Для детей 6–10 лет<br>и их родителей',
  },
  advantages: [
    'Освоит мирные способы проявления чувств',
    'Сможет успешно работать в команде',
    'Станет увереннее в себе',
    'Сможет получать пользу от разных эмоций',
    'Перестанет переживать по пустякам',
    'Научится решать конфликты',
  ],
  comics: [
    'Интерактивный сюжет с выбором действий',
    'Реалистичные ситуации',
    'Проверка знаний в конце каждой главы',
    'Прогресс видно по карте',
  ],
  textBlock: {
    title: 'Расскажем простыми словами о сложных чувствах',
    text: 'Вместе с героями комикса школьники научатся справляться с эмоциями и строить отношения с близкими. Задания и темы усложняются постепенно — от главы к главе. Курс не скучно пройти заново, ведь сюжет разветвляется. В заданиях можно выбирать новые варианты ответов и менять поведение героев. Получилось весело, познавательно и интерактивно. Начните заниматься уже сегодня!',
  },
  bottom: {
    bubble: 'Простыми словами о сложных чувствах! Мяу!',
  },
};

export const SLIDER = [
  {
    title: 'Глава 1.',
    description: 'Как на нас влияют эмоции?',
    link: `${IMAGE_PATH}/level_1_v2.svg`,
  },
  {
    title: 'Глава 2.',
    description: 'Нужно ли скрывать чувства?',
    link: `${IMAGE_PATH}/level_2_v2.svg`,
  },
  {
    title: 'Глава 3.',
    description: 'Злиться бывает полезно!',
    link: `${IMAGE_PATH}/level_3_v2.svg`,
  },
  {
    title: 'Глава 4.',
    description: 'Читаем мысли по лицам',
    link: `${IMAGE_PATH}/level_4_v2.svg`,
  },
  {
    title: 'Глава 5.',
    description: 'Учимся слушать',
    link: `${IMAGE_PATH}/level_5_v2.svg`,
  },
  {
    title: 'Глава 6.',
    description: 'Как поддержать друга?',
    link: `${IMAGE_PATH}/level_6_v2.svg`,
  },
  {
    title: 'Глава 7.',
    description: 'Почему они так поступают?',
    link: `${IMAGE_PATH}/level_7_v2.svg`,
  },
  {
    title: 'Глава 8.',
    description: 'Учимся договариваться',
    link: `${IMAGE_PATH}/level_8_v2.svg`,
  },
  {
    title: 'Глава 9.',
    description: 'Как спорить без драк и обид?',
    link: `${IMAGE_PATH}/level_9_v2.svg`,
  },
  {
    title: 'Глава 10.',
    description: 'Как справляться с эмоциями?',
    link: `${IMAGE_PATH}/level_10_v2.svg`,
  },
  {
    title: 'Глава 11.',
    description: 'У тебя всё получится!',
    link: `${IMAGE_PATH}/level_11_v2.svg`,
  },
  {
    title: 'Глава 12.',
    description: 'Проверь себя',
    link: `${IMAGE_PATH}/level_12_v2.svg`,
  },
];

export const Title = {
  my_emotions: 'Мои эмоции',
};

export const Link = {
  my_emotions: 'https://uchi.ru/payments/provider?type=game__my_emotions_25_year_990_base_2000',
};

export const Description = {
  my_emotions:
    'Курс-знакомство с эмоциональным интеллектом. Учит осознанно проявлять чувства, понимать <br /> себя и окружающих.',
};

export const BASE_PRICE = 2000;
export const PRICE = 990;

export const LOGIN_BUTTON_TEXT = 'Войти и купить';

// Код для теста цен
// export const AZTestBuyLinks = {
//   control: Link.my_emotions,
//   target1: 'https://uchi.ru/payments/provider?type=game__my_emotions_25_year_690_base_1400',
//   target2: 'https://uchi.ru/payments/provider?type=game__my_emotions_25_year_990_base_2000',
//   target3: 'https://uchi.ru/payments/provider?type=game__my_emotions_25_year_1490_base_3000',
//   target4: 'https://uchi.ru/payments/provider?type=game__my_emotions_25_year_1990_base_4000',
// };

// export const AZTestPrices = {
//   control: {
//     basePrice: 1500,
//     price: 990,
//   },
//   target1: {
//     basePrice: 1400,
//     price: 690,
//   },
//   target2: {
//     basePrice: 2000,
//     price: 990,
//   },
//   target3: {
//     basePrice: 3000,
//     price: 1490,
//   },
//   target4: {
//     basePrice: 4000,
//     price: 1990,
//   },
// };
