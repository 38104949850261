import React, { memo } from 'react';
import { TEXT } from '../../../../constants';
import styles from './Profession.module.scss';

export const Profession = memo(function Profession() {
  return (
    <section className={styles.professionSection}>
      <h2 className={styles.title}>{TEXT.titles.profession}</h2>
      <p className={styles.text}>{TEXT.professionText}</p>
    </section>
  );
});
