import React, { memo } from 'react';
import cn from 'classnames';
import ReactHtmlParser from 'react-html-parser';
import styles from './index.module.scss';
import type * as I from './types/interfaces';
import Icon1 from '../../../../images/icon_1.svg';
import Icon2 from '../../../../images/icon_2.svg';
import Icon3 from '../../../../images/icon_3.svg';

const iconList = [Icon1, Icon2, Icon3];

export const Partition = memo(function Partition({
  tag,
  srcImage,
  srcIcon,
  altImage,
  title,
  text,
  className,
  reverse = false,
}: I.PartitionProps) {
  const classesRoot = cn(styles.root, { [styles.root_reverse]: reverse }, className);

  return (
    <div className={classesRoot}>
      <div className={styles.textBox}>
        <div className={styles.icon}>
          <img src={iconList[srcIcon]} alt={altImage} />
        </div>
        <span className={styles.tag} style={{ color: `#${tag.colorHex}` }}>
          {ReactHtmlParser(tag.text)}
        </span>
        <p className={styles.text}>{ReactHtmlParser(text)}</p>
      </div>
      <div className={styles.imageBox}>
        <img className={styles.image} src={srcImage} alt={altImage} />
      </div>
    </div>
  );
});
