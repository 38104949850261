import React from 'react';
import { Button } from '../Button';
import { TEXT } from '../../constants';
import styles from '../../WordsBattle.module.scss';
import { getClickBuyBottom, getClickBuyTop, getDemoHref } from './utils';

export function BuyButton({
  callback,
  href,
  hasPremium,
}: {
  callback: () => void;
  href: string;
  hasPremium: boolean;
}) {
  return !hasPremium ? (
    <Button
      text={TEXT.buy}
      href={href}
      target="_blank"
      className={styles.button_buy}
      callback={callback}
    />
  ) : null;
}

export function TopBuyButton({ hasPremium, href }: { href: string; hasPremium: boolean }) {
  return <BuyButton callback={getClickBuyTop} hasPremium={hasPremium} href={href} />;
}

export function BottomBuyButton({ href, hasPremium }: { href: string; hasPremium: boolean }) {
  return <BuyButton callback={getClickBuyBottom} hasPremium={hasPremium} href={href} />;
}

export function TryButton({ userId }: { userId: number | null }) {
  const href = getDemoHref(userId !== null);

  return <Button href={href} text={TEXT.try} className={styles.button_try} target="_blank" />;
}
