import React from 'react';
import { observer } from 'mobx-react';
import cn from 'classnames';
import { sendEvent } from 'shared/helpers/requests';
import { TEXT } from '../../constants';
import { store } from '../../store';
import { Button } from '../Button';
import { PriceContainer } from './PriceContainer';
import styles from './Checkout.module.scss';

export const Checkout = observer(function Checkout() {
  const onPlayButtonClick = () => {
    sendEvent({
      eventType: 'landing_click_play',
      eventSource: store.eventSource,
      eventInfo: '',
    });

    window.location.assign(store.adaptedCourseUrl);
  };

  const onBuyButtonClick = () => {
    sendEvent({
      eventType: 'landing_click_buy',
      eventSource: store.eventSource,
      eventInfo: '',
    });

    window.location.assign(store.paymentUrl);
  };

  return (
    <section
      className={cn(
        styles.checkoutSection,
        (store.hasPremium || !store.isAuthorized) && styles.checkoutSection_short,
      )}
    >
      {store.hasPremium ? (
        <>
          <div className={styles.text}>
            <b>{TEXT.checkout.premiumTextStart}</b>
            <br />
            {TEXT.checkout.premiumTextEnd}
          </div>
          <Button className={styles.playButton} type="play" onClick={onPlayButtonClick} />
        </>
      ) : (
        <>
          <div className={styles.text}>{TEXT.checkout.defaultText}</div>
          {!!store.price && <PriceContainer basePrice={store.basePrice} price={store.price} />}
          {store.isReady && <Button type="buy" onClick={onBuyButtonClick} />}
        </>
      )}
    </section>
  );
});
