import React, { memo } from 'react';
import { FooterBlockProps } from './types';
import styles from './FooterBlock.module.scss';

export const FooterBlock = memo(function FooterBlock({ button }: FooterBlockProps) {
  return (
    <div className={styles.main}>
      <div className={styles.cloud} />
      <div className={styles.inner}>
        <div className={styles.content}>
          <div className={styles.button}>{button}</div>
        </div>
      </div>
    </div>
  );
});
