import React, { memo } from 'react';

import styles from './FourthScreen.module.scss';

export const FourthScreen = memo(function FourthScreen() {
  const themeList = React.useMemo(() => {
    const list = [
      {
        title: 'Введение',
        items: ['Среда разработки', 'Интерактивный Python', 'Переменные и операции'],
      },
      { title: 'Работа с графикой', items: ['Простые фигуры', 'Цвета', 'Анимации'] },
      { title: 'Основные <br> конструкции', items: ['Циклы', 'Условия', 'Функции'] },
      {
        title: '<span class="nowrap">Встроенные структуры</span> <br> данных',
        items: ['Кортежи', 'Списки', 'Словари'],
      },
      {
        title: 'Встроенные функции',
        items: ['Работа с временем', 'Числовые функции', 'Работа с файлами'],
      },
      {
        title: 'Внешние модули',
        items: ['Поиск и установка', 'Документация', 'Импорт и использование'],
      },
    ];
    return list.map((el) => (
      <div key={el.title} className={styles['theme-list']}>
        <h3
          className={`${styles.showUl} ${styles.h3_mobile}`}
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: el.title.replace(/<br>/, '') }}
        />
        <h3
          className={`${styles.showUl} ${styles.h3_desktop}`}
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: el.title }}
        />
        <span
          className={styles.accordion}
          onClick={(e: any) => e.target.parentNode.classList.toggle(styles.visible)}
        >
          +
        </span>
        <ul>
          {el.items.map((item) => (
            <li key={item}>{item}</li>
          ))}
        </ul>
      </div>
    ));
  }, []);
  return (
    <section className={styles['fourth-screen']}>
      <div className={`${styles.digit} ${styles.far}`} />
      <div className={`${styles.digit} ${styles.mid}`} />
      <div className={`${styles.digit} ${styles.close}`} />
      <div className={styles.inner}>
        <div className={`${styles.guy} ${styles.child}`} />
        <div className={`${styles.themes} ${styles.child}`}>
          <h2>Основные темы курса</h2>
          {themeList}
        </div>
      </div>
    </section>
  );
});
