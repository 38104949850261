import React, { memo } from 'react';
import cn from 'classnames';

import { ComicsProps } from './types';
import styles from './Comics.module.scss';
import pathAnimation1 from './images/animation1.gif';
import pathAnimation2 from './images/animation2.gif';
import pathAnimation3 from './images/animation3.gif';
import pathAnimation4 from './images/animation4.gif';

type ImageLinkType = { [key: number]: string };

const imageLink: ImageLinkType = {
  0: pathAnimation1,
  1: pathAnimation2,
  2: pathAnimation3,
  3: pathAnimation4,
};

const Comics = memo(function Comics({ data, title }: ComicsProps) {
  return (
    <div className={styles.main}>
      <div className={styles.contentWrapper}>
        <h2 className={styles.title}>{title}</h2>
        <div className={styles.comicsList}>
          {data.map((text, i) => {
            const index = i;
            return (
              <div key={text} className={cn(styles.item, styles[`item_${i}`])}>
                <div className={cn(styles.backgroundStatic, styles.item_image)}>
                  <img src={imageLink[index]} alt="gif" />
                </div>
                <div className={styles.item_wrap}>
                  <div className={styles.item_wrap_text}>{text}</div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
});

export { Comics };
