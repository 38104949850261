import React from 'react';
import { observer } from 'mobx-react';
import { TEXT } from '../../../constants';
import styles from './PriceContainer.module.scss';

interface PriceContainerProps {
  price: number;
  basePrice: number;
}

export const PriceContainer = observer(function PriceContainer({
  price,
  basePrice,
}: PriceContainerProps) {
  return (
    <div className={styles.priceContainer}>
      <div className={styles.basePrice}>
        <del className={styles.basePriceValue}>{basePrice}</del>
        <span className={styles.currencySign}>{TEXT.checkout.currencySign}</span>
      </div>
      <div className={styles.price}>
        <span className={styles.priceValue}>{price}</span>
        <span className={styles.currencySign}>{TEXT.checkout.currencySign}</span>
      </div>
    </div>
  );
});
