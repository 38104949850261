import React from 'react';
import cn from 'classnames';
import { observer } from 'mobx-react';

import { times } from 'lodash';
import { TEXT } from '../../constants';
import { Screencast } from '../Screencast';
import { TrialButton } from '../TrialButton';
import { BuyButton } from '../BuyButton';

import styles from './TopBlock.module.scss';

const featureCount = 3;

export const TopBlock = observer(function TopBlock() {
  return (
    <section className={styles.topBlock}>
      <div className={styles.inner}>
        <div className={styles.header}>
          <div className={styles.logoBlock}>
            <div className={styles.logo} />
            <h2 className={styles.subtitle}>{TEXT.topBlock.subtitle}</h2>
            <BuyButton className={styles.buyButton} />
          </div>
          <div className={styles.topScreencast}>
            <div className={styles.topScreencastInner} />
          </div>
        </div>
        <div className={styles.features}>
          {times(featureCount, (i) => i + 1).map((index) => (
            <div key={index} className={cn(styles.feature, styles[`feature_${index}`])} />
          ))}
        </div>
        <TrialButton className={styles.trialButton} />
        <Screencast className={styles.screencast} variant={1} />
      </div>
    </section>
  );
});
