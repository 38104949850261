import React, { memo } from 'react';
import cn from 'classnames';
import styles from './Advantages.module.scss';
import { TEXT } from '../../constants';

const URL =
  'https://static.uchi.ru/files/content-1-4/landings/emotional-quotient/images/advantages';

const imageLink = {
  stars: `${URL}/stars.svg`,
};

export const Advantages = memo(function Advantages() {
  const data = TEXT.advantages;

  return (
    <section className={styles.main}>
      <div className={styles.contentWrapper}>
        <div className={styles.advantagesList}>
          {data.map((item, i) => (
            <div key={item} className={cn(styles.item, styles[`item_${i}`])}>
              {item}
            </div>
          ))}
        </div>
        <div className={cn(styles.backgroundStatic, styles.icon, styles.surpriseSvg)} />
        <div
          className={cn(styles.backgroundStatic, styles.starsSvg)}
          style={{
            backgroundImage: `url(${imageLink.stars})`,
          }}
        />
        <div className={cn(styles.backgroundStatic, styles.icon, styles.angrySvg)} />
      </div>
    </section>
  );
});
