import { getImagePath } from './helpers/getImagePath';

export const EVENT_BUY = {
  source: 'vpr',
  type: 'Payments_from_Landing',
};

export const EVENT_LOGIN = {
  source: 'vpr',
  type: 'Login_from_Landing',
};

export const LANDING_URL = `https://${process.env.REACT_APP_LANDING_PAGES_SERVICE}/courses/vpr`;

export const IMAGE_PATH = `https://static.uchi.ru/files/content-1-4/landings/eq`;

export const TEXT = {
  buttonBuy: {
    text: 'Купить курс',
    link: 'https://fake.something',
  },
  title: {
    mainTitle: 'Впр На <span>5+</span>',
    advantages: 'Ребёнок подружится с эмоциями',
    comics: 'Никаких скучных лекций!',
    slider: 'Курс состоит из 12 уроков',
    accordion: 'Часто задаваемые вопросы',
    python: 'Как проходит подготовка',
  },
  description: {
    bubble: 'Для детей 6–10 лет<br>и их родителей',
  },
  advantages: [
    'Балл по ВПР на 30% выше',
    'Подробная статистика для родителя',
    'Красочный мир Учи.ру',
    'Программу курируют учителя',
    'Ребенок занимается самостоятельно',
    'Анимированные персонажи',
  ],
  comics: [
    'Интерактивный сюжет с выбором действий',
    'Реалистичные ситуации',
    'Проверка знаний в конце каждой главы',
    'Прогресс видно по карте',
  ],
  bottom: {
    bubble: 'Предзаказ по специальной цене. Доступ к курсу навсегда. Мяу!',
  },
  python: [
    {
      text: 'Ребенок выполняет задания в комфортном темпе, с подсказками и повторениями.',
    },
    {
      text: 'Осваивает и повторяет все необходимые для написания ВПР темы.',
    },
    {
      text: 'В личном кабинете родитель смотрит прогресс прохождения курса.',
    },
  ],
};

export const ACCORDIONS = [
  {
    content:
      'Всероссийские проверочные работы (ВПР) — это итоговые контрольные работы, проводимые по отдельным учебным предметам для оценки уровня подготовки школьников с учетом требования ФГОС.',
    header: 'Что такое ВПР?',
  },
  {
    content: 'В 4-м классе это русский язык, математика и окружающий мир.',
    header: 'По каким предметам проводят ВПР?',
  },
  {
    content: 'В личном кабинете родителя можно посмотреть прогресс прохождения заданий из ВПР.',
    header: 'Как я буду понимать, что занятия приносят пользу?',
  },
];

export const Title = {
  vpr: 'Впр На 5+',
};

export const Link = {
  vpr: 'https://uchi.ru/payments/provider?type=vpr_12_month_1500_base_3000',
};

export const Description = {
  vpr: 'Подготовка к всероссийским<br/>проверочным работам',
};

export const BASE_PRICE = 2000;
export const PRICE = 990;

export const LOGIN_BUTTON_TEXT = 'Войти и купить';

export const PARTITIONS_COURSE_DATA = {
  title: 'Из чего состоит курс?',
  partitions: [
    {
      id: 1,
      tag: {
        text: 'Математика',
        colorHex: '263B6A',
      },
      srcImage: getImagePath('partition_1.gif'),
      srcIcon: 0,
      altImage: 'first',
      title: '',
      text: 'Блок покрывает все 12 заданий проверочной работы. Ребенок тренирует навыки, которым уделяют недостаточно внимания в школьной программе: умение извлекать нужную информацию из текста и строить связь между объектами.',
      withBuyButton: false,
    },
    {
      id: 2,
      tag: {
        text: 'Русский язык',
        colorHex: '263B6A',
      },
      srcImage: getImagePath('partition_2.gif'),
      srcIcon: 1,
      altImage: 'second',
      title: '',
      text: 'Ребенок подготовится к 15 заданиям ВПР, повторит темы по морфологии, орфографии и синтаксису — пробелов не останется.',
      withBuyButton: false,
    },
    {
      id: 3,
      tag: {
        text: 'Окружающий мир',
        colorHex: '263B6A',
      },
      srcImage: getImagePath('partition_3.gif'),
      srcIcon: 2,
      altImage: 'third',
      title: '',
      text: 'В этом блоке ученик закрепит знания по 10 темам проверочной работы: природные зоны, организм человека, растения, космос и другим.',
      withBuyButton: false,
    },
  ],
};

export const PROMOTION_COURSE_DATA = {
  title: 'ВПР на 5+',
  text: '<ol><li>Полный курс по подготовке к ВПР</li><li>Математика, русский, окружающий мир </li><li>Больше 390 заданий</li><li>Поддержка 24/7</li></ol>',
};
