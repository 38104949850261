import React, { memo } from 'react';
import { TEXT } from '../../constants';
import styles from './CourseStructureBlock.module.scss';

const {
  title,
  subtitles: { spelling, morphology, syntax, partOfSpeech },
} = TEXT.courseStructureBlock;

export const CourseStructureBlock = memo(function CourseStructureBlock() {
  return (
    <section className={styles.courseStructureBlock}>
      <div className={styles.ornament} />
      <div className={styles.spelling}>
        <div className={styles.image}>
          <div className={styles.imageInner} />
        </div>
        <h1 className={styles.title}>{title}</h1>
        <h2 className={styles.subtitle}>{spelling}</h2>
      </div>
      <div className={styles.morphology}>
        <div className={styles.image} />
        <h2 className={styles.subtitle}>{morphology}</h2>
      </div>
      <div className={styles.syntax}>
        <div className={styles.image} />
        <h2 className={styles.subtitle}>{syntax}</h2>
      </div>
      <div className={styles.partOfSpeech}>
        <div className={styles.image} />
        <h2 className={styles.subtitle}>{partOfSpeech}</h2>
      </div>
    </section>
  );
});
