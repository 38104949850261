import React, { memo, useCallback } from 'react';
import { Button } from 'shared/components/Button';
import { sendEvent } from 'shared/helpers/requests';
import { TEXT, LINK_COURSE, EVENT_BUY } from '../../constants';
import styles from './LastScreen.module.scss';

export const LastScreen = memo(function LastScreen() {
  const clickPaymentButton = useCallback(() => {
    sendEvent({
      eventType: EVENT_BUY.type,
      eventSource: EVENT_BUY.source,
      eventInfo: 'bottom',
    });
  }, []);
  return (
    <section className={styles.checkoutSection}>
      <div className={styles.innerWrapper}>
        <h2 className={styles.title}>{TEXT.titles.lastScreen}</h2>
        <p className={styles.text}>{TEXT.lastScreenText}</p>
        <a href={LINK_COURSE} target="_blank" rel="noreferrer">
          <Button
            callback={clickPaymentButton}
            className={styles.curseLink}
            defaultOnClick={false}
            text={TEXT.textButtonCurse}
          />
        </a>
      </div>
    </section>
  );
});
