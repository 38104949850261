import { createContext, useContext } from 'react';
import type * as I from './types/interfaces';

const ContextAdvancedMath = createContext<I.ContextAdvancedMath>({
  type: 'unauthorized',
  from: '',
  profile: null,
  isAuthorized: false,
  isPremium: false,
  paymentLink: '',
});
ContextAdvancedMath.displayName = 'AdvancedMathContext';

export const ContextAdvancedMathProvider = ContextAdvancedMath.Provider;
export const useContextAdvancedMath = () => useContext(ContextAdvancedMath);
