import React, { FC, memo, MouseEvent } from 'react';
import cn from 'classnames';
import { sendEvent } from '../../helpers/requests';

import { ButtonProps } from './types';
import styles from './Button.module.scss';

export const Button: FC<ButtonProps> = memo(function Button({
  text = 'test',
  href = '',
  className,
  callback = () => {},
  defaultOnClick,
}) {
  const decoratedOnClick = (event: MouseEvent) => {
    if (defaultOnClick) {
      event.preventDefault();

      sendEvent({
        eventType: 'purchase_click',
        eventSource: 'uchi_video_5_8',
        eventInfo: ' ',
      }).then(() => {
        callback();

        if (href !== '') {
          window.location.href = href;
        }
      });
    } else {
      callback();
    }
  };
  const styleButton = cn(styles.main, className);

  return (
    <button type="button" className={styleButton} onClick={decoratedOnClick}>
      {text}
    </button>
  );
});
