import React, { memo } from 'react';
import { TEXT } from '../../constants';
import { HeroProps } from '../../types';
import MainTitle from '../../images/mainTitle.svg';
import styles from './Hero.module.scss';

export const Hero = memo(function Hero({ buttonTry, buttonBuy }: HeroProps) {
  return (
    <section className={styles.firstPageSection}>
      <div className={styles.innerWrapper}>
        <h1 className={styles.title}>
          <img className={styles.imageTitle} src={MainTitle} alt={TEXT.titles.mainTitle} />
          <p className={styles.textTitle}>{TEXT.titles.mainTitle}</p>
        </h1>
        <p className={styles.helpText}>{TEXT.firstPageText}</p>
        <div className={styles.containerButtons}>
          {buttonBuy}
          {buttonTry}
        </div>
      </div>
    </section>
  );
});
