import _ from 'lodash';
import React, { memo } from 'react';
import { Button } from 'shared/components/Button';

import stylesCommon from '../../Programming2035.module.scss';
import styles from './FirstScreen.module.scss';

export const FirstScreen = memo(function FirstScreen() {
  const [isArrowVisible, setIsArrowVisible] = React.useState(true);
  React.useEffect(() => {
    const scrollHandler = (event: Event) => {
      const { currentTarget } = event;
      if (currentTarget != null) {
        const { pageYOffset, innerHeight } = currentTarget as Window;
        // скрытие стрелки при 10% прокрутки страницы
        setIsArrowVisible(pageYOffset < innerHeight * 0.1);
      }
    };
    window.addEventListener('scroll', _.throttle(scrollHandler, 150), { passive: true });
    return () => window.removeEventListener('scroll', scrollHandler);
  }, []);
  return (
    <div className={`${styles.screen__wrapper} ${styles['parallax-group']}`}>
      <div className={styles['arrow-wrapper']}>
        <div className={`${styles.arrow} ${isArrowVisible ? '' : styles.hidden}`} />
      </div>
      <div className={styles.content}>
        <h1 className={`${stylesCommon.alwaysWhite} ${styles.landing__header}`}>Стань героем</h1>
        <p>
          Интерактивный курс по программированию
          <br />
          на языке Python
        </p>
        <a href={process.env.REACT_APP_PROG_2035_AUTH_URL} rel="noreferrer">
          <Button text="Получить доступ" className={stylesCommon.btn} defaultOnClick={false} />
        </a>
        <p className={styles['additional-info']}>
          Бесплатно для участников проекта <span className={stylesCommon.nowrap}>“Код Будущего”</span>
        </p>
      </div>
      <div className={`${styles['parallax-layer']} ${styles['parallax-layer_far']}`} />
      <div className={`${styles['parallax-layer']} ${styles['parallax-layer_mid']}`} />
      <div className={`${styles['parallax-layer']} ${styles['parallax-layer_close']}`} />
    </div>
  );
});
