export const STATICS_LINK = 'https://static.uchi.ru/files/content-1-4/landings/financialLiteracy/';

export const TEXT = {
  titles: {
    firstScreen: 'Финансовая грамотность',
    program: 'Программа курса',
    forWhat: 'Для чего развивать финансовую грамотность?',
    lastScreen: 'Первая глава бесплатно',
  },
  textFistScreen:
    'Расскажем об управлении деньгами в простой и интересной форме. Для младших школьников и их родителей.',
  programCourse: [
    {
      text: 'Глава 1. Что такое финансовая грамотность?',
      nameImage: `${STATICS_LINK}chapter_1.svg`,
    },
    {
      text: 'Глава 2. Семейный бюджет',
      nameImage: `${STATICS_LINK}chapter_2.svg`,
    },
    {
      text: 'Глава 3. Карманные деньги',
      nameImage: `${STATICS_LINK}chapter_3.svg`,
    },
    {
      text: 'Глава 4. История денег',
      nameImage: `${STATICS_LINK}chapter_4.svg`,
    },
    {
      text: 'Глава 5. Деньги сегодня',
      nameImage: `${STATICS_LINK}chapter_5.svg`,
    },
    {
      text: 'Глава 6. Банковская карта',
      nameImage: `${STATICS_LINK}chapter_6.svg`,
    },
    {
      text: 'Глава 7. Другие деньги',
      nameImage: `${STATICS_LINK}chapter_7.svg`,
    },
    {
      text: 'Глава 8. В магазине',
      nameImage: `${STATICS_LINK}chapter_8.svg`,
    },
    {
      text: 'Глава 9. Деньги под защитой',
      nameImage: `${STATICS_LINK}chapter_9.svg`,
    },
    {
      text: 'Глава 10. Что такое банк?',
      nameImage: `${STATICS_LINK}chapter_10.svg`,
    },
    {
      text: 'Глава 11. Копилка',
      nameImage: `${STATICS_LINK}chapter_11.svg`,
    },
    {
      text: 'Глава 12. Деньги и помощь другим',
      nameImage: `${STATICS_LINK}chapter_12.svg`,
    },
    {
      text: 'Глава 13. Тратим разумно',
      nameImage: `${STATICS_LINK}chapter_13.svg`,
    },
  ],
  forWhatList: [
    {
      text: 'Финансовая грамотность — актуальный навык для взрослых и детей. Осознанное управление деньгами сегодня во многом определяет успешность, благополучие и качество жизни.',
      nameImage: `${STATICS_LINK}Icon_1.png`,
    },
    {
      text: 'Деньги не являются общечеловеческой ценностью, и мы надеемся, что не станут. Но это важный инструмент повседневной жизни. Задача родителей — говорить с детьми о деньгах, научить правильно обращаться с ними.',
      nameImage: `${STATICS_LINK}Icon_2.png`,
    },
    {
      text: 'Задача курса — помочь в развитии этого навыка. Чем раньше школьники научатся разумно обращаться с деньгами, тем быстрее смогут полноценно ориентироваться в современном мире и принимать обоснованные финансовые решения.',
      nameImage: `${STATICS_LINK}Icon_3.png`,
    },
  ],
  forWhatText:
    'Мы создаём комиксы, в которых сложная информация подана в понятной и увлекательной форме и сопровождается проверочными заданиями. А истории персонажей напоминают ситуации из реальной жизни.',
  showAll: 'Подробнее',
  bayCourse: 'Купить курс',
  lastScreenText:
    'Пройдите пробную часть курса, чтобы оценить наш новый формат подачи материала в виде обучающих комиксов. Доступно, если вы зарегистрированы на учи.ру.',
  textButtonCurse: 'Перейти в курс',
};

export const LINK_COURSE = 'https://uchi.ru/financial_literacy';

export const TITLE = 'Финансовая грамотность';

export const EVENT_BUY = {
  source: 'financial_literacy',
  type: 'Payments_from_Landing',
};

export const EVENT_LOAD = {
  source: 'financial_literacy',
  type: 'Landing_Open',
};
