import React, { memo } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { TEXT } from '../../constants';
import { ButtonAndPrice } from '../ButtonAndPrice';
import { LoginButton } from '../LoginButton';
import styles from './MainBanner.module.scss';

import { MainBannerProps } from './types';

export const MainBanner = memo(function MainBanner({
  isNeedBuyButton,
  isNeedLoginButton,
  paymentLink,
  price,
  basePrice,
}: MainBannerProps) {
  return (
    <section className={styles.mainBanner}>
      <div className={styles.mainBannerBG}>
        <img
          src="https://static.uchi.ru/files/content-1-4/landings/neuroCourse/grishasonya.svg"
          alt="bg"
        />
      </div>
      <div className={styles.mainBannerHelpWrapper}>
        <p className={styles.mainBannerHelpText}>{TEXT.mainBannerText2}</p>
      </div>
      <div className={styles.mainBannerContent}>
        <h1 className={styles.mainBannerH1}>{ReactHtmlParser(TEXT.titles.mainBanner)}</h1>
        <div className={styles.mainBannerSubTitle}>{TEXT.mainBannerText}</div>
        <div className={styles.mainBannerWrapperButton}>
          {isNeedBuyButton && (
            <ButtonAndPrice
              paymentLink={paymentLink}
              place="top"
              price={price}
              basePrice={basePrice}
              isShowPrice
              isShowBasePrice={false}
            />
          )}
          {isNeedLoginButton && <LoginButton place="top" />}
        </div>
      </div>
    </section>
  );
});
