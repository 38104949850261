import React, { memo, useCallback } from 'react';
import { Button } from 'shared/components/Button';
import { sendEvent } from 'shared/helpers/requests';
import cn from 'classnames';
import styles from './ButtonAndPrice.module.scss';
import { TEXT, EVENT_BUY } from '../../constants';
import { ButtonAndPriceProps } from './types';

export const ButtonAndPrice = memo(function ButtonAndPrice({
  price = 0,
  basePrice = 0,
  isShowPrice,
  isShowBasePrice,
  className,
  paymentLink,
  place,
}: ButtonAndPriceProps) {
  const clickPaymentButton = useCallback(() => {
    sendEvent({
      eventType: EVENT_BUY.type,
      eventSource: EVENT_BUY.source,
      eventInfo: JSON.stringify({ place }),
    });
  }, [place]);

  return (
    <div className={styles.containerButtonPrice}>
      <div className={cn(styles.buttonWrapper, className)}>
        <a href={paymentLink} target="_blank" rel="noreferrer">
          <Button
            callback={clickPaymentButton}
            className={styles.innerButton}
            defaultOnClick={false}
            text={TEXT.buttonBuy.default.text}
          />
        </a>
      </div>
      {isShowPrice && (
        <div className={styles.priceContainer}>
          {isShowBasePrice && (
            <div className={styles.basePriceWrapper}>
              <p className={styles.basePrice}>
                <span>{basePrice}</span>
              </p>
              <span>{TEXT.valuta}</span>
            </div>
          )}
          <div className={styles.price}>
            {price}
            <span>{TEXT.valuta}</span>
          </div>
        </div>
      )}
    </div>
  );
});
