import React from 'react';
import DocumentMeta from 'react-document-meta';
import { observer } from 'mobx-react';
import { fetchPaymentData } from 'shared/api/fetchPaymentData';
import { fetchHasPremium } from 'shared/api/fetchPremiumData';
import { fetchProfileData, isAuthorizedProfile } from 'shared/api/fetchProfileData';
import { Header } from 'shared/components/Header';
import { Footer } from 'shared/components/Footer';
import {
  redirectToPaymentIfInPaymentRedirectChain,
  isInPaymentRedirectChain,
} from 'shared/helpers/paymentRedirect';
import { useEffectOnMount } from 'shared/hooks/useEffectOnMount';
import { TopBlock, CourseStructureBlock, AboutBlock, FutureBlock, BottomBlock } from './components';
import { META, COURSE_URL } from './constants';
import { store } from './store';
import styles from './RussianGame.module.scss';

export const RussianGame = observer(function RussianGame() {
  useEffectOnMount(() => {
    async function fetchData() {
      const studentProfile = await fetchProfileData();

      if (isAuthorizedProfile(studentProfile)) {
        const [hasPremium, paymentData] = await Promise.all([
          fetchHasPremium('game__scary_adventures'),
          fetchPaymentData('scary_adventures'),
        ]);

        redirectToPaymentIfInPaymentRedirectChain({
          paymentUrl: paymentData.paymentPath,
          courseUrl: COURSE_URL,
          hasPremium,
        });

        store.init({
          studentId: studentProfile.id,
          studentParallel: studentProfile.class_book_parallel,
          hasPremium,
          price: paymentData.price,
          basePrice: paymentData.basePrice,
          paymentUrl: paymentData.paymentPath,
        });
      } else {
        store.setIsReadyNotLoggedIn();
      }
    }

    fetchData();
  });

  // Если в цепочке редиректов к платёжке, не рендерить базовое содержимое
  // страницы, пока инфа по оплате не пришла, когда и произойдёт редирект
  if (isInPaymentRedirectChain()) return null;

  // Да и в целом всё же тут без элементов, зависящих от информации из запросов,
  // находящихся сразу наверху страницы, получается некрасиво, поэтому вообще
  // не рендерим ничего, пока нет всех данных.
  if (!store.isReady) return null;

  return (
    <div className={styles.main}>
      <DocumentMeta {...META} />
      <Header type="gray" />
      <TopBlock />
      <CourseStructureBlock />
      <AboutBlock />
      <FutureBlock />
      <BottomBlock />
      <Footer />
    </div>
  );
});
