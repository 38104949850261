import React, { memo } from 'react';
import cn from 'classnames';
import ReactHtmlParser from 'react-html-parser';
import styles from './index.module.scss';
import type * as I from './types/interfaces';

export const Fact = memo(function Fact({ srcImage, altImage, text, className }: I.FactProps) {
  const classesRoot = cn(styles.root, className);

  return (
    <div className={classesRoot}>
      <img className={styles.image} src={srcImage} alt={altImage} />
      <p className={styles.text}>{ReactHtmlParser(text)}</p>
    </div>
  );
});
