import React, { memo } from 'react';
import { LottiePlayer } from 'components/pages/Poetry/components/general/LottiePlayer';

import stylesCommon from '../../Programming2035.module.scss';
import styles from './FifthScreen.module.scss';
import boy from '../../boy.json';

export const FifthScreen = memo(function FifthScreen() {
  return (
    <section className={styles['fifth-screen']}>
      <div className={`${styles.subscreen} ${styles.first}`}>
        <div className={styles.content}>
          <h2 className={stylesCommon.alwaysWhite}>Как будет проходить обучение?</h2>
          <p className={stylesCommon.standardP}>
            Ты будешь реализовывать <span>различные проекты</span>
            <span className={stylesCommon.nowrap}> и готовиться</span> к ним, решая интерактивные
            задачи.
          </p>
          <p className={stylesCommon.standardP}>
            Тебе предстоит сделать <span>секундомер, шифратор</span>
            <span className={stylesCommon.nowrap}> и дешифратор</span>, аэрохоккей и многое другое.
          </p>
        </div>
        <div className={styles.animation}>
          <LottiePlayer speed={1} animationData={boy} playing loop />
        </div>
      </div>
      <div className={`${styles.subscreen} ${styles.second}`}>
        <h2 className={stylesCommon.alwaysWhite}>Почему python?</h2>
        <ul className={styles.features}>
          <li>
            <h3>Простой</h3>
            <p>Понятный синтаксис, который легко читать</p>
          </li>
          <li>
            <h3>Востребованный</h3>
            <p>На Питоне пишут в Яндексе, Гугле, Мэйле, Касперском и даже в NASA</p>
          </li>
          <li>
            <h3>Мощный</h3>
            <p>Широкий функционал и множество готовых расширений</p>
          </li>
        </ul>
      </div>
    </section>
  );
});
