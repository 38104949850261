import { getUchiLoginRedirectUrl } from './getUchiLoginRedirectUrl';

export const REDIRECT_TO_PAYMENT_QUERY_PARAM_NAME = 'redirect-to-payment';

/**
 * Получение особой ссылки для редиректа на платёжку для неавторизованных.
 *
 * У некоторых курсов платёжная информация — цена и ссылка на платёжку — неизвестны
 * для неавторизованных пользователей, т.к. могут быть разными для разных пользователей
 * на основании AZ-тестов, фича-пойнтов и прочих параметров.
 * Поэтому, для того, чтобы для неавторизованных по кнопке типа "Купить" в конечном итоге
 * был переход на платёжку, имеем цепочку редиректов — переход на главную для авторизации,
 * с указанием в `?next=` текущей страницы плюс флага для итогового последующего редиректа,
 * так что после авторизации и возвращения сюда имеем флаг, что теперь, будучи
 * авторизованными, должны перейти на теперь возможную для получения платёжку,
 * используя `redirectToPaymentIfInRedirectChain`.
 */
export const getUnauthorizedPaymentRedirectChainUrl = () =>
  getUchiLoginRedirectUrl(
    `${window.location.origin + window.location.pathname}?${REDIRECT_TO_PAYMENT_QUERY_PARAM_NAME}`,
  );

export const isInPaymentRedirectChain = () =>
  new URL(window.location.href).searchParams.has(REDIRECT_TO_PAYMENT_QUERY_PARAM_NAME);

/**
 * Итоговый редирект на платёжку, если авторизованы с особым флагом после
 * прохождения первой части цепочки редиректов через
 * `getUnauthorizedPaymentRedirectChainUrl`.
 * См. детали там.
 *
 * Если находимся в цепочке редиректов, то после выполнения этой функции
 * другой код уже не выполняется и сразу происходит смена страницы.
 * Если не в цепочке редиректов, то функция безопасна и ничего не делает.
 */
export const redirectToPaymentIfInPaymentRedirectChain = ({
  paymentUrl,
  courseUrl,
  hasPremium,
}: {
  paymentUrl: string;
  courseUrl: string;
  hasPremium: boolean;
}) => {
  if (isInPaymentRedirectChain()) {
    // Если после авторизации оказалось, что уже имеем прем, то хоть и
    // нажимали "Купить", сейчас сразу переходим в курс вместо платёжки
    // или оставания на ленде.
    // И чтобы у пользователя в истории навигации не было временного адреса
    // для редиректа, делаем `replace`, а не `assign`.
    window.location.replace(hasPremium ? courseUrl : paymentUrl);
  }
};
