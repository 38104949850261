import React, { memo, useContext, useEffect, useState } from 'react';
import { Accordion as AccordionBS, AccordionContext, useAccordionButton } from 'react-bootstrap';
import cn from 'classnames';
import { sendEvent } from '../../helpers/requests';

import { ReactComponent as IconSVG } from './svg/icon.svg';
import { AccordionProps, AccordionHeaderButtonProps, AccordionItem } from './types';
import styles from './Accordion.module.scss';

const AccordionHeaderButton = memo(function AccordionHeaderButton({
  children,
  eventKey,
  classNames,
  eventSource,
}: AccordionHeaderButtonProps) {
  const { activeEventKey } = useContext(AccordionContext);
  const decoratedOnClick = useAccordionButton(eventKey, () => {
    const message = {
      id: Number(eventKey) + 1,
      event: isCurrentEventKey ? 'close' : 'open',
      name: children,
    };
    sendEvent({
      eventType: 'faq_click',
      eventSource,
      eventInfo: JSON.stringify(message),
    });
  });
  const isCurrentEventKey = activeEventKey?.includes(eventKey);

  const className = cn(styles.headerButton, isCurrentEventKey && styles.headerButton_open);
  const titleClassName = cn(styles.headerButton__title, classNames?.title);

  return (
    <div className={className} onClick={decoratedOnClick}>
      <div className={titleClassName}>{children}</div>
      <div className={styles.headerButton__icon}>
        <IconSVG />
      </div>
    </div>
  );
});

export const Accordion = memo(function Accordion({
  accordions,
  className,
  contentClassName,
  itemClassName,
  titleItemClassName,
  theme = 'black',
  eventSource = 'uchi_video_5-8',
}: AccordionProps) {
  const [items, setItems] = useState<AccordionItem[]>([]);

  useEffect(() => {
    setItems(accordions.map((el, index) => ({ ...el, index: index.toString() })));
  }, [accordions]);

  const HeaderButtonClass = {
    title: titleItemClassName,
  };

  return (
    <div className={cn(styles[`theme_${theme}`], className)}>
      <AccordionBS alwaysOpen bsPrefix={styles.main}>
        {items.map(({ content, header, index }) => (
          <AccordionBS.Item eventKey={index} key={index} bsPrefix={cn(styles.item, itemClassName)}>
            <AccordionHeaderButton
              eventKey={index}
              classNames={HeaderButtonClass}
              eventSource={eventSource}
            >
              {header}
            </AccordionHeaderButton>
            <AccordionBS.Collapse eventKey={index}>
              <div className={cn(styles.content, contentClassName)}>{content}</div>
            </AccordionBS.Collapse>
          </AccordionBS.Item>
        ))}
      </AccordionBS>
    </div>
  );
});
