import React, { FC, memo, MouseEvent } from 'react';
import { ButtonProps } from './types';

export const Button: FC<ButtonProps> = memo(function Button({
  text = 'test',
  href = '',
  target = '_blank',
  className,
  callback = () => {},
}) {
  const decoratedOnClick = (event: MouseEvent) => {
    callback();
  };

  return href ? (
    <a className={className} href={href} target={target} onClick={decoratedOnClick}>
      {text}
    </a>
  ) : (
    <button type="button" className={className} onClick={decoratedOnClick}>
      {text}
    </button>
  );
});
