import React, { memo } from 'react';
import cn from 'classnames';

import { AdvantagesProps } from './types';
import styles from './Advantages.module.scss';

const URL =
  'https://static.uchi.ru/files/content-1-4/landings/emotional-quotient/images/advantages';

const imageLink = {
  surprise: `${URL}/surprise.svg`,
  stars: `${URL}/stars.svg`,
  angry: `${URL}/angry.svg`,
  clouds: `${URL}/clouds.svg`,
};

const Advantages = memo(function Advantages(props: AdvantagesProps) {
  const { data, title } = props;
  return (
    <div className={styles.main}>
      <div className={styles.contentWrapper}>
        <h2 className={styles.title}>{title}</h2>
        <div className={styles.advantagesList}>
          {data.map((item, i) => (
            <div key={item} className={cn(styles.item, styles[`item_${i}`])}>
              {item}
            </div>
          ))}
        </div>
        <div
          className={cn(styles.backgroundStatic, styles.icon, styles.surpriseSvg)}
          style={{
            backgroundImage: `url(${imageLink.surprise})`,
          }}
        />
        <div
          className={cn(styles.backgroundStatic, styles.starsSvg)}
          style={{
            backgroundImage: `url(${imageLink.stars})`,
          }}
        />
        <div
          className={cn(styles.backgroundStatic, styles.icon, styles.angrySvg)}
          style={{
            backgroundImage: `url(${imageLink.angry})`,
          }}
        />
        <div
          className={cn(styles.backgroundStatic, styles.cloudsSvg)}
          style={{
            backgroundImage: `url(${imageLink.clouds})`,
          }}
        />
      </div>
    </div>
  );
});

export { Advantages };
