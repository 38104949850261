import React, { memo } from 'react';
import { DIPLOMAS_DATA } from 'components/pages/Anatomy/data';
import styles from './index.module.scss';
import { HeroSection } from '../../ui/HeroSection';
import DiplomaSVG from './imgs/diplomas.svg';
import { Container } from '../../ui/Container';

export const Diploma = memo(function Diploma() {
  const textConfig = {
    title: DIPLOMAS_DATA.title,
    text: DIPLOMAS_DATA.text,
  };

  const imageConfig = {
    imageUrl: DiplomaSVG,
  };

  return (
    <div className={styles.root}>
      <div className={styles.egg} />
      <Container>
        <HeroSection
          textConfig={textConfig}
          imageConfig={imageConfig}
          className={styles.grid}
          classNameImage={styles.image}
        />
      </Container>
    </div>
  );
});
