import React, { useState } from 'react';
import cn from 'classnames';

import { useEffectOnMount } from 'shared/hooks/useEffectOnMount';
import { wait } from 'shared/helpers/wait';

import styles from './Screencast.module.scss';

interface ScreencastProps {
  variant: 1 | 2 | 3;
  className?: string;
}

export const Screencast = function Screencast({ variant, className }: ScreencastProps) {
  const [shouldRenderGifs, setShouldRenderGifs] = useState(false);

  useEffectOnMount(() => {
    wait(500).then(() => setShouldRenderGifs(true));
  });

  return (
    <div className={cn(styles.screencast, styles[`screencast_variant${variant}`], className)}>
      {shouldRenderGifs && <div className={styles.screencastInner} />}
      <div className={cn(styles[`explanation_${variant}`])} />
    </div>
  );
};
