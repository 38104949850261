import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Provider } from 'mobx-react';
import { ImportedController } from 'react-imported-component';
import { Science } from 'components/pages/Science';
import { HarvestMath } from 'components/pages/HarvestMath';
import { Anatomy } from 'components/pages/Anatomy';
import { EnglishPet } from 'components/pages/EnglishPet';
import { BaseTemplate } from './components/pages/BaseTemplate';
import { Reading } from './components/pages/Reading';
import { AdvancedMath } from './components/pages/AdvancedMath';
import { EmotionalQuotient } from './components/pages/EmotionalQuotient';
import { NeuroCourse } from './components/pages/NeuroCourse';
import { Planning } from './components/pages/Planning';
import { Programming } from './components/pages/Programming';
import { RussianGame } from './components/pages/RussianGame';
import { FinancialLiteracy } from './components/pages/FinancialLiteracy';
import { WordsBattle } from './components/pages/WordsBattle';
import { Programming2035 } from './components/pages/Programming2035';
import { Chess } from './components/pages/Chess';
import { VPR } from './components/pages/VPR';

import AppStore from './store';

import reportWebVitals from './reportWebVitals';
import 'assets/styles/base.scss';

// Импортируем некоторые страницы ПОСЛЕ `import 'assets/styles/base.scss'`,
// чтобы маловесные стили (ресеты `*` и тегов) самих страниц шли после /
// перекрывали стили из `base` и подключаемого там `bootstrap`.
import { MagicMath } from './components/pages/MagicMath';
import { MagicMath2 } from './components/pages/MagicMath2';

const rootElement = document.getElementById('root');
console.log(`process.env.PUBLIC_URL = ${process.env.PUBLIC_URL}`);

ReactDOM.render(
  <BrowserRouter>
    <Provider appStore={new AppStore()}>
      <ImportedController>
        <Routes>
          <Route path={`${process.env.PUBLIC_URL}/test`} element={<BaseTemplate />} />
          <Route path={`${process.env.PUBLIC_URL}/advanced_math`} element={<AdvancedMath />} />
          <Route path={`${process.env.PUBLIC_URL}/reading`} element={<Reading />} />
          <Route path={`${process.env.PUBLIC_URL}/neurocourse`} element={<NeuroCourse />} />
          <Route path={`${process.env.PUBLIC_URL}/planning`} element={<Planning />} />
          <Route
            path={`${process.env.PUBLIC_URL}/emotional_quotient`}
            element={<EmotionalQuotient />}
          />
          <Route path={`${process.env.PUBLIC_URL}/programming`} element={<Programming />} />
          <Route path={`${process.env.PUBLIC_URL}/russian_game`} element={<RussianGame />} />
          <Route path={`${process.env.PUBLIC_URL}/harvest_math`} element={<HarvestMath />} />
          <Route
            path={`${process.env.PUBLIC_URL}/financial_literacy`}
            element={<FinancialLiteracy />}
          />
          <Route path={`${process.env.PUBLIC_URL}/magic_math`} element={<MagicMath />} />
          <Route path={`${process.env.PUBLIC_URL}/magic_math_2`} element={<MagicMath2 />} />
          <Route path={`${process.env.PUBLIC_URL}/programming2035`} element={<Programming2035 />} />
          <Route
            path={`${process.env.PUBLIC_URL}/programming2035-processing`}
            element={<Programming2035 isProcessing />}
          />
          <Route path={`${process.env.PUBLIC_URL}/chess`} element={<Chess />} />
          <Route path={`${process.env.PUBLIC_URL}/words_battle`} element={<WordsBattle />} />
          <Route path={`${process.env.PUBLIC_URL}/science`} element={<Science />} />
          <Route path={`${process.env.PUBLIC_URL}/vpr`} element={<VPR levelClass="4" />} />
          <Route path={`${process.env.PUBLIC_URL}/vpr-3`} element={<VPR levelClass="3" />} />
          <Route path={`${process.env.PUBLIC_URL}/anatomy`} element={<Anatomy />} />
          <Route path={`${process.env.PUBLIC_URL}/english_pet`} element={<EnglishPet />} />
        </Routes>
      </ImportedController>
    </Provider>
  </BrowserRouter>,
  rootElement,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
