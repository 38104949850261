import React, { memo } from 'react';
import cn from 'classnames';
import { TEXT } from '../../../../constants';
import styles from './CursList.module.scss';

export const CursList = memo(function CursList() {
  return (
    <div className={styles.cursList}>
      {TEXT.cursList.map(({ text, icon }) => (
        <div className={styles.item} key={text}>
          <div className={cn(styles.icon, styles[`icon_${icon}`])} />
          <div className={styles.text}>{text}</div>
        </div>
      ))}
    </div>
  );
});
