import React, { memo } from 'react';
import cn from 'classnames';
import ReactHtmlParser from 'react-html-parser';
import styles from './index.module.scss';
import type * as I from './types/interfaces';
import { PROMOTION_COURSE_DATA } from '../../../data';
import { Container } from '../../ui/Container';
import { Button } from '../../ui/Button';

export const PromotionSection = memo(function PromotionSection({
  className,
}: I.PromotionSectionProps) {
  const classesRoot = cn(styles.root, className);

  return (
    <section className={classesRoot}>
      <div className={styles.hero} />
      <div className={styles.cloudTop} />
      <Container className={styles.inner}>
        <p className={styles.text}>{ReactHtmlParser(PROMOTION_COURSE_DATA.text)}</p>
        <Button className={styles.button} />
      </Container>
    </section>
  );
});
