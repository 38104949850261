import React, { memo } from 'react';
import styles from './index.module.scss';
import { GOAL, TEXT } from '../../constants';
import { Goal } from '../Goal/Goal';

export const Goals = memo(function Goals() {
  return (
    <section className={styles.section_goals}>
      <div className={styles.wrapper}>
        <h2 className={styles.header}>{TEXT.storyHeader}</h2>
        <div className={styles.goals}>
          {GOAL.map((goal, i) => (
            <Goal key={goal} text={goal} id={i} />
          ))}
        </div>
      </div>
    </section>
  );
});
