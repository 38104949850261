import React, { useState, memo } from 'react';
import { Header } from 'shared/components/Header';
import { Footer } from 'shared/components/Footer';
import { useEffectOnMount } from 'shared/hooks/useEffectOnMount';
import { fetchProfileData, isAuthorizedProfile } from 'shared/api/fetchProfileData';
import { getPremium } from './helpers/getPremium';
import { MyEmotional } from './components/MyEmotional';
import { PartitionsSection } from './components/PartitionsSection';
import { PromotionSection } from './components/PromotionSection';
import { Advantages } from './components/Advantages';
import { Accordion } from './components/Accordion';
import { LastScreen } from './components/LastScreen';
import { Link, Title } from './constants';
import styles from './VPR.module.scss';

interface TVPR {
  levelClass: string;
}

export const VPR = memo(function VPR({ levelClass }: TVPR) {
  const [paymentLink, setPaymentLink] = useState('');
  const [isLoaded, setIsLoaded] = useState(false);
  const [isNeedBuyButton, setIsNeedBuyButton] = useState(false);
  const [isNeedLoginButton, setIsNeedLoginButton] = useState(false);

  useEffectOnMount(() => {
    async function fetchData() {
      if (process.env.NODE_ENV !== 'development') {
        try {
          const profileData = await fetchProfileData();

          if (isAuthorizedProfile(profileData)) {
            const eqPremium = await getPremium();
            if (!eqPremium) {
              setIsNeedBuyButton(true);
              setPaymentLink(Link.vpr);
            }
          } else {
            setIsNeedLoginButton(true);
          }
        } catch (error) {
          // eslint-disable-next-line no-console
          console.log('error:', error);
        }
        setIsLoaded(true);
      } else {
        setPaymentLink(Link.vpr);
        setIsLoaded(true);
      }
    }

    fetchData();
    document.title = Title.vpr;
  });

  if (!isLoaded) return null;

  return (
    <div className={styles.main}>
      <h1 className="visually-hidden">{Title.vpr}</h1>
      <Header type="gray" />
      <MyEmotional
        paymentLink={paymentLink}
        isNeedBuyButton={isNeedBuyButton}
        isNeedLoginButton={isNeedLoginButton}
        levelClass={levelClass}
      />
      <PartitionsSection />
      <Advantages />
      <LastScreen />
      <Accordion />
      <PromotionSection
        paymentLink={paymentLink}
        isNeedBuyButton={isNeedBuyButton}
        isNeedLoginButton={isNeedLoginButton}
      />
      <Footer />
    </div>
  );
});
