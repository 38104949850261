import React, { memo } from 'react';
import YouTube from 'react-youtube';
import cn from 'classnames';
import styles from './CourseContent.module.scss';
import { TEXT } from '../../constants';

export const CourseContent = memo(function CourseContent() {
  const opts = {
    height: '390',
    width: '640',
  };

  return (
    <section className={styles.courseContentSection}>
      <div className={styles.innerWrapper}>
        <div className={styles.courseContentText}>
          <p>{TEXT.CourseContentText}</p>
        </div>
        <div className={styles.sonyaDark}>
          <img
            src="https://static.uchi.ru/files/content-1-4/landings/neuroCourse/sonya1.svg"
            alt="sonyaDark"
          />
        </div>
      </div>
      <ul className={styles.courseContentListSubTitle}>
        <li className={cn(styles.courseContentItemSubTitle, styles.courseContentItemSubTitle_0)}>
          {TEXT.CourseContentList[0]}
        </li>
        <li className={cn(styles.courseContentItemSubTitle, styles.courseContentItemSubTitle_1)}>
          {TEXT.CourseContentList[1]}
        </li>
        <li className={cn(styles.courseContentItemSubTitle, styles.courseContentItemSubTitle_2)}>
          {TEXT.CourseContentList[2]}
        </li>
        <li className={cn(styles.courseContentItemSubTitle, styles.courseContentItemSubTitle_3)}>
          {TEXT.CourseContentList[3]}
        </li>
      </ul>
      <div className={styles.innerWrapper}>
        <div className={styles.pterlok}>
          <img
            src="https://static.uchi.ru/files/content-1-4/landings/neuroCourse/pterlok.svg"
            alt="pterlok"
          />
        </div>
      </div>
      <div className={styles.BGLine} />
      <h2 className={styles.courseContentTitle}>{TEXT.titles.CourseContent}</h2>
      <div className={styles.courseContentList}>
        <div className={cn(styles.courseContentItem, styles.courseContentItem_0)}>
          <div className={styles.courseContentSubTitle}>{TEXT.CourseContent[0].title}</div>
          <div className={styles.courseContentDescription}>{TEXT.CourseContent[0].description}</div>
          <div className={styles.courseContentImage}>
            <img
              src="https://static.uchi.ru/files/content-1-4/landings/neuroCourse/img3.svg"
              alt="img_0"
            />
          </div>
        </div>
        <div className={cn(styles.courseContentItem, styles.courseContentItem_1)}>
          <div className={styles.courseContentSubTitle}>{TEXT.CourseContent[1].title}</div>
          <div className={styles.courseContentDescription}>{TEXT.CourseContent[1].description}</div>
          <div className={styles.courseContentImageContainer}>
            <div className={styles.courseContentImage}>
              <img
                src="https://static.uchi.ru/files/content-1-4/landings/neuroCourse/img1.svg"
                alt="img_1"
              />
              <p className={styles.courseContentDescriptionImage}>
                {TEXT.CourseContent[1].subTitle1}
              </p>
            </div>
            <div className={styles.courseContentImage}>
              <img
                src="https://static.uchi.ru/files/content-1-4/landings/neuroCourse/img2.svg"
                alt="img_2"
              />
              <p className={styles.courseContentDescriptionImage}>
                {TEXT.CourseContent[1].subTitle1}
              </p>
            </div>
          </div>
        </div>
        <div className={cn(styles.courseContentItem, styles.courseContentItem_2)}>
          <div className={styles.courseContentSubTitle}>{TEXT.CourseContent[2].title}</div>
          <div className={styles.courseContentDescription}>{TEXT.CourseContent[2].description}</div>
          <div className={styles.courseContentImage}>
            <div className={styles.courseContentWrapperGIF}>
              <img
                src="https://cms-content.uchi.ru/audios/how-to-learn/gifs/cartoonBrain-mini.gif"
                alt="gif"
              />
            </div>
          </div>
        </div>
        <div className={cn(styles.courseContentItem, styles.courseContentItem_3)}>
          <div className={styles.courseGrisha}>
            <img
              src="https://static.uchi.ru/files/content-1-4/landings/neuroCourse/grisha.svg"
              alt="grisha"
            />
          </div>
          <div className={styles.courseContentSubTitle}>{TEXT.CourseContent[3].title}</div>
          <div className={styles.courseContentDescription}>{TEXT.CourseContent[3].description}</div>
          <div className={styles.courseContentVideo}>
            <YouTube videoId="QxdN8lN27Hg" opts={opts} />
          </div>
          <div className={styles.courseSonya2}>
            <img
              src="https://static.uchi.ru/files/content-1-4/landings/neuroCourse/sonya.svg"
              alt="sonya2"
            />
          </div>
        </div>
      </div>
      <div className={styles.courseContentDiplomaWrapper}>
        <div className={styles.courseContentDiplomaImage}>
          <img
            src="https://static.uchi.ru/files/content-1-4/landings/neuroCourse/diplom.svg"
            alt="diploma"
          />
        </div>
        <div className={styles.courseContentDiplomaContent}>
          <div className={styles.courseContentDiplomaTitle}>{TEXT.CourseContentDiploma.title}</div>
          <div className={styles.courseContentDiplomaText}>{TEXT.CourseContentDiploma.text}</div>
        </div>
      </div>
    </section>
  );
});
