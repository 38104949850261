import React, { memo } from 'react';
import cn from 'classnames';
import { TEXT, STATICS_LINK } from '../../constants';
import styles from './ForWhat.module.scss';

const personageImageUrl = `${STATICS_LINK}mam.png`;
const leftImageUrl = `${STATICS_LINK}light.svg`;
const rightImageUrl = `${STATICS_LINK}pens.svg`;

export const ForWhat = memo(function ForWhat() {
  return (
    <section className={styles.forWhatSection}>
      <div className={styles.innerWrapper}>
        <div className={styles.symbol}>
          <svg xmlns="http://www.w3.org/2000/svg" width="90" height="21.48" viewBox="0 0 88 21">
            <path
              fill="#414042"
              d="M77.2 20.9s-.9-4.3-3.5-6.9c-2.6-2.6-6.9-3.5-6.9-3.5s4.3-.9 6.9-3.5c2.6-2.7 3.5-7 3.5-7s.9 4.3 3.5 7c2.6 2.6 7 3.5 7 3.5s-4.3.9-6.9 3.5c-2.7 2.6-3.6 6.9-3.6 6.9ZM44.3 20.9s-.9-4.3-3.5-6.9c-2.6-2.6-6.9-3.5-6.9-3.5s4.3-.9 6.9-3.5c2.6-2.7 3.5-7 3.5-7s.9 4.3 3.5 7c2.6 2.6 7 3.5 7 3.5s-4.3.9-6.9 3.5c-2.7 2.6-3.6 6.9-3.6 6.9ZM11.1 20.9s-.9-4.3-3.5-6.9C5 11.4.7 10.5.7 10.5S5 9.6 7.6 7c2.5-2.7 3.5-7 3.5-7s.9 4.3 3.5 7c2.6 2.6 7 3.5 7 3.5s-4.3.9-6.9 3.5c-2.7 2.6-3.6 6.9-3.6 6.9Z"
            />
          </svg>
        </div>
        <h2 className={styles.title}>{TEXT.titles.forWhat}</h2>
        <div className={styles.list}>
          {TEXT.forWhatList.map(({ text, nameImage }, index) => (
            <div className={cn(styles.item, styles[`item_${index}`])} key={`forWhat_${index + 1}`}>
              <img className={styles.image} src={nameImage} alt={`image_${index}`} />
              <p className={styles.text}>{text}</p>
            </div>
          ))}
        </div>
        <div className={styles.contentWrapper}>
          <p className={styles.personageText}>{TEXT.forWhatText}</p>
        </div>
        <img className={styles.personageImage} src={personageImageUrl} alt="mam_Image" />
      </div>
      <div className={styles.containerImage}>
        <img className={styles.leftImage} src={leftImageUrl} alt="left_image" />
        <img className={styles.rightImage} src={rightImageUrl} alt="right_image" />
      </div>
    </section>
  );
});
