import React, { memo } from 'react';
import ReactHtmlParser from 'react-html-parser';
import cn from 'classnames';
import { TEXT } from '../../constants';
import styles from './ForWhom.module.scss';

export const ForWhom = memo(function ForWhom() {
  return (
    <section className={styles.forWhomSection}>
      <h2 className={styles.forWhomTitle}>{TEXT.titles.forWhom}</h2>
      <ul className={styles.forWhomList}>
        {TEXT.ForWhomList.map(({ text, imageLink }, index) => (
          <li
            key={`ForWhom_${index + 1}`}
            className={cn(styles.itemList, styles[`itemList_${index}`])}
          >
            <img className={styles.itemIcon} src={imageLink} alt={`ForWhom_${index + 1}`} />
            <p className={styles.itemText}>{ReactHtmlParser(text)}</p>
          </li>
        ))}
      </ul>
      <div className={styles.bgPersonage}>
        <img
          src="https://static.uchi.ru/files/content-1-4/landings/neuroCourse/zavriki.svg"
          alt=""
        />
      </div>
      <div className={cn(styles.bgElem, styles.bgElem_item1)} />
      <div className={cn(styles.bgElem, styles.bgElem_item2)} />
    </section>
  );
});
