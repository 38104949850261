import React, { memo } from 'react';
import { VIDEO_LESSONS_DATA } from 'components/pages/Anatomy/data';
import styles from './index.module.scss';
import { HeroSection } from '../../ui/HeroSection';
import VideosGIF from './imgs/videos.gif';
import { Container } from '../../ui/Container';

export const VideoLessons = memo(function VideoLessons() {
  const textConfig = {
    title: VIDEO_LESSONS_DATA.title,
    text: VIDEO_LESSONS_DATA.text,
  };

  const imageConfig = {
    imageUrl: VideosGIF,
    description: VIDEO_LESSONS_DATA.imageDescription,
  };

  return (
    <div className={styles.root}>
      <div className={styles.egg} />
      <Container>
        <HeroSection textConfig={textConfig} imageConfig={imageConfig} className={styles.grid} />
      </Container>
    </div>
  );
});
