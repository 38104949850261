import React, { memo } from 'react';
import { TEXT } from '../../constants';
import { ButtonProps } from '../../types';
import styles from './Duel.module.scss';
import Mockup from '../../images/phone_duel.png';

export const Duel = memo(function Duel({ button }: ButtonProps) {
  return (
    <section className={styles.duelSection}>
      <div className={styles.innerWrapper}>
        <div className={styles.textContainer}>
          <h2 className={styles.title}>{TEXT.titles.duel}</h2>
          <p className={styles.text}>{TEXT.duelText}</p>
          <div className={styles.buttonWrapper}>{button}</div>
        </div>
        <div className={styles.imageWrapper}>
          <img className={styles.image} src={Mockup} alt="Mockup" />
        </div>
      </div>
    </section>
  );
});
