import { UCHI_DOMAIN } from 'shared/constants';

export const META = {
  title: 'Магическая математика II',
};

export const COURSE_URL = `${UCHI_DOMAIN}/magic-math-2/game`;

export const TEXT = {
  titles: {
    firstScreen: 'Магическая математика глава 2',
    preview: 'Тебя ждут',
    faq: 'Вопросы и ответы',
  },
  parallelBadge: (studentParallel: number) =>
    // Эту плашку делали для ЦОК, с отображением класса ученика
    // (если авторизован и подходит по классу).
    // При том, что ЦОК ушёл, но может вернуться (не факт), оставляем сигнатуру
    // и этот комментарий. Если нужно будет вернуть, можно ревертнуть коммит,
    // в котором был добавлен этот коммент.
    // А перед этим — ещё ревертнуть коммит, в котором добавлена эта строчка.
    // А пока отображаем просто "1–6" для более широкой аудитории потенциальных покупателей.
    'Для 1–6 классов',
  firstScreenSubtitle: 'Новый формат обучения — курс школьной\nматематики теперь полностью в игре!',
  buttons: {
    play: 'играть',
    buy: 'купить',
    details: 'подробнее',
  },
  monitorioBubble:
    'В игре тебе предстоит обучаться в магической школе и сражаться с противниками, путешествуя по волшебным мирам. Изучай математику и побеждай противников!',
  description: [
    {
      type: 'plot',
      title: 'МИР СНОВА В ОПАСНОСТИ',
      subtitle:
        'Во второй главе магического приключения тебе предстоит\nузнать, почему монстры вернулись и кто за этим стоит.',
    },
    {
      type: 'map',
      title: 'ВОЛШЕБНЫЙ МИР',
      subtitle:
        'Отправляйся в путешествие по Матемагии. Легенды гласят,\nчто в бесконечных подземельях можно найти\nмогущественные предметы.',
    },
    {
      type: 'spells',
      title: 'СИЛА СТИХИЙ',
      subtitle:
        'Отчаянные враги требуют отчаянных мер. Пять новых\nзаклинаний помогут в самых непростых ситуациях.',
    },
    {
      type: 'inventory',
      title: 'ДРЕВНИЕ АРТЕФАКТЫ',
      subtitle: 'Более пятидесяти новых предметов\nдля тебя и твоего помощника.',
    },
  ],
  previewFeatures: [
    {
      text: 'Достижения',
      type: 'achievements',
    },
    {
      text: 'Обновлённый инвентарь',
      type: 'inventory',
    },
    {
      text: 'Изучение заклинаний',
      type: 'spells',
    },
    {
      text: 'Интересный сюжет',
      type: 'plot',
    },
    {
      text: 'Адаптивная сложность',
      type: 'difficulty',
    },
    {
      text: 'Прокачка и кастомизация',
      type: 'customization',
    },
  ],
  checkout: {
    defaultText: 'Приобретите вторую главу «Магической Математики» сейчас по сниженной цене!',
    cardSubtitle: 'вторая глава',
    premiumTitle: 'Игра приобретена!',
    premiumText:
      'Поздравляем! Вам теперь доступная полная версия Второй Главы.\nСкорее отправляйтесь к приключениям!',
    currencySign: '₽',
  },
  faqAccordion: [
    {
      header: 'Будут ли новые задания?',
      content: 'Да, мы планируем их выпустить до конца года.',
    },
    {
      header: 'До какого числа действует оплата?',
      content:
        'Доступ к курсу бессрочный! Оплатив курс, вы сохраните доступ даже после смены класса.',
    },
    {
      header: 'Если мы прошли игру во 2 классе, можно ли нам включить доступ в игре 4 класса?',
      content: 'Внутри игры можно будет выбрать, на материалах какого класса заниматься.',
    },
    {
      header: 'Почему игра продается отдельно от основного курса математики?',
      content:
        'Игра — полноценный продукт с собственным сюжетом, кардинально другими механиками, многопользовательским режимом. Хотя и курс математики, и игра связаны со школьной программой, они основываются на разных принципах и могут быть использованы параллельно.',
    },
    {
      header: 'На каком учебнике строится программа математики в игре?',
      content:
        'Мы ориентируемся на наш собственный курс математики, на Учи.ру. Прямо сейчас в игре представлены темы с 1 по 4 классы. Мы тестируем, какие темы для учеников каких классов интереснее и полезнее.',
    },
    {
      header:
        'Меняется ли программа в магической математике при переходе в другой класс? Или курс единый с 1 по 11 класс?',
      content:
        'В игре представлены две программы: для 1-2 и 3-4 классов. Внутри игры можно выбрать на материалах какого класса заниматься. Приобретать отдельный доступ к этим материалам не нужно.',
    },
    {
      header: 'Какие новые обновления будут выходить?',
      content:
        'Мы планируем добавить задачи на новые темы, выпустить обновления с новыми сюжетными поворотами.',
    },
  ],
};
