export const UCHIRU_LINK = 'https://uchi.ru';

export const BUTTON = {
  default: {
    text: 'купить курс',
    link: '',
  },
  pr: {
    text: 'перейти на учи.ру',
    link: UCHIRU_LINK,
  },
  premium: {
    text: 'перейти к курсу',
    link: 'https://uchi.ru/reading',
  },
};

export const TEXT = {
  advantages: ['Доступ навсегда', 'Диплом в конце курса'],
  subtitles: {
    default: 'Курс-путешествие:<br>от знакомства с буквами<br>до анализа текстов.',
  },
  titles: {
    getAccess: 'Оформите доступ уже сейчас!',
    main: 'Чтение — новый курс Учи.ру',
    whatWillNext: 'Что будет в курсе',
    sectionWhatWillNext: 'Научим ребёнка читать<br>и любить книги.',
    readingText: 'Чтение — базовый навык для успешного обучения',
  },
  listBukvarik: [
    {
      title: 'bukvarik-a',
      link: 'https://static.uchi.ru/files/content-1-4/landings/reading/images/bukvarik-a_v2.svg',
      text: 'Знакомство с буквами, звуками и слогами',
    },
    {
      title: 'bukvarik-o',
      link: 'https://static.uchi.ru/files/content-1-4/landings/reading/images/bukvarik-o_v2.svg',
      text: 'Отработка чтения по слогам',
    },
    {
      title: 'bukvarik-u',
      link: 'https://static.uchi.ru/files/content-1-4/landings/reading/images/bukvarik-u_v2.svg',
      text: 'Смысловое чтение и анализ текстов',
    },
  ],
  listPeculiarities: [
    {
      text: 'Подойдёт детям 5–8 лет. А также тем, для кого русский — второй язык.',
      linkImg: 'https://static.uchi.ru/files/content-1-4/landings/reading/images/first.svg',
      alt: 'first',
    },
    {
      text: 'Знакомство с буквами в формате игры. Персонажи ведут по курсу и помогают, присутствие взрослого не требуется.',
      linkImg: 'https://static.uchi.ru/files/content-1-4/landings/reading/images/second.svg',
      alt: 'second',
    },
    {
      text: 'Развитие навыков восприятия текста — визуального, слухового, а также смыслового.',
      linkImg: 'https://static.uchi.ru/files/content-1-4/landings/reading/images/third.svg',
      alt: 'third',
    },
  ],
  readingText:
    'Развитый навык чтения даёт преимущество в учёбе, развивает кругозор, помогает общаться со сверстниками. В курсе ребёнок не только научится читать, но и увидит, насколько это может быть захватывающе и интересно.',
};

export const SLIDERS = [
  {
    alt: 'slider-a',
    link: 'https://static.uchi.ru/files/content-1-4/landings/reading/images/slider-a_v2.svg',
  },
  {
    alt: 'slider-o',
    link: 'https://static.uchi.ru/files/content-1-4/landings/reading/images/slider-o_v2.svg',
  },
  {
    alt: 'slider-u',
    link: 'https://static.uchi.ru/files/content-1-4/landings/reading/images/slider-u_v2.svg',
  },
];

export const Title = {
  reading: 'Чтение',
};
