import React, { memo } from 'react';
import { PROMOTION_DATA } from 'components/pages/Anatomy/data';
import styles from './index.module.scss';
import { Button } from '../../ui/Button';

export const Promotion = memo(function Promotion() {
  const { copyright } = PROMOTION_DATA;

  return (
    <div className={styles.root}>
      <div className={styles.girl} />
      <div className={styles.boy} />
      <div className={styles.content}>
        <Button className={styles.button__buy} />
      </div>
      <p className={styles.copyright}>{copyright}</p>
    </div>
  );
});
