import { UCHI_DOMAIN } from 'shared/constants';

export const META = {
  title: 'Магическая математика',
};

export const COURSE_URL = `${UCHI_DOMAIN}/magic_rpg_math/game`;

export const STATICS_BASE_PATH =
  'https://static.uchi.ru/files/content-1-4/landings/magicMathematics';

export const TEXT = {
  titles: {
    firstScreen: 'Магическая математика',
    preview: 'Вас ждут',
    reviews: 'Отзывы игроков',
    faq: 'Вопросы и ответы',
  },
  parallelBadge: (studentParallel: number) =>
    // Эту плашку делали для ЦОК, с отображением класса ученика
    // (если авторизован и подходит по классу).
    // При том, что ЦОК ушёл, но может вернуться (не факт), оставляем сигнатуру
    // и этот комментарий. Если нужно будет вернуть, можно ревертнуть коммит,
    // в котором был добавлен этот коммент.
    // А перед этим — ещё ревертнуть коммит, в котором добавлена эта строчка.
    // А пока отображаем просто "1–6" для более широкой аудитории потенциальных покупателей.
    'Для 1–6 классов',
  firstScreenSubtitle: 'Новый формат обучения — курс школьной\nматематики теперь полностью в игре!',
  buttons: {
    play: 'играть',
    buy: 'купить',
    details: 'подробнее',
  },
  previewFeatures: [
    {
      text: 'Интересный сюжет',
      imageUrl: `${STATICS_BASE_PATH}/plotList_1.jpg`,
    },
    {
      text: 'Смешная озвучка',
      imageUrl: `${STATICS_BASE_PATH}/plotList_2.jpg`,
    },
    {
      text: 'Прокачка и кастомизация',
      imageUrl: `${STATICS_BASE_PATH}/plotList_3.jpg`,
    },
    {
      text: 'Адаптивная сложность',
      imageUrl: `${STATICS_BASE_PATH}/plotList_4.jpg`,
    },
    {
      text: 'Дуэли с реальными игроками',
      imageUrl: `${STATICS_BASE_PATH}/plotList_6.jpg`,
    },
  ],
  description:
    'В игре вам предстоит обучаться в магической школе и сражаться с противниками, путешествуя по волшебным мирам. Вы будете зарабатывать монеты и покупать одежду, а также активировать магические камни и чинить робота-помощника. Изучайте математику и побеждайте противников!',
  descriptionList: [
    {
      text: 'Дети оценивают игру на 9 из 10 баллов.',
      type: 'rating',
      iconUrl: `${STATICS_BASE_PATH}/prize_icon.svg`,
    },
    {
      text: 'После прохождения сюжета вы сможете путешествовать по мирам и продолжать сражения столько, сколько захотите!',
      type: 'continuation',
      iconUrl: `${STATICS_BASE_PATH}/full_icon.svg`,
    },
    {
      text: 'Согласно нашим исследованиям дети решают до 4х раз больше заданий в игре «Магическая математика» по сравнению с обычными форматами.',
      type: 'research',
      iconUrl: `${STATICS_BASE_PATH}/price_icon.svg`,
    },
    {
      text: 'В игре вы сможете изучить, повторить и проработать следующие темы:',
      type: 'tasks',
      iconUrl: `${STATICS_BASE_PATH}/test_icon.svg`,
      detailsListByParallel: {
        '1-2': [
          'Числа до 20',
          'Сравнение чисел',
          'Сложение и вычитание чисел до 10',
          'Сложение и вычитание чисел до 20',
          'Выражения и уравнения',
          'Единицы измерения длины',
        ],
        '3-4': [
          'Числа до 1000',
          'Круглые числа',
          'Операции с близкими к круглым числами',
          'Единицы измерения массы',
          'Единицы измерения длины',
          'Единицы измерения площади',
          'Единицы измерения времени',
          'Календарь',
          'Сложение в столбик',
          'Вычитание в столбик',
        ],
        '5-6': [
          'Обыкновенные дроби',
          'Неправильные и смешанные дроби',
          'Сравнение дробей',
          'Основное свойство дроби',
          'Делимость',
          'Простые и составные числа',
          'Разложение на простые множители',
        ],
      },
    },
  ],
  reviews: [
    'Игра просто класс особенно с идеей про магию в 10000000 раз  лучше всех игр в плей-офф маркете и еще математику развивает ставлю 5 😍😍🌟🌟🌟🌟🌟',
    'Я хочу чтобы в эту игру играли другие девочки и мальчики потому что это очень хорошая игра и мне она очень понравилась!!!!!!!!!!!!!!',
    'Это интересно, потому что одновременно и решаешь и играешь. В игре хочется находиться дольше и не замечаешь, что ещё и учишься.',
    'Игра очень хорошая!! Не усвоила тему по математике а тут потянула!! Спасибо производителям за такую крутую озвучку!!',
    'Мне очень понравилось то что это развивает мозги и даже когда ты начал-(а) проходить новую тему то в заданиях встречаются и другие темы это даёт то что я не забываю другие темы и они у меня должны быть всегда в голове',
  ],
  checkout: {
    defaultText:
      'Купите «Магическую математику» сейчас по сниженной цене.\nСо следующими обновлениями стоимость будет расти.',
    premiumTextStart: 'Игра приобретена.',
    premiumTextEnd: 'Скорее отправляйтесь к приключениям!',
    currencySign: '₽',
  },
  faqAccordion: [
    {
      header: 'Будут ли новые задания?',
      content: 'Да, мы планируем их выпустить в ближайшее время.',
    },
    {
      header: 'До какого числа действует оплата?',
      content:
        'Доступ к курсу бессрочный! Оплатив курс, вы сохраните доступ даже после смены класса.',
    },
    {
      header: 'Если мы прошли игру в 3 классе, можно ли нам включить доступ в игре 5 класса?',
      content: 'Внутри игры можно выбрать, на материалах какого класса заниматься.',
    },
    {
      header: 'Почему игра продается отдельно от основного курса математики? ',
      content:
        'Игра — полноценный продукт с собственным сюжетом, кардинально другими механиками, многопользовательским режимом. Хотя и курс математики, и игра связаны со школьной программой, они основываются на разных принципах и могут быть использованы параллельно.',
    },
    {
      header: 'На каком учебнике строится программа математики в игре?',
      content:
        'Мы ориентируемся на наш собственный курс математики на Учи.ру. Прямо сейчас в игре есть задания на основе тем для 1, 3 и 5 классов, мы тестируем, какие темы для учеников каких классов интереснее и полезнее.',
    },
    {
      header:
        'При переходе в другой класс, меняется ли программа в магической математике? Или курс единый с 1 по 11 класс?',
      content:
        'Прямо сейчас в игре реализованы темы для 1, 3 или 5 классов. В ближайшем будущем мы добавим темы для 2, 4 и 6 классов. Вы сможете выбирать, на темах каких классов проходить игру.',
    },
    {
      header: 'Какие новые обновления будут выходить?',
      content:
        'Мы планируем добавить задачи на новые темы, выпустить обновления с новыми сюжетными поворотами.',
    },
  ],
};
