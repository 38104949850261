import React, { memo } from 'react';
import cn from 'classnames';
import styles from './index.module.scss';
import type * as I from './types/interfaces';
import { Title } from '../../ui/Title';
import { Partition } from './components/Partition';
import { PARTITIONS_COURSE_DATA } from '../../../data';
import { Container } from '../../ui/Container';

export const PartitionsSection = memo(function PartitionsSection({
  className,
}: I.PartitionsSectionProps) {
  const classesRoot = cn(styles.root, className);

  return (
    <section className={classesRoot}>
      <Container className={styles.inner}>
        <Title className={styles.title}>{PARTITIONS_COURSE_DATA.title}</Title>
        <div className={styles.partitions}>
          {PARTITIONS_COURSE_DATA.partitions.map((partition, index) => (
            <div key={partition.id} className={styles.partition}>
              <Partition
                tag={partition.tag}
                srcImage={partition.srcImage}
                altImage={partition.altImage}
                title={partition.title}
                text={partition.text}
                withBuyButton={partition.withBuyButton}
                reverse={index % 2 === 1}
              />
            </div>
          ))}
        </div>
      </Container>
    </section>
  );
});
