import { DeviceType } from './types';

export const UCHIRU_LINK = 'https://uchi.ru';
// В `setupProxy` есть копипаста определения этой константы,
// и при изменении здесь нужно не забыть изменить там.
export const UCHI_DOMAIN =
  JSON.stringify(process.env.REACT_APP_SENTRY_ENVIRONMENT) === JSON.stringify('production')
    ? UCHIRU_LINK
    : '';

export const cancelledPromiseMessage = 'CANCELLED';

export const cancelledWhenPromiseMessage = 'WHEN_CANCELLED';

export const deviceTypes: Record<DeviceType, DeviceType> = {
  desktop: 'desktop',
  touch: 'touch',
};
