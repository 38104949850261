import React, { memo, useCallback, useState } from 'react';
import cn from 'classnames';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import { useEffectOnMount } from 'shared/hooks/useEffectOnMount';
import { SliderProps } from './types';
import styles from './Slider.module.scss';

const MIN_WIDTH_WITH_CENTER_MODE = 640;

const getSlideWidth = (innerWidth: number) => {
  switch (true) {
    // widthWindow = 640; padding-right: 77px; padding-left: 77px; widthSlide = 324px + 16px = 340px
    // 640px - 77px - 77px = 486px; widthWindowWithoutPadding = 486px;
    // 340px / 4.86 = 69.95%; - widthSlide in percentage
    case innerWidth >= 640 && innerWidth < 720:
      return 69.95;
    case innerWidth >= 720 && innerWidth < 1024:
      return 80.1;
    case innerWidth >= 1024 && innerWidth < 1360:
      return 75.98;
    case innerWidth >= 1360 && innerWidth < 1920:
      return 72;
    case innerWidth >= 1920:
      return 61.08;
    default:
      return 100;
  }
};

const getArrow = (onClick: VoidFunction, centerMode: boolean, type: 'prev' | 'next') => (
  <div
    className={cn(styles.mask, {
      [styles.maskShow]: centerMode,
      [styles.maskPrev]: type === 'prev',
      [styles.maskNext]: type === 'next',
    })}
  >
    <div
      className={cn(styles.button, {
        [styles.maskShow]: centerMode,
        [styles.buttonPrev]: type === 'prev',
        [styles.buttonNext]: type === 'next',
      })}
      onClick={onClick}
    />
  </div>
);

const Slider = memo(function Slider({ data, title: titleHeader }: SliderProps) {
  const [centerMode, setCenterMode] = useState(
    () => window.innerWidth >= MIN_WIDTH_WITH_CENTER_MODE,
  );
  const [slideWidth, setSlideWidth] = useState(() => getSlideWidth(window.innerWidth));

  const handleResize = useCallback(() => {
    setCenterMode(window.innerWidth >= MIN_WIDTH_WITH_CENTER_MODE);
    setSlideWidth(getSlideWidth(window.innerWidth));
  }, []);

  useEffectOnMount(() => {
    handleResize();

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  });

  const renderArrowPrev = useCallback(
    (onClickHandler, hasPrev) => hasPrev && getArrow(onClickHandler, centerMode, 'prev'),
    [centerMode],
  );

  const renderArrowNext = useCallback(
    (onClickHandler, hasNext) => hasNext && getArrow(onClickHandler, centerMode, 'next'),
    [centerMode],
  );

  return (
    <div className={styles.main}>
      <div className={styles.contentWrapper}>
        <h2 className={styles.title}>{titleHeader}</h2>
        <Carousel
          centerMode={centerMode}
          centerSlidePercentage={slideWidth}
          renderArrowPrev={renderArrowPrev}
          renderArrowNext={renderArrowNext}
          showArrows
          showIndicators={false}
          showStatus={false}
          showThumbs={false}
        >
          {data.map(({ description, link, title }) => (
            <div className={styles.slide} key={`${title}-${description}`}>
              <img alt={`${title} ${description}`} src={link} />
              <div className={styles.slideText}>
                <div className={styles.slideTitle}>{title}&nbsp;</div>
                <div className={styles.slideDescription}>{description}</div>
              </div>
            </div>
          ))}
        </Carousel>
      </div>
    </div>
  );
});

export { Slider };
