import React, { memo } from 'react';
import { Accordion } from 'shared/components/Accordion';
import { TEXT, FAQ_ACCORDION } from '../../constants';
import styles from './index.module.scss';

export const FAQ = memo(function Faq() {
  return (
    <section className={styles.faqSection}>
      <h2 className={styles.title}>{TEXT.faqHeader}</h2>
      <div className={styles.container}>
        <Accordion
          accordions={FAQ_ACCORDION}
          theme="white"
          contentClassName={styles.hidden_text}
          titleItemClassName={styles.btn_text}
        />
      </div>
    </section>
  );
});
