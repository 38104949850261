import React from 'react';
import cn from 'classnames';

import styles from './FAQBlock.module.scss';
import { TEXT } from '../../constants';

const { questions, title } = TEXT.faqBlock;

export const FAQBlock = function FAQBlock() {
  return (
    <section className={styles.faqBlock}>
      <h2 className={styles.title}>{title}</h2>
      <div className={styles.questions}>
        <div className={styles.grid}>
          {questions.map((item, index) => (
            <div
              key={item.title}
              className={cn(styles.gridElement, styles[`gridElement_${index}`])}
            >
              <div className={styles.gridElementTitle}>{item.title}</div>
              <div className={styles.gridElementDesc}>{item.description}</div>
            </div>
          ))}
        </div>
        <div className={styles.scarecrow} />
        <div className={styles.tractor} />
        <div className={styles.horse} />
        <div className={styles.chicken} />
      </div>
      <div className={styles.boats}>
        <div className={cn(styles.boat, styles.topBoat)} />
        <div className={cn(styles.boat, styles.bottomBoat)} />
      </div>
    </section>
  );
};
