import React, { memo } from 'react';
import cn from 'classnames';
import { TEXT } from '../../constants';
import styles from './Helped.module.scss';
import { getDeviceData } from '../../../../../shared/helpers/getDeviceData';

export const Helped = memo(function Helped() {
  const isMobile = getDeviceData().deviceType === 'mobile';

  return (
    <section className={styles.helpedSection}>
      <div className={styles.innerWrapper}>
        <h2 className={styles.title}>{TEXT.titles.helpTitle}</h2>
        <div className={cn(styles.innerContainer, isMobile && styles.innerContainer_mobile)}>
          <div className={cn(styles.phoneContainer, isMobile && styles.phoneContainer_mobile)}>
            <div className={cn(styles.phone, isMobile && styles.phone_mobile)} />
          </div>
          <div className={cn(styles.contentWrapper, isMobile && styles.contentWrapper_mobile)}>
            {TEXT.helpedList.map(({ subTitle, text }, index) => (
              <article
                className={cn(
                  styles.cardItem,
                  styles[`cardItem_${index}`],
                  isMobile && styles.cardItem_mobile,
                  isMobile && styles[`cardItem_${index}_mobile`],
                )}
                key={`article_${index + 1}`}
              >
                <h3 className={styles.subTitle}>{subTitle}</h3>
                <p className={cn(styles.text, isMobile && styles.text_mobile)}>{text}</p>
              </article>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
});
