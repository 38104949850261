import React, { memo } from 'react';
import styles from './index.module.scss';

export const Microcomponentik = memo(function Microcomponentik({ text }: { text: string }) {
  return (
    <div className={styles.wrapper}>
      <div className={styles.mark} />
      <div className={styles.text}>{text}</div>
    </div>
  );
});
