import React, { FC, memo } from 'react';
import cn from 'classnames';

import { UCHIRU_LINK } from 'shared/constants';

import { FooterProps } from './types';
import styles from './Footer.module.scss';
import { EMAIL, LOGO, SOCIAL_LINKS, TEXT_LOGO } from './constants';

export const Footer: FC<FooterProps> = memo(function Footer({ className }) {
  return (
    <div className={cn(styles.main, className)}>
      <div className={styles.content}>
        <div className={styles.logo}>
          <a href={UCHIRU_LINK} target="_blank" rel="noreferrer">
            <img src={LOGO} alt={TEXT_LOGO} />
          </a>
        </div>

        <div className={styles.email}>
          <a href={`mailto:${EMAIL}`}>{EMAIL}</a>
        </div>

        <div>
          {SOCIAL_LINKS.map(({ image, link, name }) => (
            <a
              className={styles.socialLink}
              href={link}
              key={name}
              target="_blank"
              rel="noreferrer"
            >
              <img src={image} alt={name} />
            </a>
          ))}
        </div>
      </div>
    </div>
  );
});
