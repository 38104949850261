import React, { memo } from 'react';
import cn from 'classnames';
import { ReactComponent as TitleSvg } from './images/title.svg';
import styles from './Badge.module.scss';

type BadgeProps = {
  className?: string;
};

export const Badge = memo(function Badge({ className = '' }: BadgeProps) {
  return (
    <div className={cn(className, styles.badge)}>
      <TitleSvg className={styles.badgeImage} />
    </div>
  );
});
