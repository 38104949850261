import React, { memo, useCallback } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { Header } from 'shared/components/Header';
import { HeaderBlockProps } from './types';
import styles from './HeaderBlock.module.scss';
import { BackButton } from '../BackButton';

export const HeaderBlock = memo(function HeaderBlock({
  button,
  subtitle,
  title,
  from,
}: HeaderBlockProps) {
  const handleBackButtonClick = useCallback(() => {
    window.location.href = from;
  }, [from]);

  return (
    <div className={styles.main}>
      <div className={styles.header}>
        <Header type="gray" />
      </div>
      <BackButton className={styles.backButton} onClick={handleBackButtonClick} />
      <div className={styles.contentWrapper}>
        <div className={styles.content}>
          <h1 className={styles.title}>{title}</h1>
          <div className={styles.subtitle}>{ReactHtmlParser(subtitle)}</div>
          <div className={styles.button}>{button}</div>
        </div>
      </div>
    </div>
  );
});
