import React, { memo, useEffect, useState } from 'react';
import { Footer } from 'shared/components/Footer';
import { fetchPaymentData } from 'shared/api/fetchPaymentData';
import { fetchHasPremium } from 'shared/api/fetchPremiumData';
import { fetchProfileData, isAuthorizedProfile } from 'shared/api/fetchProfileData';
import { useEffectOnMount } from 'shared/hooks/useEffectOnMount';
import { Hero } from './components/Hero';
import { Duel } from './components/Duel';
import { Helped } from './components/Helped';
import { FeatureList } from './components/FeatureList';
import { Checkout } from './components/Checkout';
import styles from './WordsBattle.module.scss';
import { BottomBuyButton, TopBuyButton, TryButton } from './components/Buttons';
import { TEXT } from './constants';

export const WordsBattle = memo(function WordsBattle() {
  const [userId, setUserId] = useState<number | null>(null);
  const [hasPremium, setHasPremium] = useState<boolean>(false);
  const [paymentHref, setPaymentHref] = useState<string>('');

  useEffectOnMount(() => {
    document.title = TEXT.titles.mainTitle;

    async function fetchData() {
      try {
        const profileData = await fetchProfileData();

        if (isAuthorizedProfile(profileData)) {
          setUserId(profileData.id);
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log('error fetching profile data:', error);
      }
    }

    fetchData();
  });

  useEffect(() => {
    if (!userId) return;

    const fetchData = async () => {
      try {
        const hasPremium = await fetchHasPremium('words_battle');
        const paymentData = await fetchPaymentData('words_battle');

        setHasPremium(hasPremium);
        setPaymentHref(paymentData.paymentPath);
      } catch (_) {
        /**/
      }
    };

    fetchData();
  }, [userId]);

  return (
    <div className={styles.main}>
      <Hero
        buttonBuy={<TopBuyButton href={paymentHref} hasPremium={hasPremium} />}
        buttonTry={<TryButton userId={userId} />}
      />
      <Duel />
      <Helped />
      <FeatureList />
      <Checkout button={<BottomBuyButton href={paymentHref} hasPremium={hasPremium} />} />
      <Footer />
    </div>
  );
});
