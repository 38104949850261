import React, { memo } from 'react';
import cn from 'classnames';

import { BuyButton } from '../BuyButton';
import { BottomProps } from './types';
import styles from './Bottom.module.scss';
import { LoginButton } from '../LoginButton';

const URL = 'https://static.uchi.ru/files/content-1-4/landings/emotional-quotient/images/bottom';

const imageLink = {
  bg: `${URL}/bg.svg`,
  bubble: `${URL}/bubble.svg`,
  cat: `${URL}/cat.svg`,
  cloud: `${URL}/cloud.svg`,
  flower: `${URL}/flower.svg`,
};

const Bottom = memo(function Bottom({
  paymentLink,
  text,
  price,
  basePrice,
  isNeedBuyButton,
  isNeedLoginButton,
}: BottomProps) {
  return (
    <div className={styles.main}>
      <div className={styles.contentWrapper}>
        <div
          className={cn(styles.backgroundStatic, styles.cloudsSvg)}
          style={{
            backgroundImage: `url(${imageLink.bg})`,
          }}
        />
        {isNeedBuyButton && (
          <BuyButton
            className={styles.buyButton}
            paymentLink={paymentLink}
            place="bottom"
            theme="dark"
            price={price}
            basePrice={basePrice}
          />
        )}
        {isNeedLoginButton && <LoginButton place="bottom" className={styles.loginButton} />}
        <div
          className={cn(
            styles.bubble,
            !isNeedBuyButton && !isNeedBuyButton && styles.bubble_withoutButton,
          )}
        >
          <div
            className={cn(styles.backgroundStatic, styles.bubbleSvg)}
            style={{
              backgroundImage: `url(${imageLink.bubble})`,
            }}
          />
          <div className={styles.bubbleText}>{text}</div>
        </div>
        <div className={styles.images}>
          <div
            className={cn(styles.backgroundStatic, styles.flower)}
            style={{
              backgroundImage: `url(${imageLink.flower})`,
            }}
          />
          <div
            className={cn(styles.backgroundStatic, styles.cloud)}
            style={{
              backgroundImage: `url(${imageLink.cloud})`,
            }}
          />
          <div
            className={cn(styles.backgroundStatic, styles.cat)}
            style={{
              backgroundImage: `url(${imageLink.cat})`,
            }}
          />
        </div>
      </div>
    </div>
  );
});

export { Bottom };
