import React, { memo } from 'react';
import cn from 'classnames';
import { TEXT } from '../../constants';
import { ReactComponent as PlateBg } from '../../images/plate.svg';
import styles from './Classroom.module.scss';

type ClassroomProps = {
  className?: string;
  text?: string;
};

const { classroom: defaultText } = TEXT;

export const Classroom = memo(function Classroom({
  className = '',
  text = defaultText,
}: ClassroomProps) {
  return (
    <div className={cn(className, styles.classroom)}>
      <PlateBg className={styles.classroomBg} />
      <div className={styles.classroomText} dangerouslySetInnerHTML={{ __html: text }} />
    </div>
  );
});
