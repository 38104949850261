import React, { memo } from 'react';
import { TEXT } from '../../constants';
import styles from './HowItWorks.module.scss';

const urlGif = 'https://static.uchi.ru/files/content-1-4/landings/planning/gifCurs.gif';

export const HowItWorks = memo(function HowItWorks() {
  return (
    <section className={styles.howItWorksSection}>
      <div className={styles.leftColumn}>
        <h2 className={styles.title}>{TEXT.titles.howItWorks}</h2>
        <ul className={styles.list}>
          {TEXT.howItWorksList.map((elem) => (
            <li key={elem} className={styles.elementList}>
              {elem}
            </li>
          ))}
        </ul>
      </div>
      <div className={styles.rightColumn}>
        <div className={styles.wrapperGif}>
          <img src={urlGif} alt="gif" />
        </div>
      </div>
    </section>
  );
});
