import React, { memo } from 'react';
import ReactHtmlParser from 'react-html-parser';
import styles from './MyEmotional.module.scss';
import { Description, TEXT } from '../../constants';
import { BuyButton } from '../BuyButton';
import { LoginButton } from '../LoginButton';
import { MyEmotionalProps } from './types';
import IconInfo3 from '../../images/info_3.svg';
import IconInfo4 from '../../images/info.svg';
import GrishaFirst from '../../images/grishasonya.svg';
import BG from '../../images/bg.svg';

export const MyEmotional = memo(function MyEmotional({
  paymentLink,
  isNeedBuyButton,
  isNeedLoginButton,
  levelClass,
}: MyEmotionalProps) {
  const description = Description.vpr;
  const title = TEXT.title.mainTitle;

  return (
    <section className={styles.main}>
      <div className={styles.contentWrapper}>
        <div className={styles.content}>
          <h2 className={styles.title}>{ReactHtmlParser(title)}</h2>
          <div className={styles.description}>{ReactHtmlParser(description)}</div>
          <div className={styles.iconInfo}>
            {levelClass === '3' && <img src={IconInfo3} alt="IconInfo" />}
            {levelClass === '4' && <img src={IconInfo4} alt="IconInfo" />}
          </div>
          {isNeedBuyButton && (
            <BuyButton className={styles.buyButton} paymentLink={paymentLink} place="top" />
          )}
          {isNeedLoginButton && <LoginButton place="top" className={styles.loginButton} />}
          <div className={styles.pictureContainer}>
            <img src={GrishaFirst} alt="Grisha" />
          </div>
        </div>
      </div>
      <div className={styles.backgroundImage}>
        <img src={BG} alt="BG" />
      </div>
    </section>
  );
});
