import React from 'react';
import slider1Sprite from './images/slider-1.svg';
import slider2Sprite from './images/slider-2.svg';
import slider3Sprite from './images/slider-3.svg';
import slider4Sprite from './images/slider-4.svg';
import slider5Sprite from './images/slider-5.svg';
import slider6Sprite from './images/slider-6.svg';

export const EVENT_BUY = {
  source: 'chess',
  type: 'Payments_from_Landing',
};

export const EVENT_LOGIN = {
  source: 'chess',
  type: 'Login_from_Landing',
};

export const LANDING_URL = `https://${process.env.REACT_APP_LANDING_PAGES_SERVICE}/courses/chess`;

export const TEXT = {
  buttonBuy: {
    text: 'Купить курс',
    link: 'https://fake.something',
  },
  title: {
    advantages: 'Чему научится ребенок?',
    comics: 'Шахматы — это не скучно!',
    slider: '30 уроков курса разделены на 6 частей',
    accordion: 'Часто задаваемые вопросы',
    bottom: 'Половина курса уже доступна для прохождения',
  },
  description: {
    bubble: 'Для детей <br> 6–11 лет',
  },
  advantages: [
    'Правильно ходить фигурами и грамотно начинать партию',
    'Ставить шах и мат разными способами',
    'Замечать ловушки соперника и защищаться от нападения',
    'Решать шахматные задачи',
    'Записывать ходы и играть с часами',
    'Выигрывать партии',
  ],
  comics: [
    'В основе курса сказочная история о Шахматном королевстве',
    'Краткая теория, наглядные анимации, интерактивные задания',
    'Самостоятельное обучение без контроля родителей',
    'Режим сражения доступен с самого начала',
  ],
  bottom: {
    description: (
      <>
        Курс начинается со&nbsp;знакомства с&nbsp;фигурами и&nbsp;постепенно усложняется. Ученики
        решают шахматные задачи, играют в&nbsp;партии-миниатюры и&nbsp;тренируют навыки
        в&nbsp;режиме сражений. Мы&nbsp;продолжаем работать над курсом и&nbsp;выпускать новые уроки.
        Заниматься можно уже сегодня!
      </>
    ),
    bubble: 'Я Так-Тик. Знаю про шахматы всё и научу выигрывать!',
  },
};

export const SLIDER = [
  {
    title: 'Часть 1.',
    description: 'Введение и шахматные фигуры',
    link: slider1Sprite,
  },
  {
    title: 'Часть 2.',
    description: 'Взаимодействие фигур',
    link: slider2Sprite,
  },
  {
    title: 'Часть 3.',
    description: 'Партии-миниатюры',
    link: slider3Sprite,
  },
  {
    title: 'Часть 4.',
    description: 'Завершение партии',
    link: slider4Sprite,
  },
  {
    title: 'Часть 5.',
    description: 'Турнир',
    link: slider5Sprite,
  },
  {
    title: 'Часть 6.',
    description: 'Повторение тем',
    link: slider6Sprite,
  },
];

export const ACCORDIONS = [
  {
    content:
      'Вы получите доступ к урокам курса навсегда, без необходимости продления доступа. Новый урок открывается  после прохождения предыдущего.  Все уровни постепенно появятся до конца 2022 года.',
    header: 'Что я получу после оплаты курса?',
  },
  {
    content:
      'До конца 2022 года. Сейчас ребенок может пройти 1-ю часть курса: введение и знакомство с шахматными фигурами. В течение сентября мы выпустим 2-ю часть — взаимодействие фигур на доске.',
    header: 'Когда курс появится целиком?',
  },
  {
    content:
      'Нет, курс начинается со знакомства с фигурами и постепенно усложняется. Уже в середине обучения ученики смогут решать шахматные задачи и играть в партии-миниатюры. А к концу курса — играть с компьютером на начальном уровне сложности.',
    header: 'Ребенок должен уметь играть в шахматы?',
  },
];

export const Title = {
  chess: 'Шахматы',
};

export const Link = {
  chess: 'https://uchi.ru/payments/provider?type=chess_25_year_990_base_2000',
};

export const Description = {
  chess: 'Интерактивное обучение: от знакомства <br /> с фигурами до шахматного турнира',
};

export const LOGIN_BUTTON_TEXT = 'Войти и купить';
