import React, { memo } from 'react';

import styles from './ThirdScreen.module.scss';

export const ThirdScreen = memo(function ThirdScreen() {
  return (
    <section className={styles['third-screen']}>
      <div className={styles.content}>
        <div className={`${styles.about} ${styles.child}`}>
          <h2>Ты научишься</h2>
          <ul>
            <li>Разбираться в синтаксисе Python</li>
            <li>Создавать мини-игры</li>
            <li>Создавать работающие программы</li>
            <li>Решать абстрактные задачи</li>
            <li>Тестировать свой код</li>
          </ul>
        </div>
        <div className={`${styles.screenshotsContainer} ${styles.child}`}>
          <div className={styles.screenshots}>
            <div className={styles.toys} />
            {/* исходная анимация заменена на статичную картинку */}
            {/* <div className={styles.container}>
              <div className={styles.ball} />
              <div className={styles.pusher} />
            </div> */}
          </div>
          <div className={styles.static} />
        </div>
      </div>
    </section>
  );
});
