import React from 'react';
import cn from 'classnames';
import { observer } from 'mobx-react';
import { Slider } from 'shared/components/Slider';
import { sendEvent } from 'shared/helpers/requests';
import { TEXT, STATICS_BASE_PATH } from '../../constants';
import { store } from '../../store';
import styles from './Reviews.module.scss';

const quoteLeftUrl = `${STATICS_BASE_PATH}/symbol_left.svg`;
const quoteRightUrl = `${STATICS_BASE_PATH}/symbol_right.svg`;

export const Reviews = observer(function Reviews() {
  return (
    <section className={styles.reviewsSection}>
      <h2 className={styles.title}>{TEXT.titles.reviews}</h2>
      <div className={styles.sliderWrapper}>
        <img
          className={cn(styles.quote, styles.quote_left)}
          src={quoteLeftUrl}
          alt="Кавычка открывающая"
        />
        <img
          className={cn(styles.quote, styles.quote_right)}
          src={quoteRightUrl}
          alt="Кавычка закрывающая"
        />
        <Slider
          isShowArrow={false}
          onCurrentItemChange={(newCurrentItemIndex) => {
            sendEvent({
              eventType: 'landing_click_review',
              eventSource: store.eventSource,
              eventInfo: JSON.stringify({
                slide_index: newCurrentItemIndex,
              }),
            });
          }}
          itemComponents={TEXT.reviews.map((text) => (
            <div className={styles.reviewItemText} key={text}>
              {text}
            </div>
          ))}
        />
      </div>
    </section>
  );
});
