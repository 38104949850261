import React, { memo } from 'react';
import { TextBlockProps } from './types';
import styles from './TextBlock.module.scss';

const backgroundImage =
  'http://uchiru-static-files.s3.amazonaws.com/content-1-4/landings/emotional-quotient/images/textblock/bg.svg';

export const TextBlock = memo(function TextBlock(props: TextBlockProps) {
  const { title, text } = props;
  return (
    <div className={styles.main}>
      <div
        className={styles.contentWrapper}
        style={{
          backgroundImage: `url(${backgroundImage})`,
        }}
      >
        <h2 className={styles.title}>{title}</h2>
        <p className={styles.text}>{text}</p>
      </div>
    </div>
  );
});
