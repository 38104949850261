import React, { memo, useState } from 'react';
import { Header } from 'shared/components/Header';
import { Footer } from 'shared/components/Footer';
import { fetchPaymentData } from 'shared/api/fetchPaymentData';
import { fetchHasPremium } from 'shared/api/fetchPremiumData';
import { fetchProfileData, isAuthorizedProfile } from 'shared/api/fetchProfileData';
import { useEffectOnMount } from 'shared/hooks/useEffectOnMount';
import { sendEvent } from 'shared/helpers/requests';
import { MainBanner } from './components/MainBanner';
import { ForWhom } from './components/ForWhom';
import { FooterBlock } from './components/FooterBlock';
import { CourseContent } from './components/CourseContent';
import styles from './NeuroCourse.module.scss';
import { TEXT, EVENT_LOAD, defaultPaymentInfo } from './constants';
import { WhyItWork } from './components/WhyItWork';

export const NeuroCourse = memo(function NeuroCourse() {
  const [paymentInfo, setPaymentInfo] = useState(defaultPaymentInfo);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isNeedBuyButton, setIsNeedBuyButton] = useState(false);
  const [isNeedLoginButton, setIsNeedLoginButton] = useState(false);

  useEffectOnMount(() => {
    async function fetchData() {
      if (process.env.NODE_ENV !== 'development') {
        try {
          const profileData = await fetchProfileData();

          if (isAuthorizedProfile(profileData)) {
            const [paymentOptions, isPremium] = await Promise.all([
              fetchPaymentData('htl_zavr'),
              fetchHasPremium('how_to_learn'),
            ]);

            if (!isPremium) {
              setIsNeedBuyButton(true);
              setPaymentInfo(paymentOptions);
            }
          } else {
            setIsNeedLoginButton(true);
          }
        } catch (error) {
          // eslint-disable-next-line no-console
          console.log('error:', error);
        }
        setIsLoaded(true);
      } else {
        setIsLoaded(true);
      }
    }
    fetchData();
    document.title = TEXT.documentTitle;
    sendEvent({
      eventType: EVENT_LOAD.type,
      eventSource: EVENT_LOAD.source,
      eventInfo: '',
    });
  });

  if (!isLoaded) return null;

  return (
    <div className={styles.main}>
      <Header type="gray" />
      <MainBanner
        isNeedBuyButton={isNeedBuyButton}
        isNeedLoginButton={isNeedLoginButton}
        paymentLink={paymentInfo.paymentPath}
        price={paymentInfo.price}
        basePrice={paymentInfo.basePrice}
      />
      <CourseContent />
      <ForWhom />
      {/* замена на блок с текстом */}
      {/* <Faq /> */}
      <WhyItWork />
      <FooterBlock
        isNeedBuyButton={isNeedBuyButton}
        isNeedLoginButton={isNeedLoginButton}
        paymentLink={paymentInfo.paymentPath}
        price={paymentInfo.price}
        basePrice={paymentInfo.basePrice}
      />
      <Footer />
    </div>
  );
});
