import React from 'react';
import { observer } from 'mobx-react';
import cn from 'classnames';
import { TEXT } from '../../constants';
import styles from './Description.module.scss';

interface TowardsAdventureProps {
  sectionRef: React.RefObject<HTMLDivElement>;
}

export const Description = observer(function Description({ sectionRef }: TowardsAdventureProps) {
  return (
    <section className={styles.descriptionSection} ref={sectionRef}>
      {TEXT.description.map(({ type, title, subtitle }) => (
        <div key={title} className={cn(styles.descriptionItem, styles[`descriptionItem_${type}`])}>
          <div className={styles.descriptionItemText}>
            <div className={styles.descriptionItemTextTitle}>{title}</div>
            <div className={styles.descriptionItemTextSubtitle}>{subtitle}</div>
          </div>
        </div>
      ))}
    </section>
  );
});
