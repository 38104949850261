import React, { memo } from 'react';
import cn from 'classnames';

import { BulletProps } from './types';
import styles from './Bullet.module.scss';

export const Bullet = memo(function Bullet({ isCurrent, onClick }: BulletProps) {
  return (
    <div className={cn(styles.main, isCurrent && styles.main_current)} onClick={onClick}>
      <div className={styles.inner} />
    </div>
  );
});
