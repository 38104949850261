import React, { memo } from 'react';
import cn from 'classnames';
import styles from './index.module.scss';
import topSprite from '../../images/background-top.svg';
import bottomSprite from '../../images/background-bottom.svg';

export const Background = memo(() => (
  <div className={styles.main}>
    <img
      className={cn(styles.image, styles.image_top)}
      src={topSprite}
      draggable={false}
      alt="background"
    />
    <img
      className={cn(styles.image, styles.image_bottom)}
      src={bottomSprite}
      draggable={false}
      alt="background"
    />
  </div>
));
