export const LOGO =
  'https://static.uchi.ru/files/content-1-4/landings/common/images/header-uchiru-logo-white.svg';

export const TEXT_LOGO = 'Учи.ру';

export const EMAIL = 'info@uchi.ru';

export const SOCIAL_LINKS = [
  {
    image: 'https://static.uchi.ru/files/content-1-4/landings/common/footer/vk-icon.svg',
    link: 'https://vk.com/uchi_ru',
    name: 'vk',
  },
  {
    image: 'https://static.uchi.ru/files/content-1-4/landings/common/footer/telegram-icon.svg',
    link: 'https://t.me/uchi_ru_official',
    name: 'telegram',
  },
];
