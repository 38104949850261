import React, { memo } from 'react';
import { useEffectOnMount } from 'shared/hooks/useEffectOnMount';
import { Header } from 'shared/components/Header';
import { Footer } from 'shared/components/Footer';
import { sendEvent } from 'shared/helpers/requests';
import { FirstScreen } from './components/FirstScreen';
import { Program } from './components/Program';
import { ForWhat } from './components/ForWhat';
import { LastScreen } from './components/LastScreen';
import styles from './FinancialLiteracy.module.scss';
import { EVENT_LOAD, TITLE } from './constants';
import { LandingProps } from './types';

export const FinancialLiteracy = memo(function FinancialLiteracy({ typeLanding }: LandingProps) {
  useEffectOnMount(() => {
    document.title = TITLE;
    sendEvent({
      eventType: EVENT_LOAD.type,
      eventSource: EVENT_LOAD.source,
      eventInfo: '',
    });
  });
  return (
    <div className={styles.main}>
      <Header type="gray" />
      <FirstScreen />
      <Program />
      <ForWhat />
      <LastScreen />
      <Footer />
    </div>
  );
});
