import { UCHI_DOMAIN } from 'shared/constants';

export const META = {
  title: 'Чудеса русского языка',
};

export const COURSE_URL = `${UCHI_DOMAIN}/russian-game`;

export const EVENT = {
  source: 'russian_game',
  play: 'landing_button_play',
  buy: 'landing_button_buy',
};

export const TEXT = {
  button: {
    // Кнопка "Играть" показывается только при авторизованности,
    // поэтому нет отдельных текста и ссылки для неавторизованности
    play: {
      text: 'Играть',
      link: COURSE_URL,
    },
    buy: {
      text: 'Купить полную версию',
      // Ссылка на оплату получается из `store.paymentUrl`
      link: '',
    },
    buyWithoutAuthWithRedirect: {
      text: 'Войти и купить',
      // Ссылка на оплату получается из `store.paymentUrl`
      link: '',
    },
  },
  classroom: 'Для 1–4 классов',
  price: {
    purchased: 'Куплено',
    // Остальное получается из `store`
  },
  topBlock: {
    subtitle: 'От фонетики до синтаксиса: закрепляем знания по русскому языку в форме игры',
  },
  courseStructureBlock: {
    title: 'Ребенок сможет',
    subtitles: {
      spelling: 'Запомнить правописание сложных слов',
      morphology: 'Научиться выделять морфемы в слове',
      syntax: 'Находить главные члены предложения',
      partOfSpeech: 'Обобщить знания о разных частях речи',
    },
  },
  aboutBlock: {
    title: 'Сюжет игры',
    subtitle:
      'В работе мы вдохновлялись старинными русскими сказками и былинами. Наш герой пробирается через Дремучий лес и по пути выполняет интересные задания. Его миссия — помочь ​Лешему, Бабе-яге и Русалке стать добрыми и грамотными.',
  },
  futureBlock: {
    title: 'Что еще появится',
    list: [
      'Больше заданий по орфографии и грамматике, лексике и пунктуации',
      'Новые персонажи, карты и сказочные артефакты',
      'Объемная коллекция правил русского языка',
    ],
  },
  bottomBlock: {
    access: 'Доступ навсегда',
    updates: 'Регулярные обновления',
    copyright: `©${new Date().getFullYear()} Учи.ру`,
  },
};
