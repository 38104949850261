import React, { memo, useCallback, useState } from 'react';
import { Header } from 'shared/components/Header';
import { Footer } from 'shared/components/Footer';
import { useEffectOnMount } from '../../../shared/hooks/useEffectOnMount';
import { FirstScreen } from './components/FirstScreen';
import { CourseStructure } from './components/CourseStructure';
import { SimpleComplicated } from './components/SimpleComplicated';
import { UniquePlatform } from './components/UniquePlatform';
import { ConvenientInterface } from './components/ConvenientInterface';
import { LastScreen } from './components/LastScreen';

import { PAYMENT_LINK, TEXT } from './constants';
import styles from './Programming.module.scss';
import { fetchProfileData, isAuthorizedProfile } from '../../../shared/api/fetchProfileData';
import { fetchHasPremium } from '../../../shared/api/fetchPremiumData';
import { ButtonBuyPlace } from '../WordsBattle/components/Buttons/types';
import { sendEvent } from '../../../shared/helpers/requests';
import { getUchiLoginRedirectUrl } from '../../../shared/helpers/getUchiLoginRedirectUrl';

export const Programming = memo(function Programming() {
  const [buyHref, setBuyHref] = useState<string | null>(null);

  useEffectOnMount(() => {
    document.title = TEXT.titles.firstScreen;

    async function fetchData() {
      try {
        const profileData = await fetchProfileData();

        if (isAuthorizedProfile(profileData)) {
          const hasPremium = await fetchHasPremium('informatics');

          if (hasPremium) {
            setBuyHref(null);
          } else {
            setBuyHref(PAYMENT_LINK);
          }
        } else {
          setBuyHref(getUchiLoginRedirectUrl(PAYMENT_LINK));
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log('error fetching profile data:', error);
      }
    }

    fetchData();
  });

  const handleBuyClick = useCallback((place: ButtonBuyPlace) => {
    sendEvent({
      eventType: 'purchase_click',
      eventInfo: JSON.stringify({ place }),
      eventSource: 'programming_landing',
    });
  }, []);

  return (
    <div className={styles.main}>
      <Header type="gray" />
      <FirstScreen onBuyClick={handleBuyClick} buyHref={buyHref} />
      <CourseStructure />
      <SimpleComplicated />
      <UniquePlatform />
      <ConvenientInterface />
      <LastScreen onBuyClick={handleBuyClick} buyHref={buyHref} />
      <Footer />
    </div>
  );
});
