import React, { memo } from 'react';
import { Cloud } from './components/Cloud';
import { CursList } from './components/CursList';
import styles from './FirstScreen.module.scss';
import { ButtonBuyPlace } from '../../../WordsBattle/components/Buttons/types';

interface FirstScreenProps {
  onBuyClick: (place: ButtonBuyPlace) => void;
  buyHref: string | null;
}

export const FirstScreen = memo(function FirstScreen({ onBuyClick, buyHref }: FirstScreenProps) {
  return (
    <section className={styles.firstScreenSection}>
      <Cloud onBuyClick={onBuyClick} buyHref={buyHref} />
      <CursList />
      <div className={styles.rocket}>
        <img
          src="https://static.uchi.ru/files/content-1-4/landings/programming/rocket.svg"
          alt="rocket"
        />
      </div>
      <div className={styles.sputnik}>
        <img
          src="https://static.uchi.ru/files/content-1-4/landings/programming/sputnik.svg"
          alt="sputnik"
        />
      </div>
      <div className={styles.arrow}>
        <img
          src="https://static.uchi.ru/files/content-1-4/landings/programming/arrow.svg"
          alt="arrow"
        />
      </div>
    </section>
  );
});
