import React from 'react';
import cn from 'classnames';
import { sendEvent } from 'shared/helpers/requests';
import { observer } from 'mobx-react';

import { Button } from '../Button';
import { EVENT, TEXT } from '../../constants';
import { store } from '../../store';

import styles from './BuyButton.module.scss';

interface BuyButtonProps {
  className?: string;
}

export const BuyButton = observer(function BuyButton({ className }: BuyButtonProps) {
  const { paymentUrl, hasPremium } = store;

  const onClick = () => {
    sendEvent({
      eventSource: EVENT.source,
      eventType: EVENT.buy,
      eventInfo: '',
    });

    window.location.assign(paymentUrl);
  };

  return (
    <Button
      onClick={onClick}
      color="yellow"
      className={cn(hasPremium && styles.buyButton_hasPremium, className)}
    >
      {hasPremium ? (
        <>
          {TEXT.button.purchased.text}
          <div className={styles.check} />
        </>
      ) : (
        TEXT.button.buy.text
      )}
    </Button>
  );
});
