import React, { memo } from 'react';
import cn from 'classnames';
import styles from './index.module.scss';
import type * as I from './types/interfaces';

export const Container = memo(function Container({ children, className }: I.ContainerProps) {
  const classesRoot = cn(styles.root, className);

  return <div className={classesRoot}>{children}</div>;
});
