import React, { memo } from 'react';

import { TEXT } from '../../constants';
import styles from './CourseStructure.module.scss';

export const CourseStructure = memo(function CourseStructure() {
  return (
    <section className={styles.courseStructureSection}>
      <div className={styles.container}>
        <div className={styles.content}>
          <h2 className={styles.title}>{TEXT.titles.courseStructure}</h2>
          <p className={styles.textStructure}>{TEXT.courseStructureText}</p>
        </div>
        <div className={styles.wrapperImage}>
          <img
            src="https://static.uchi.ru/files/content-1-4/landings/programming/structure_img_new.png"
            alt="structure_img"
          />
        </div>
      </div>
    </section>
  );
});
