import React from 'react';
import { observer } from 'mobx-react';
import { TEXT } from '../../constants';
import { Badge } from '../Badge';
import { ButtonType } from '../../types';
import { Button } from '../Button';
import { Classroom } from '../Classroom';
import { Price } from '../Price';
import styles from './TopBlock.module.scss';

const { subtitle } = TEXT.topBlock;

export const TopBlock = observer(function TopBlock() {
  return (
    <section className={styles.topBlock}>
      <div className={styles.inner}>
        <Badge className={styles.badge} />
        <h2 className={styles.subtitle}>{subtitle}</h2>
        <Button type={ButtonType.PLAY} className={styles.buttonPlay} />
        <Button type={ButtonType.BUY} className={styles.buttonBuy} />
        <div className={styles.screencast}>
          <div className={styles.screencastInner} />
        </div>
        <Classroom className={styles.classroom} />
        <Price className={styles.price} />
      </div>
    </section>
  );
});
