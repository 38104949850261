import React, { memo, TouchEvent, useEffect, useMemo, useRef, useState } from 'react';
import anime from 'animejs';

import cn from 'classnames';
import { useEffectOnUpdate } from '../../hooks/useEffectOnUpdate';
import { Arrow } from './components/Arrow';
import { Bullet } from './components/Bullet';
import { Item } from './components/Item';
import { getOrder } from './helpers/getOrder';
import {
  ITEMS_CONTAINER_X,
  MAX_TIME_TO_SWIPE,
  MIN_PERCENT_TO_SLIDE,
  MIN_PERCENT_TO_SWIPE,
} from './constants';
import { SliderItemType, SliderProps } from './types';
import styles from './styles/Slider.module.scss';

export const Slider = memo(function Slider({
  ArrowComponent,
  BulletComponent,
  itemComponents,
  isShowArrow = true,
  className,
  onCurrentItemChange,
}: SliderProps) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isFadeAnimating, setFadeAnimating] = useState(true);
  const [isLocked, setLocked] = useState(false);
  const [items, setItems] = useState<SliderItemType[]>([]);
  const [itemsContainerTranslateX, setItemsContainerTranslateX] = useState(ITEMS_CONTAINER_X);
  const [startTouchPosition, setStartTouchPosition] = useState(0);
  const [startTouchTime, setStartTouchTime] = useState(0);
  const itemsContainerRef = useRef<HTMLDivElement>(null);

  const currentItems = useMemo(() => items.slice().sort((a, b) => a.order - b.order), [items]);

  const handleBulletClick = (index: number) => {
    if (isLocked) return;

    setCurrentIndex(index);
    setFadeAnimating(true);
  };

  const handleLeftClick = () => {
    if (isLocked) return;

    slidePrevious();
    setFadeAnimating(true);
  };

  const handleRightClick = () => {
    if (isLocked) return;

    slideNext();
    setFadeAnimating(true);
  };

  const handleSlideAnimationEnd = (isSlide: boolean) => {
    if (!isSlide) {
      resetAfterSlideAnimation();
      return;
    }

    if (itemsContainerTranslateX < ITEMS_CONTAINER_X) {
      slideNext();
    } else {
      slidePrevious();
    }

    resetAfterSlideAnimation();
  };

  const handleTouchEnd = () => {
    if (itemsContainerTranslateX === ITEMS_CONTAINER_X) return;

    const slideTime = performance.now() - startTouchTime;
    const minPercentToSlide =
      slideTime < MAX_TIME_TO_SWIPE ? MIN_PERCENT_TO_SWIPE : MIN_PERCENT_TO_SLIDE;

    let newTranslateX = ITEMS_CONTAINER_X;
    if (itemsContainerTranslateX < ITEMS_CONTAINER_X - minPercentToSlide) {
      newTranslateX = ITEMS_CONTAINER_X * 2;
    } else if (Math.abs(ITEMS_CONTAINER_X - itemsContainerTranslateX) > minPercentToSlide) {
      newTranslateX = 0;
    }

    anime({
      targets: itemsContainerRef.current,
      easing: 'easeInOutSine',
      duration: 500,
      autoplay: true,
      translateX: `${newTranslateX}%`,
      complete: () => {
        handleSlideAnimationEnd(newTranslateX !== ITEMS_CONTAINER_X);
      },
    });
  };

  const handleTouchMove = (event: TouchEvent<HTMLDivElement>) => {
    const wrapperNode = event.currentTarget;
    const wrapperWidth = wrapperNode.getBoundingClientRect().width;
    const range = event.touches[0].clientX - startTouchPosition;
    const percentMoved = (range / wrapperWidth) * 100;
    const newContainerX = ITEMS_CONTAINER_X + percentMoved;
    setItemsContainerTranslateX(newContainerX);
  };

  const handleTouchStart = (event: TouchEvent<HTMLDivElement>) => {
    setLocked(true);
    setStartTouchPosition(event.touches[0].clientX);
    setStartTouchTime(performance.now());
    setFadeAnimating(false);
  };

  const resetAfterSlideAnimation = () => {
    setLocked(false);
    setItemsContainerTranslateX(ITEMS_CONTAINER_X);
  };

  const slideNext = () => {
    if (currentIndex + 1 < items.length) {
      setCurrentIndex(currentIndex + 1);
    } else {
      setCurrentIndex(0);
    }
  };

  const slidePrevious = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    } else {
      setCurrentIndex(items.length - 1);
    }
  };

  useEffect(() => {
    const newItems = itemComponents.map((component, index) => ({
      component,
      index,
      isVisible: index === currentIndex,
      order: getOrder(index, currentIndex, itemComponents.length),
    }));
    setItems(newItems);
  }, [itemComponents, currentIndex]);

  useEffect(() => {
    setLocked(false);
  }, [items]);

  useEffectOnUpdate(() => {
    onCurrentItemChange?.(currentIndex);
  }, [currentIndex]);

  return (
    <div className={cn(styles.main, className)}>
      <div className={styles.content}>
        {isShowArrow && (
          <div className={styles.arrowsContainer}>
            {ArrowComponent ? (
              <>
                <ArrowComponent onClick={handleLeftClick} type="left" />
                <ArrowComponent onClick={handleRightClick} type="right" />
              </>
            ) : (
              <>
                <Arrow onClick={handleLeftClick} type="left" />
                <Arrow onClick={handleRightClick} type="right" />
              </>
            )}
          </div>
        )}
        <div
          className={styles.itemsWrapper}
          onTouchEnd={handleTouchEnd}
          onTouchMove={handleTouchMove}
          onTouchStart={handleTouchStart}
        >
          <div
            className={styles.itemsContainer}
            ref={itemsContainerRef}
            style={{ transform: `translateX(${itemsContainerTranslateX}%)` }}
          >
            {currentItems.map(({ component, index, isVisible, order }) => (
              <Item
                isVisible={isVisible}
                key={index}
                component={component}
                isFadeAnimating={isFadeAnimating}
                order={order}
              />
            ))}
          </div>
        </div>
      </div>
      <div className={styles.bulletsContainer}>
        {items.map(({ index, isVisible }) =>
          BulletComponent ? (
            <BulletComponent
              key={index}
              isCurrent={isVisible}
              onClick={() => handleBulletClick(index)}
            />
          ) : (
            <Bullet key={index} isCurrent={isVisible} onClick={() => handleBulletClick(index)} />
          ),
        )}
      </div>
    </div>
  );
});
