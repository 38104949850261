import React, { memo, useCallback } from 'react';
import { Button } from 'shared/components/Button';
import { sendEvent } from 'shared/helpers/requests';
import { TEXT, STATICS_LINK, LINK_COURSE, EVENT_BUY } from '../../constants';
import styles from './FirstScreen.module.scss';

const imageLink = `${STATICS_LINK}pic_header.svg`;

export const FirstScreen = memo(function FirstScreen() {
  const clickPaymentButton = useCallback(() => {
    sendEvent({
      eventType: EVENT_BUY.type,
      eventSource: EVENT_BUY.source,
      eventInfo: 'top',
    });
  }, []);
  return (
    <section className={styles.firstScreen}>
      <div className={styles.innerWrapper}>
        <div className={styles.leftColumn}>
          <h1 className={styles.title}>{TEXT.titles.firstScreen}</h1>
          <p className={styles.text}>{TEXT.textFistScreen}</p>
          <div className={styles.containerButtonWithPrice}>
            <a href={LINK_COURSE} target="_blank" rel="noreferrer">
              <Button
                callback={clickPaymentButton}
                className={styles.curseLink}
                defaultOnClick={false}
                text={TEXT.textButtonCurse}
              />
            </a>
          </div>
        </div>
        <div className={styles.rightColumn}>
          <img className={styles.image} src={imageLink} alt="" />
        </div>
      </div>
    </section>
  );
});
