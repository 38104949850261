import React from 'react';
import { observer } from 'mobx-react';
import { Accordion } from 'shared/components/Accordion';
import { TEXT } from '../../constants';
import styles from './FAQ.module.scss';

export const FAQ = observer(function FAQ() {
  return (
    <section className={styles.faqSection}>
      <h2 className={styles.title}>{TEXT.titles.faq}</h2>
      <Accordion accordions={TEXT.faqAccordion} theme="white" />
    </section>
  );
});
