import React, { memo } from 'react';
import cn from 'classnames';

import { BuyButton } from '../BuyButton';
import { BottomProps } from './types';
import styles from './Bottom.module.scss';
import { LoginButton } from '../LoginButton';
import takTikSprite from '../../images/bottom-tak-tik.svg';
import bubbleSprite from '../../images/bottom-bubble.svg';

const Bottom = memo(function Bottom({
  paymentLink,
  text,
  title,
  isNeedBuyButton,
  isNeedLoginButton,
}: BottomProps) {
  return (
    <div className={styles.main}>
      <div className={styles.contentWrapper}>
        <h2 className={styles.title}>{title}</h2>
        <div className={styles.description}>
          <div className={styles.description_content}>{text.description}</div>
        </div>
        {isNeedBuyButton && (
          <BuyButton className={styles.buyButton} paymentLink={paymentLink} place="bottom" />
        )}
        {isNeedLoginButton && <LoginButton place="bottom" className={styles.loginButton} />}
        <div className={styles.takTik}>
          <div className={styles.bubbleContainer}>
            <div
              className={cn(styles.backgroundStatic, styles.bubbleSprite)}
              style={{
                backgroundImage: `url(${bubbleSprite})`,
              }}
            />
            <div className={styles.bubbleText}>{text.bubble}</div>
          </div>

          <div
            className={cn(styles.backgroundStatic, styles.takTikSprite)}
            style={{
              backgroundImage: `url(${takTikSprite})`,
            }}
          />
        </div>
      </div>
    </div>
  );
});

export { Bottom };
