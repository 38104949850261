import React, { memo } from 'react';
import cn from 'classnames';
import ReactHtmlParser from 'react-html-parser';
import styles from './index.module.scss';
import type * as I from './types/interfaces';
import { PROMOTION_COURSE_DATA } from '../../constants';
import { BuyButton } from '../BuyButton';
import { LoginButton } from '../LoginButton';

export const PromotionSection = memo(function PromotionSection({
  className,
  isNeedBuyButton,
  isNeedLoginButton,
  paymentLink,
}: I.PromotionSectionProps) {
  const classesRoot = cn(styles.root, className);

  return (
    <section className={classesRoot}>
      <div className={styles.hero} />
      <div className={styles.bliki} />
      <div className={styles.inner}>
        <h2 className={styles.title}>{PROMOTION_COURSE_DATA.title}</h2>
        <div className={styles.text}>{ReactHtmlParser(PROMOTION_COURSE_DATA.text)}</div>
        {isNeedBuyButton && (
          <BuyButton className={styles.buyButton} paymentLink={paymentLink} place="top" />
        )}
        {isNeedLoginButton && <LoginButton place="top" className={styles.loginButton} />}
      </div>
    </section>
  );
});
