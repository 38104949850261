import React from 'react';
import cn from 'classnames';
import { observer } from 'mobx-react';
import { TEXT } from '../../constants';
import { store } from '../../store';
import styles from './Description.module.scss';

export const Description = observer(function Description() {
  return (
    <section className={styles.descriptionSection}>
      <div className={styles.main}>
        <div className={styles.mainBg} />
        <p className={styles.mainText}>
          <span className={styles.mainTextInner}>{TEXT.description}</span>
        </p>
      </div>
      <div className={styles.list}>
        {TEXT.descriptionList.map((item) => (
          <div key={item.text} className={cn(styles.listItem, styles[`listItem_${item.type}`])}>
            <img className={styles.listItemIcon} src={item.iconUrl} alt={item.text} />
            <div className={styles.listItemText}>
              {item.text}
              {item.detailsListByParallel && store.studentParallel !== null && (
                /* eslint-disable no-nested-ternary */
                <ul>
                  {/*
                  Раньше был различный вывод списка тем в зависимости от класса ученика,
                  но после ухода ЦОК (который может ещё вернуться) решили объединить
                  списки для всех, оставляя разделенную структуру `detailsListByParallel`
                  и этот комментарий. Если нужно будет вернуть, можно ревертнуть коммит,
                  в котором был добавлен этот коммент.
                  А пока отображаем объединённый список тем для более широкой
                  аудитории потенциальных покупателей.
                  */}
                  {Object.values(item.detailsListByParallel)
                    .flat()
                    .map((detail) => (
                      <li key={detail}>{detail}</li>
                    ))}
                </ul>
              )}
            </div>
          </div>
        ))}
      </div>
    </section>
  );
});
