import React, { memo } from 'react';
import cn from 'classnames';

import styles from './BuyButton.module.scss';
import { TEXT } from '../../constants';
import { ButtonBuyPlace } from '../../../WordsBattle/components/Buttons/types';

interface ButtonProps {
  href: string | null;
  className?: string;
  onClick: (place: ButtonBuyPlace) => void;
  place: ButtonBuyPlace;
}

export const BuyButton = memo(function BuyButton({ href, className, onClick, place }: ButtonProps) {
  const handleClick = () => {
    onClick(place);
  };

  return href ? (
    <a
      href={href}
      target="_blank"
      rel="noreferrer"
      onClick={handleClick}
      className={cn(styles.main, className)}
    >
      {TEXT.buy}
    </a>
  ) : null;
});
