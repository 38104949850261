import React, { memo } from 'react';
import cn from 'classnames';
import { TEXT } from '../../constants';

import styles from './SimpleComplicated.module.scss';

export const SimpleComplicated = memo(function SimpleComplicated() {
  return (
    <section className={styles.simpleComplicatedSection}>
      <div className={styles.innerWrapper}>
        <div className={styles.container}>
          <div className={cn(styles.leftColumn, styles.column)}>
            <div className={styles.arrow} />
            <div className={styles.title}>{TEXT.simple.title}</div>

            <div className={styles.imageWrapper}>
              <img src={TEXT.simple.image} alt="simple" />
            </div>
            <div className={styles.text}>{TEXT.simple.text}</div>
          </div>
          <div className={cn(styles.rightColumn, styles.column)}>
            <div className={styles.title}>{TEXT.complicated.title}</div>
            <div className={styles.imageWrapper}>
              <img src={TEXT.complicated.image} alt="simple" />
            </div>
            <div className={styles.text}>{TEXT.complicated.text}</div>
          </div>
        </div>
      </div>
    </section>
  );
});
