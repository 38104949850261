import { createContext, useContext } from 'react';
import type * as I from './types/interfaces';

const ContextAnatomy = createContext<I.ContextAnatomy>({
  type: 'unauthorized',
  from: '',
  profile: null,
  isAuthorized: false,
  isPremium: false,
  paymentLink: '',
});
ContextAnatomy.displayName = 'AnatomyContext';

export const ContextAnatomyProvider = ContextAnatomy.Provider;
export const useContextAnatomy = () => useContext(ContextAnatomy);
