import React from 'react';
import cn from 'classnames';

import styles from './Button.module.scss';

interface ButtonProps {
  color?: 'yellow' | 'blue';
  className?: string;
  onClick: () => void;
}

export const Button = function Button({
  color = 'yellow',
  className,
  children,
  onClick,
}: React.PropsWithChildren<ButtonProps>) {
  return (
    <div className={cn(styles.buttonWrapper, styles[`buttonWrapper_${color}`], className)}>
      <button onClick={onClick} type="button" className={styles.button}>
        {children}
      </button>
    </div>
  );
};
