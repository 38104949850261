import React, { memo } from 'react';
import { TEXT } from '../../../../constants';
import styles from './Python.module.scss';

export const Python = memo(function Python() {
  return (
    <section className={styles.pythonSection}>
      <h2 className={styles.title}>{TEXT.titles.python}</h2>
      <div className={styles.wrapperList}>
        {TEXT.python.map(({ text, image }) => (
          <div className={styles.elemList} key={text}>
            <div className={styles.wrapperImage}>
              <img className={styles.imageNumber} src={image} alt="number" />
            </div>
            <p className={styles.text}>{text}</p>
          </div>
        ))}
      </div>
    </section>
  );
});
