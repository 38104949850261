import { FeedbackCardType } from './types';

export const META = {
  title: 'Наука вокруг нас',
  description: 'Лэндинг курса наука вокруг нас',
};

export const TEXT = {
  mainBanner: 'Детективная игра. Исследуем окружающий мир через эксперименты',
  buy: 'Купить',
  gameNow: 'Играйте прямо сейчас',
  tryFree: 'Попробовать бесплатно',
  goalsHeader: 'Вас ждут',
  inGameHeader: 'Ребенок:',
  feedbackHeader: 'Что говорят дети',
};

export const FOOTER_TEXT = [
  '45+ экспериментов',
  'Развитие логики\nи изобретательности',
  'Детективная история',
];

export const GOAL = [
  'Задания на внимательность, логику и изобретательность',
  'Гриша, Соня, Кныш и новые заврики',
  'Загадочная история с уликами и «пасхалками»',
];

export const RESULT = [
  'Проведет 45+ опытов\nв безопасной онлайн-среде',
  'Закрепит знания по предмету\n«Окружающий мир» на практике',
  'Потренирует\nизобретательность, которая\nпригодится и на школьных\nолимпиадах, и в жизни',
  'Станет наблюдательнее, будет\nбольше интересоваться тем, как\nустроен мир вокруг',
  'Разберется в причинах и\nследствиях, научится делать\nвыводы',
];

export const FEEDBACK: FeedbackCardType[] = [
  {
    author: 'Виталий, 2 класс',
    text: 'Мне понравился сюжет с детективами, очень красочные и интересные задания!',
    count: 5,
    id: 1,
  },
  {
    id: 2,
    text: 'Иногда попадаются сложные задания, но в целом игра — КЛАСС!!! Спасибо разработчикам!',
    author: 'Саша, 2 класс',
    count: 4,
  },
  {
    id: 3,
    text: 'Мне понравилось совмещать различные материалы и создавать из них новые предметы',
    author: 'Лев, 3 класс',
    count: 5,
  },
  {
    id: 4,
    text: 'Очень интересно: продолжение может быть любым, это интригует',
    author: 'Аня, 4 класс',
    count: 5,
  },
  {
    id: 5,
    text: 'Прошел до 9-го уровня. ПОЛНАЯ БОМБА!',
    author: 'Костя, 1 класс',
    count: 5,
  },
  {
    id: 6,
    text: 'Интересно разгадывать загадки. И тут очень классные персонажи',
    author: 'Маша, 3 класс',
    count: 5,
  },
];
export const LINK_COURSE =
  'https://uchi.ru/payments/provider?type=game__chemistry_25_year_990_base_2000';

export const LINK_PLAY = 'https://uchi.ru/science-around/main';

export const LANDING_URL = `https://${process.env.REACT_APP_LANDING_PAGES_SERVICE}/courses/science`;
