import React, { memo } from 'react';
import cn from 'classnames';
import styles from './index.module.scss';
import * as I from './types/interfaces';

export const ParallaxBlock = memo(function ParallaxBlock({
  className,
  children,
}: I.ParallaxBlockProps) {
  const backgroundStyles = cn(styles.background, className);
  return (
    <div className={styles.root}>
      <div className={styles.wrapper}>
        <div className={backgroundStyles} />
        <div className={styles.content}>{children}</div>
      </div>
    </div>
  );
});
