import { getPaymentLink } from '@uchi/payment-link';
import { UCHI_DOMAIN } from '../constants';

/**
 * Получение платёжных данных для курса.
 *
 * На стейдже и проде ходит к соседнему сервису платёжки https://github.com/uchiru/payments,
 * который отдаёт динамические платёжные данные в зависимости от разных факторов,
 * которые конфигурируются продактами в админке цен. Если что-то не так
 * с получаемыми данными, то искать проблему там или в передаваемом имени курса тут.
 *
 * Локально в разработке без дополнительного сервиса webpack-dev-server
 * отдаёт мок `payments_api_v2_products.json`, поэтому локально всегда всё хорошо
 * (но моковые данные общие для всех курсов).
 */
export const fetchPaymentData = (
  ...args: Parameters<typeof getPaymentLink>
): ReturnType<typeof getPaymentLink> => {
  const [arg1, argOptions, ...restArgs] = args;
  return getPaymentLink(
    arg1,
    {
      customHost: UCHI_DOMAIN,
      ...argOptions,
    },
    ...restArgs,
  );
};
