export const TEXT = {
  titles: {
    mainTitle: 'Словесная битва',
    duel: 'Как насчет орфографической дуэли с одноклассниками?',
    helpTitle: 'Помогаем ребенку:',
  },
  helpedList: [
    {
      subTitle: 'Грамотно писать слова',
      text: 'Верно передавать услышанное на письме и замечать орфограммы',
    },
    {
      subTitle: 'Тренировать внимание',
      text: 'Фокусироваться на задании и допускать меньше ошибок',
    },
    {
      subTitle: 'Готовиться к диктантам',
      text: 'Запоминать правописание словарных слов и чувствовать себя увереннее во время проверочных работ ',
    },
    {
      subTitle: 'Совершенствовать знания',
      text: 'Работать над ошибками и справляться с более сложными заданиями ',
    },
  ],
  featureList: [
    'Соревнование поддерживает интерес к занятиям',
    'Ребенок может менять внешний вид персонажа',
    'Рейтинг и награды вдохновляют на большие успехи',
    'Прогресс в игре синхронизируется на всех устройствах',
  ],
  checkoutList: ['Отработка ошибок', 'Доступ навсегда', 'Более 2600 слов для тренировки'],
  firstPageText: 'Соревновательная игра для подготовки учеников 1–4 классов к диктантам',
  duelText: 'Победит тот, кто правильнее и быстрее напишет все слова',
  buy: 'Купить',
  try: 'Попробовать бесплатно',
  game: 'Играть',
  valuta: '₽',
};
