import React, { memo } from 'react';
import { COURSE_CONTENT_DATA } from 'components/pages/Anatomy/data';
import ReactHtmlParser from 'react-html-parser';
import styles from './index.module.scss';
import { Container } from '../../ui/Container';
import { ImageWithDescription } from '../../ui/ImageWithDescription';
import LotsOfPracticeGIF from './media/lots_of_practise.gif';
import SkeletonPNG from './media/skeleton.png';
import ChooseCharacter from './media/choose_character.png';
import { HeroSection } from '../../ui/HeroSection';

export const CourseContent = memo(function CourseContent() {
  const {
    title,
    descriptionTitle,
    desciptionParagraph,
    gifDescription,
    leftPictureDescription,
    rightPictureDescription,
  } = COURSE_CONTENT_DATA;

  return (
    <div className={styles.root}>
      <div className={styles.img__heart} />
      <div className={styles.img__lungs} />
      <Container>
        <h2 className={styles.title}>{ReactHtmlParser(title)}</h2>
        <HeroSection
          textConfig={{ title: descriptionTitle, text: desciptionParagraph }}
          imageConfig={{ imageUrl: LotsOfPracticeGIF, description: gifDescription }}
        />
        <div className={styles.gallery}>
          <ImageWithDescription
            className={styles.block__left}
            imageUrl={SkeletonPNG}
            description={leftPictureDescription}
          />
          <ImageWithDescription
            className={styles.block__right}
            imageUrl={ChooseCharacter}
            description={rightPictureDescription}
          />
        </div>
      </Container>
    </div>
  );
});
