import React from 'react';
import { observer } from 'mobx-react';

import styles from './ErrorCorrectionBlock.module.scss';
import { Screencast } from '../Screencast';

export const ErrorCorrectionBlock = observer(function ErrorCorrectionBlock() {
  return (
    <section className={styles.trainBlock}>
      <Screencast className={styles.screencast} variant={3} />
    </section>
  );
});
