import React, { memo } from 'react';
import Countdown from 'react-countdown';
import cn from 'classnames';

import { zeroPad } from './helpers/zeroPad';
import { DAY_MILLISECONDS, LABELS_TEXT } from './constants';
import {
  CountdownTimerProps,
  CountdownTimerRendererData,
  CountdownTimerRendererProps,
} from './types';
import styles from './CountdownTimer.module.scss';

function CountdownTimerRenderer({
  classNames,
  days,
  hours,
  isLabelsVisible,
  minutes,
  seconds,
  template,
}: CountdownTimerRendererProps) {
  const blockClassName = cn(styles.block, classNames.block);
  const dividerClassName = cn(styles.divider, classNames.divider);
  const labelClassName = cn(styles.label, classNames.label);
  const mainClassName = cn(
    styles.main,
    styles[`main_color_${template.color}`],
    styles[`main_size_${template.size}`],
    classNames.main,
  );
  const valueClassName = cn(styles.value, classNames.value);

  return (
    <div className={mainClassName}>
      <div className={blockClassName}>
        <div className={valueClassName}>{zeroPad(days)}</div>
        {isLabelsVisible && <div className={labelClassName}>{LABELS_TEXT.days}</div>}
      </div>
      <div className={dividerClassName}>:</div>
      <div className={blockClassName}>
        <div className={valueClassName}>{zeroPad(hours)}</div>
        {isLabelsVisible && <div className={labelClassName}>{LABELS_TEXT.hours}</div>}
      </div>
      <div className={dividerClassName}>:</div>
      <div className={blockClassName}>
        <div className={valueClassName}>{zeroPad(minutes)}</div>
        {isLabelsVisible && <div className={labelClassName}>{LABELS_TEXT.minutes}</div>}
      </div>
      <div className={dividerClassName}>:</div>
      <div className={blockClassName}>
        <div className={valueClassName}>{zeroPad(seconds)}</div>
        {isLabelsVisible && <div className={labelClassName}>{LABELS_TEXT.seconds}</div>}
      </div>
    </div>
  );
}

export const CountdownTimer = memo(function CountdownTimer({
  classNames = {},
  color = 'black',
  date,
  isLabelsVisible = false,
  onComplete,
  repeatAfterDays = 0,
  size = 'm',
}: CountdownTimerProps) {
  const currentDate = Date.now();
  let targetDate = new Date(date).getTime();
  const template = { color, size };

  if (currentDate >= targetDate) {
    targetDate += repeatAfterDays * DAY_MILLISECONDS;
  }

  return (
    <Countdown
      date={targetDate}
      onComplete={onComplete}
      renderer={(props: CountdownTimerRendererData) =>
        CountdownTimerRenderer({ ...props, classNames, isLabelsVisible, template })
      }
    />
  );
});
