import React, { memo } from 'react';
import styles from './FooterBlock.module.scss';
import { TEXT } from '../../constants';
import { FooterBlockProps } from './types';
import { ButtonAndPrice } from '../ButtonAndPrice';
import { LoginButton } from '../LoginButton';

export const FooterBlock = memo(function FooterBlock({
  isNeedBuyButton,
  isNeedLoginButton,
  paymentLink,
  price,
  basePrice,
}: FooterBlockProps) {
  return (
    <section className={styles.footerBlockSection}>
      <div className={styles.imageBlock}>
        <img
          src="https://static.uchi.ru/files/content-1-4/landings/neuroCourse/baby.svg"
          alt="baby"
        />
      </div>
      <div className={styles.footerBlockWrapper}>
        <div className={styles.footerBlockContent}>
          <ul className={styles.footerBlockList}>
            {TEXT.footerBlockText.map((text, ind) => (
              <li key={`footerBlockText_${ind + 1}`}>{text}</li>
            ))}
          </ul>
          <div className={styles.mainBannerWrapperButton}>
            {isNeedBuyButton && (
              <ButtonAndPrice
                paymentLink={paymentLink}
                place="bottom"
                price={price}
                basePrice={basePrice}
                isShowPrice
                isShowBasePrice={false}
              />
            )}
            {isNeedLoginButton && <LoginButton place="bottom" />}
          </div>
        </div>
      </div>
    </section>
  );
});
