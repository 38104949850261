import React, { useState } from 'react';
import { Header } from 'shared/components/Header';
import { Footer } from 'shared/components/Footer';
import { useEffectOnMount } from 'shared/hooks/useEffectOnMount';
import { fetchPaymentData } from 'shared/api/fetchPaymentData';
import { fetchHasPremium } from 'shared/api/fetchPremiumData';
import { fetchProfileData, isAuthorizedProfile } from 'shared/api/fetchProfileData';
import { Advantages } from './components/Advantages';
import { Comics } from './components/Comics';
import { Chess as ChessComp } from './components/Chess';
import { Slider } from './components/Slider';
import { Bottom } from './components/Bottom';
import { Background } from './components/Background';
import { Description, Link, SLIDER, TEXT, Title } from './constants';
import styles from './Chess.module.scss';

function Chess() {
  const [paymentLink, setPaymentLink] = useState('');
  const [isLoaded, setIsLoaded] = useState(false);
  const [isNeedBuyButton, seIsNeedBuyButton] = useState(false);
  const [isNeedLoginButton, setIsNeedLoginButton] = useState(false);

  useEffectOnMount(() => {
    async function fetchData() {
      if (process.env.NODE_ENV !== 'development') {
        try {
          const profileData = await fetchProfileData();

          if (isAuthorizedProfile(profileData)) {
            const [paymentInfo, hasPremium] = await Promise.all([
              fetchPaymentData('chess'),
              fetchHasPremium('chess'),
            ]);
            if (!hasPremium) {
              seIsNeedBuyButton(true);
              setPaymentLink(paymentInfo.paymentPath);
            }
          } else {
            setIsNeedLoginButton(true);
          }
        } catch (error) {
          // eslint-disable-next-line no-console
          console.log('error:', error);
        }
        setIsLoaded(true);
      } else {
        setPaymentLink(Link.chess);
        setIsLoaded(true);
      }
    }

    fetchData();
    document.title = Title.chess;
  });

  if (!isLoaded) return null;

  return (
    <div className={styles.main}>
      <h1 className="visually-hidden">{Title.chess}</h1>
      <Header type="gray" />
      <Background />
      <ChessComp
        bubbleText={TEXT.description.bubble}
        description={Description.chess}
        paymentLink={paymentLink}
        title={Title.chess}
        isNeedBuyButton={isNeedBuyButton}
        isNeedLoginButton={isNeedLoginButton}
      />
      <Slider data={SLIDER} title={TEXT.title.slider} />
      <Advantages data={TEXT.advantages} title={TEXT.title.advantages} />
      <Comics data={TEXT.comics} title={TEXT.title.comics} />
      <Bottom
        paymentLink={paymentLink}
        text={{
          bubble: TEXT.bottom.bubble,
          description: TEXT.bottom.description,
        }}
        title={TEXT.title.bottom}
        isNeedBuyButton={isNeedBuyButton}
        isNeedLoginButton={isNeedLoginButton}
      />
      <Footer className={styles.footer} />
    </div>
  );
}

export { Chess };
