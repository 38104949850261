import React, { memo } from 'react';
import { Accordion as AccordionComp } from 'shared/components/Accordion';
import styles from './Accordion.module.scss';
import { TEXT, ACCORDIONS } from '../../constants';

const Accordion = memo(function Accordion() {
  const data = ACCORDIONS;
  const title = TEXT.title.accordion;
  return (
    <section className={styles.main}>
      <div className={styles.contentWrapper}>
        <div className={styles.starImage} />
        <h2 className={styles.title}>{title}</h2>
        <AccordionComp
          accordions={data}
          className={styles.accordionContainer}
          contentClassName={styles.itemTextContent}
          itemClassName={styles.item}
          titleItemClassName={styles.itemText}
          theme="black"
        />
      </div>
    </section>
  );
});

export { Accordion };
