import React from 'react';
import { MobXProviderContext } from 'mobx-react';
import { AppStore as App } from './App';

export default App;

export const useStore = () => {
  const { appStore } = React.useContext(MobXProviderContext);
  return appStore;
};
