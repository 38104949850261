import React, { memo } from 'react';
import { CountdownTimer } from 'shared/components/CountdownTimer';
import { TEXT } from '../../constants';

import { BannerProps } from '../../types';

import styles from './Banner.module.scss';

const urlImageBG = 'https://static.uchi.ru/files/content-1-4/landings/planning/bg_image_1.png';

export const Banner = memo(function Banner({
  basePrice,
  price,
  sale,
  isShowPrice,
  date,
}: BannerProps) {
  return (
    <section className={styles.bannerSection}>
      <div className={styles.innerWrapper}>
        <div className={styles.contentBlock}>
          <h2 className={styles.title}>{TEXT.titles.banner}</h2>
          <div className={styles.priceWrapper}>
            <div className={styles.wrapperButton}>button</div>
            {isShowPrice && (
              <div className={styles.priceContainer}>
                <div className={styles.newOldPrice}>
                  <div className={styles.oldPrice}>
                    <del>
                      {basePrice} {TEXT.valuta}
                    </del>
                  </div>
                  <div className={styles.price}>{`${price} ${TEXT.valuta}`}</div>
                </div>
                <div className={styles.sale}>{sale}</div>
              </div>
            )}
          </div>
        </div>
        <img className={styles.bgImage} src={urlImageBG} alt="bg" />
        <div className={styles.timerWrapper}>
          <div className={styles.timerTitle}>{TEXT.saleTimer}</div>
          <div className={styles.timerContainer}>
            <CountdownTimer
              color="white"
              date={date}
              isLabelsVisible
              repeatAfterDays={7}
              size="m"
            />
          </div>
        </div>
      </div>
    </section>
  );
});
