import React, { memo } from 'react';
import cn from 'classnames';
import ReactHtmlParser from 'react-html-parser';
import styles from './index.module.scss';
import type * as I from './types/interfaces';
import { Title } from '../../ui/Title';
import { Fact } from './components/Fact';
import { ABOUT_COURSE_DATA } from '../../../data';
import { Container } from '../../ui/Container';

export const AboutSection = memo(function AboutSection({ className }: I.AboutSectionProps) {
  const classesRoot = cn(styles.root, className);

  return (
    <section className={classesRoot}>
      <Container className={styles.inner}>
        <Title className={styles.title}>{ABOUT_COURSE_DATA.title}</Title>
        <div className={styles.facts}>
          {ABOUT_COURSE_DATA.facts.map((fact) => (
            <div key={fact.id} className={styles.fact}>
              <Fact srcImage={fact.srcImage} altImage={fact.altImage} text={fact.text} />
            </div>
          ))}
        </div>
        <div className={styles.text}>{ReactHtmlParser(ABOUT_COURSE_DATA.text)}</div>
      </Container>
    </section>
  );
});
