import React from 'react';
import cn from 'classnames';
import { observer } from 'mobx-react';
import { TEXT } from '../../constants';
import { store } from '../../store';
import { ReactComponent as PlateBg } from '../../images/plate.svg';
import styles from './Price.module.scss';

type PriceProps = {
  className?: string;
};

const { purchased } = TEXT.price;

export const Price = observer(function Price({ className = '' }: PriceProps) {
  if (!store.isAuthorized) return null;

  const { price, basePrice } = store;
  const discountPercent = Math.floor(100 - (price / basePrice) * 100);

  return (
    <div className={cn(className, styles.price)}>
      <PlateBg className={styles.priceBg} />
      {store.hasPremium ? (
        <>
          <div className={styles.check} />
          <div className={styles.purchased}>{purchased}</div>
        </>
      ) : (
        <>
          <div className={styles.priceTextTotal}>{price} ₽</div>
          <div className={styles.priceTextBase}>{basePrice} ₽</div>
          <div className={styles.discount}>{`-${discountPercent}%`}</div>
        </>
      )}
    </div>
  );
});
