import React, { memo, useState } from 'react';
import cn from 'classnames';
import styles from './index.module.scss';
import { TEXT, FOOTER_TEXT } from '../../constants';
import { Microcomponentik } from './Microcomponentik';

export const FooterBlock = memo(function FooterBlock({ link }: { link: string | null }) {
  const [isActivated, setIsActivated] = useState(false);
  const handleClick = () => {
    setIsActivated(false);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <div className={styles.logo} />
        {FOOTER_TEXT.map((text) => (
          <Microcomponentik key={text} text={text} />
        ))}
        {link && (
          <a href={link} target="_blank" rel="noreferrer">
            <div
              className={cn(styles.button, isActivated && styles.button_activated)}
              onMouseDown={() => setIsActivated(true)}
              onMouseUp={handleClick}
            >
              {TEXT.buy}
            </div>
          </a>
        )}
      </div>
    </div>
  );
});
