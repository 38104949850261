import UAParser from 'ua-parser-js';
import { camelCase } from 'lodash';

export type DeviceData = {
  browserName?: string;
  osName?: string;
  deviceModel?: string;
  deviceType?: string;
  touchType: 'touch' | 'no-touch' | 'mouse-and-touch';
  vh: number;
};

export const getDeviceData = () => {
  const parser = new UAParser();
  const result = parser.getResult();
  const { maxTouchPoints, platform } = navigator;
  const userDeviceData: DeviceData = { touchType: 'no-touch', vh: window.innerHeight * 0.01 };

  if (result.browser.name) userDeviceData.browserName = camelCase(result.browser.name);
  if (result.os.name) userDeviceData.osName = camelCase(result.os.name);
  if (result.device.model) userDeviceData.deviceModel = camelCase(result.device.model);
  if (result.device.type) userDeviceData.deviceType = camelCase(result.device.type);

  if (result.device.type === 'mobile' || result.device.model === 'tablet') {
    userDeviceData.touchType = 'touch';
  } else if (result.ua.match(/ipad|iphone|android|blackberry|phone/i)) {
    userDeviceData.touchType = 'touch';
  } else if (platform === 'MacIntel' && maxTouchPoints > 1) {
    userDeviceData.touchType = 'touch';
    userDeviceData.deviceModel = 'iPad';
    userDeviceData.deviceType = 'tablet';
  }

  if (userDeviceData.touchType === 'no-touch' && ('ontouchstart' in window || maxTouchPoints > 0)) {
    userDeviceData.touchType = 'mouse-and-touch';
  }

  return userDeviceData;
};
