import React, { memo } from 'react';
import cn from 'classnames';
import ReactHtmlParser from 'react-html-parser';
import styles from './index.module.scss';
import type * as I from './types/interfaces';
import { Title } from '../../ui/Title';
import { DIPLOMA_COURSE_DATA } from '../../../data';
import { Container } from '../../ui/Container';
import { Button } from '../../ui/Button';

export const DiplomaSection = memo(function DiplomaSection({ className }: I.DiplomaSectionProps) {
  const classesRoot = cn(styles.root, className);

  return (
    <section className={classesRoot}>
      <Container className={styles.inner}>
        <div className={styles.textBox}>
          <Title className={styles.title}>{DIPLOMA_COURSE_DATA.title}</Title>
          <p className={styles.text}>{ReactHtmlParser(DIPLOMA_COURSE_DATA.text)}</p>
          <Button className={styles.buttonDesktop} />
        </div>
        <div className={styles.imageBox}>
          <img
            className={styles.image}
            src={DIPLOMA_COURSE_DATA.srcImage}
            alt={DIPLOMA_COURSE_DATA.altImage}
          />
        </div>
        <Button className={styles.buttonMobile} />
      </Container>
    </section>
  );
});
