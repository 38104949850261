import React, { memo } from 'react';
import { TEXT, FEEDBACK } from '../../constants';
import { FeedbackCard } from '../FeedbackCard';
import styles from './index.module.scss';

export const Feedback = memo(function Feedback() {
  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>{TEXT.feedbackHeader}</div>
      <div className={styles.feedback}>
        {FEEDBACK.map((feedback, i) => (
          <FeedbackCard key={String(i)} {...feedback} />
        ))}
      </div>
    </div>
  );
});
