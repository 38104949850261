import React, { memo } from 'react';
import cn from 'classnames';
import { TEXT } from '../../constants';
import styles from './FutureBlock.module.scss';

const { title, list } = TEXT.futureBlock;

export const FutureBlock = memo(function FutureBlock() {
  return (
    <section className={styles.futureBlock}>
      <div className={styles.ornament} />
      <h1 className={styles.title}>{title}</h1>
      {list.map((text, ind) => (
        <div key={text} className={cn(styles.item, styles[`item_${ind + 1}`])}>
          <div className={styles.itemPlate}>
            <div className={styles.itemPlateBg} />
            <div className={styles.itemPlateNumber}>{ind + 1}</div>
          </div>
          <div className={styles.itemText}>{text}</div>
        </div>
      ))}
    </section>
  );
});
