import React, { memo } from 'react';

import cn from 'classnames';
import { TEXT } from '../../constants';

import styles from './UniquePlatform.module.scss';

export const UniquePlatform = memo(function UniquePlatform() {
  return (
    <section className={styles.uniquePlatformSection}>
      <div className={styles.innerBlock}>
        <h2 className={styles.title}>{TEXT.titles.uniquePlatform}</h2>
        <ul className={styles.listUnique}>
          {TEXT.uniquePlatformList.map((elem) => (
            <li key={elem.text} className={styles.itemUnique}>
              <div className={cn(styles.icon, styles[`icon_${elem.image}`])} />
              <p className={styles.text}>{elem.text}</p>
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
});
