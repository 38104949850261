import React, { memo } from 'react';
import { Button } from 'shared/components/Button';

import stylesCommon from '../../Programming2035.module.scss';
import styles from './SeventhScreen.module.scss';

export const SeventhScreen = memo(function SeventhScreen() {
  return (
    <section className={styles['seventh-screen']}>
      <div className={styles['content-wrapper']}>
        <div className={`${styles['child-block']} ${styles.first}`}>
          <div className={styles.first__inner}>
            <h2 className={stylesCommon.alwaysWhite}>
              Изучай, программируй
              <br />
              и создавай собственные
              <br />
              проекты на Python
            </h2>
            <ul>
              <li className={styles.projectsNumberDesktop}>4 проекта и 50+ задач</li>
              <li className={styles.projectsNumberMobile}>3 проекта и 90+ задач</li>
              <li>Справочник</li>
              <li>Сертификат</li>
              <li>Все будущие проекты и задачи курса</li>
            </ul>
            <a href={process.env.REACT_APP_PROG_2035_AUTH_URL} rel="noreferrer">
              <Button text="Получить доступ" className={stylesCommon.btn} defaultOnClick={false} />
            </a>
          </div>
        </div>
      </div>
    </section>
  );
});
