import { makeAutoObservable } from 'mobx';
import { SetNonNullable } from 'type-fest';
import { getUnauthorizedPaymentRedirectChainUrl } from 'shared/helpers/paymentRedirect';

class RussianGameStore {
  isReady = false;

  studentId: number | null = null;

  studentParallel: number | null = null;

  hasPremium = false;

  price = 0;

  basePrice = 0;

  // По умолчанию не авторизованы, пока не вызвали `init` с платёжной инфой.
  paymentUrl = getUnauthorizedPaymentRedirectChainUrl();

  constructor() {
    makeAutoObservable(this);
  }

  get isAuthorized() {
    return !!this.studentId;
  }

  init(data: RussianGameStoreData) {
    (Object.keys(data) as Array<keyof RussianGameStoreData>).forEach((key) => {
      // @ts-ignore
      this[key] = data[key];
    });

    this.isReady = true;
  }

  setIsReadyNotLoggedIn() {
    this.isReady = true;
  }
}

type RussianGameStoreData = SetNonNullable<
  Pick<
    RussianGameStore,
    'studentId' | 'studentParallel' | 'hasPremium' | 'price' | 'basePrice' | 'paymentUrl'
  >
>;

export const store = new RussianGameStore();
