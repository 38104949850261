import React, { memo } from 'react';
import { Slider } from 'shared/components/Slider';

import cn from 'classnames';
import styles from './ConvenientInterface.module.scss';

import { TEXT } from '../../constants';

const array = Array.from(Array(5).keys());

const imagesList = array.map((elem, index) => (
  <div className={cn(styles.image, styles[`image_${index + 1}`])} key={`slider_${index + 1}`} />
));

export const ConvenientInterface = memo(function ConvenientInterface() {
  return (
    <section className={styles.convenientInterfaceSection}>
      <h2 className={styles.title}>{TEXT.titles.convenientInterface}</h2>
      <Slider itemComponents={imagesList} />
    </section>
  );
});
