import React, { memo } from 'react';
import styles from './index.module.scss';
import { GOAL, TEXT } from '../../constants';
import { Goal } from '../Goal/Goal';

export const Goals = memo(function Goals() {
  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>{TEXT.goalsHeader}</div>
      <div className={styles.goals}>
        {GOAL.map((goal, i) => (
          <Goal key={goal} text={goal} id={i} />
        ))}
      </div>
    </div>
  );
});
