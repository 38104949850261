import React, { memo } from 'react';
import { BUBBLE_TEXTS } from 'components/pages/Anatomy/data';
import styles from './index.module.scss';
import { ParallaxBlock } from '../../ui/ParallaxBlock';
import { Bubble } from '../../ui/Bubble';

export const ImmunitySection = memo(function ImmunitySection() {
  return (
    <ParallaxBlock className={styles.root}>
      <Bubble
        classNameRoot={styles.bubbleRoot}
        classNameBubble={styles.bubble}
        color="green"
        shape="egg"
        text={BUBBLE_TEXTS.immunity}
      />
      <div className={styles.pilot} />
    </ParallaxBlock>
  );
});
