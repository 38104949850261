import React, { memo } from 'react';
import cn from 'classnames';
import { TEXT } from '../../constants';
import styles from './Button.module.scss';

interface ButtonProps {
  type: 'play' | 'buy' | 'purpleBuy' | 'details';
  className?: string;
  onClick(): void;
}

export const Button = memo(function Button({ type, className, onClick }: ButtonProps) {
  return (
    <button
      type="button"
      className={cn(styles.button, styles[`button_${type}`], className)}
      onClick={onClick}
    >
      {TEXT.buttons[type === 'purpleBuy' ? 'buy' : type]}
    </button>
  );
});
