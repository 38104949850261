import React, { memo } from 'react';
import styles from './index.module.scss';
import { ParallaxBlock } from '../../ui/ParallaxBlock';
import { Bubble } from '../../ui/Bubble';
import { BUBBLE_TEXTS } from '../../../data';

export const AboutSection = memo(function AboutSection() {
  return (
    <ParallaxBlock className={styles.root}>
      <div className={styles.blob} />
      <Bubble
        classNameRoot={styles.bubbleRoot}
        color="yellow"
        shape="egg"
        text={BUBBLE_TEXTS.about}
      />
    </ParallaxBlock>
  );
});
