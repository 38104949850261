import { UCHIRU_LINK } from 'shared/constants';
import { ANATOMY_URL } from './constants';

export const BUTTON_DATA = {
  unauthorized: {
    text: 'перейти на uchi.ru',
    link: UCHIRU_LINK,
  },
  bought: {
    text: 'перейти к курсу',
    link: ANATOMY_URL,
  },
  notBought: {
    text: 'купить курс',
    link: '',
  },
};

export const MAIN_BANNER_DATA = {
  title: ['Моё ', 'тело'],
  subtitle: 'Курс про анатомию<br />и здоровый образ жизни',
  targetAudience: 'Для учеников 1–5 классов',
};

export const BUBBLE_TEXTS = {
  about: 'Курс поможет понять,<br/>как связаны разные<br/>части организма',
  nutrition: 'Почему важно<br/>разнообразное питание',
  physhicalActivity: 'Почему важна<br/>физическая активность',
  nervousSystem: 'Как устроены нервная<br/>система и мозг',
  metabolism: 'Как происходит<br/>обмен веществ',
  immunity: 'Как работает<br/>иммунитет',
};

export const COURSE_CONTENT_DATA = {
  title: 'ИЗ ЧЕГО СОСТОИТ КУРС',
  descriptionTitle: 'Интерактивный атлас <br/>тела',
  desciptionParagraph: 'Наглядно объясняем, как устроен человек',
  gifDescription: 'Много практики',
  leftPictureDescription: 'Можно посмотреть название каждого органа',
  rightPictureDescription: 'Можно выбрать персонажа',
};

export const VIDEO_LESSONS_DATA = {
  title: 'Видеоуроки',
  text: 'Доступно о сложном: <br/>рассказываем не просто об органах, а об организме как единой взаимосвязанной системе',
  imageDescription: 'Урок «Что и зачем поступает в твой организм»',
};

export const TASKS_DATA = {
  title: 'Игровые задания',
  text: 'Помогаем закрепить полученные <br/>знания и проверить, насколько всё </br>понятно',
  imageDescription: 'Задания «Зачем мы едим?» и «Что необходимо для получения энергии?»',
};

export const DIPLOMAS_DATA = {
  title: '<strong>ДИПЛОМ В КОНЦЕ КУРСА</strong>',
  text: 'Пройдя все задания, ребенок получит не только полезные знания, но и диплом с любимыми персонажами',
};

export const PROMOTION_DATA = {
  advantages: ['Доступ навсегда', 'Диплом в конце курса'],
  copyright: 'Учи.ру @ 2023',
};
