import React, { memo } from 'react';
import ReactHtmlParser from 'react-html-parser';
import cn from 'classnames';
import { BuyButton } from '../BuyButton';
import { MyEmotionalProps } from './types';
import styles from './Chess.module.scss';
import { LoginButton } from '../LoginButton';
import kingsSprite from '../../images/kings.svg';

export const Chess = memo(function Chess({
  bubbleText,
  description,
  paymentLink,
  title,
  isNeedBuyButton,
  isNeedLoginButton,
}: MyEmotionalProps) {
  return (
    <div className={styles.main}>
      <div className={styles.contentWrapper}>
        <div className={styles.content}>
          <h2 className={styles.title}>{title}</h2>
          <div
            className={cn(
              styles.description,
              !isNeedLoginButton && !isNeedBuyButton && styles.description_withoutButton,
            )}
          >
            {ReactHtmlParser(description)}
          </div>
          <div className={styles.bubble}>{ReactHtmlParser(bubbleText)}</div>
          {isNeedBuyButton && (
            <BuyButton className={styles.buyButton} paymentLink={paymentLink} place="top" />
          )}
          {isNeedLoginButton && <LoginButton place="top" className={styles.loginButton} />}
          <div
            className={cn(
              styles.pictureContainer,
              !isNeedLoginButton && !isNeedBuyButton && styles.pictureContainer_withoutButton,
            )}
          >
            <div
              className={cn(styles.backgroundStatic, styles.picture)}
              style={{
                backgroundImage: `url(${kingsSprite})`,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
});
