import { UCHIRU_LINK } from 'shared/constants';
import { ADVANCED_MATH_URL } from './constants';
import { getImagePath } from './utils/getImagePath';

export const BASE_DATA = {
  PRICE: 990,
  CURRENCY: 'руб.',
};

export const MAIN_BANNER_DATA = {
  title: 'Математика<span>Плюс</span>',
  text: '<p>Курс для тех, кому хочется ещё больше математических заданий!</p> <p>В игровой форме учит ребёнка логически мыслить и самостоятельно решать задачи.</p>',
};

export const BUTTON_DATA = {
  unauthorized: {
    text: 'перейти на учи.ру',
    link: UCHIRU_LINK,
  },
  bought: {
    text: 'перейти к курсу',
    link: ADVANCED_MATH_URL,
  },
  notBought: {
    text: 'купить курс',
    link: '',
  },
};

export const ABOUT_COURSE_DATA = {
  title: 'О курсе',
  text: 'В курсе Математика Плюс нужно <span>экспериментировать</span> и <span>самостоятельно</span> придумывать решения задач. Мы даём ребёнку пространство для <span>исследований.</span> Не навязываем шаблонные решения, а помогаем взглянуть на задачу с разных сторон и самостоятельно найти выход.',
  facts: [
    {
      id: 1,
      srcImage: getImagePath('about_1.svg'),
      altImage: 'first',
      text: 'Для учеников 1–6 классов с любым уровнем подготовки',
    },
    {
      id: 2,
      srcImage: getImagePath('about_2.svg'),
      altImage: 'second',
      text: 'Типов заданий в курсе, развивающих разные навыки',
    },
    {
      id: 3,
      srcImage: getImagePath('about_3.svg'),
      altImage: 'third',
      text: 'Уровней в каждом задании. Сложность уровней постепенно возрастает',
    },
  ],
};

export const PARTITIONS_COURSE_DATA = {
  title: 'Разделы курса',
  partitions: [
    {
      id: 1,
      tag: {
        text: 'море',
        colorHex: '4B7ED4',
      },
      srcImage: getImagePath('partition_1.gif'),
      altImage: 'first',
      title: 'Пространственное мышление',
      text: 'Разберёмся что такое симметрия и решим пространственные головоломки',
      withBuyButton: false,
    },
    {
      id: 2,
      tag: {
        text: 'пустыня',
        colorHex: 'FCE68A',
      },
      srcImage: getImagePath('partition_2.gif'),
      altImage: 'second',
      title: 'Логические задачи',
      text: 'Будем строить сложные умозаключения внутри красочной игры',
      withBuyButton: false,
    },
    {
      id: 3,
      tag: {
        text: 'лес',
        colorHex: '28a842',
      },
      srcImage: getImagePath('partition_3.gif'),
      altImage: 'third',
      title: 'Задачи на счёт и числа',
      text: 'Нестандартные задачки на счёт. От простого до олимпиадного уровня сложности',
      withBuyButton: true,
    },
  ],
};

export const DIPLOMA_COURSE_DATA = {
  srcImage: getImagePath('diploma.svg'),
  altImage: 'diploma',
  title: 'Диплом в конце курса',
  text: 'В конце курса ребёнок получает не только полезные навыки, но и диплом с персонажем из курса.',
};

export const REVIEWS_COURSE_DATA = {
  title: 'Отзывы',
  reviews: [
    {
      id: 1,
      altImage: 'review_1',
      srcImage: getImagePath('review_1.svg'),
    },
    {
      id: 2,
      altImage: 'review_2',
      srcImage: getImagePath('review_2.svg'),
    },
    {
      id: 3,
      altImage: 'review_3',
      srcImage: getImagePath('review_3.svg'),
    },
    {
      id: 4,
      altImage: 'review_4',
      srcImage: getImagePath('review_4.svg'),
    },
    {
      id: 5,
      altImage: 'review_5',
      srcImage: getImagePath('review_5.svg'),
    },
    {
      id: 6,
      altImage: 'review_6',
      srcImage: getImagePath('review_6.svg'),
    },
  ],
};

export const PROMOTION_COURSE_DATA = {
  text: '<span>800 тыс. учеников</span> уже попробовали курс Математика Плюс',
};
