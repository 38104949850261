import React, { memo, useCallback } from 'react';
import ReactHtmlParser from 'react-html-parser';
import cn from 'classnames';
import { useContextAdvancedMath } from 'components/pages/AdvancedMath/context';
import { Button } from '../../ui/Button';
import styles from './index.module.scss';
import type * as I from './types/interfaces';

import { MAIN_BANNER_DATA } from '../../../data';
import { Container } from '../../ui/Container';
import { BackButton } from '../../ui/BackButton';

export const MainBannerSection = memo(function MainBannerSection({
  className,
}: I.MainBannerSectionProps) {
  const { from } = useContextAdvancedMath();

  const handleClickBackButton = useCallback(() => {
    window.location.href = from;
  }, [from]);

  const classesRoot = cn(styles.root, className);

  return (
    <section className={classesRoot}>
      <BackButton className={styles.backButton} onClick={handleClickBackButton} />
      <div className={styles.hero} />
      <div className={styles.cloudBottom} />
      <Container className={styles.inner}>
        <div className={styles.content}>
          <h1 className={styles.title}>{ReactHtmlParser(MAIN_BANNER_DATA.title)}</h1>
          <div className={styles.text}>{ReactHtmlParser(MAIN_BANNER_DATA.text)}</div>
          <Button className={styles.button} />
        </div>
      </Container>
    </section>
  );
});
