import React, { memo } from 'react';
import { TEXT, FEEDBACK } from '../../constants';
import { FeedbackCard } from '../FeedbackCard';
import styles from './index.module.scss';
import bottomPlant from '../../images/plant_bottom.svg';
import leftBottomBrick from '../../images/bricks_left_bottom.svg';
import rightBottomBrick from '../../images/bricks_right_bottom.svg';

export const Feedback = memo(function Feedback() {
  return (
    <section className={styles.section_feedback}>
      <div className={styles.plant}>
        <img src={bottomPlant} alt="bottom_plant" />
      </div>
      <div className={styles.bricks_container}>
        <div className={styles.left_brick}>
          <img src={leftBottomBrick} alt="left_bottom_brick" />
        </div>
        <div className={styles.right_brick}>
          <img src={rightBottomBrick} alt="right_bottom_brick" />
        </div>
      </div>
      <div className={styles.wrapper}>
        <h2 className={styles.header}>{TEXT.feedbackHeader}</h2>
        <div className={styles.feedback}>
          {FEEDBACK.map((feedback, i) => (
            <FeedbackCard key={String(i)} {...feedback} />
          ))}
        </div>
      </div>
    </section>
  );
});
