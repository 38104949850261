import React, { memo } from 'react';
import cn from 'classnames';
import ReactHtmlParser from 'react-html-parser';
import styles from './index.module.scss';
import * as I from './types/interfaces';

export const ImageWithDescription = memo(function ImageWithDescription({
  imageUrl,
  description,
  className,
  classNameImage,
}: I.ImageWithDescriptionProps) {
  const rootStyles = cn(styles.root, className);
  const imageStyles = cn(styles.gif, classNameImage);

  return (
    <div className={rootStyles}>
      <img className={imageStyles} src={imageUrl} alt={description} />
      {description && <p className={styles.description}>{ReactHtmlParser(description)}</p>}
    </div>
  );
});
