import React from 'react';
import lottie, {
  AnimationConfigWithData,
  AnimationItem,
  AnimationEventName,
  AnimationSegment,
} from 'lottie-web';
import style from './index.module.scss';

export type LottiePlayerProps = Pick<
  AnimationConfigWithData,
  'initialSegment' | 'renderer' | 'rendererSettings' | 'animationData'
> & {
  playing?: boolean;
  segments?: AnimationSegment;
  direction?: -1 | 1;
  speed?: number;
  loop?: boolean;
  staticFrame?: number;
  onLoaded?: (instance: AnimationItem) => void;
  onComplete?: () => void;
  onLoopComplete?: () => void;
  onEnterFrame?: () => void;
  onSegmentStart?: () => void;
};

const useLottieEventHandler = ({
  lottieItem,
  eventName,
  handler,
}: {
  lottieItem: AnimationItem | null;
  eventName: AnimationEventName;
  handler?: () => void;
}) => {
  React.useEffect(() => {
    // Костыль
    // При _cbs === null на addEventListener падает ошибка лотти (не совсем понял, в чём дело)
    // @ts-ignore
    if (!lottieItem || !lottieItem._cbs) return () => {};

    const handleComplete = () => handler?.();

    lottieItem.addEventListener(eventName, handleComplete);

    return () => {
      // @ts-ignore
      if (!lottieItem || !lottieItem._cbs) return;
      lottieItem.removeEventListener(eventName, handleComplete);
    };
  }, [eventName, handler, lottieItem]);
};

export function LottiePlayer(props: LottiePlayerProps) {
  const {
    initialSegment,
    loop = false,
    renderer = 'svg',
    rendererSettings,
    animationData,
    playing = false,
    segments,
    direction = 1,
    speed = 1,
    staticFrame = 0,
    onLoaded,
    onComplete,
    onLoopComplete,
    onEnterFrame,
    onSegmentStart,
  } = props;

  const containerRef = React.useRef<HTMLDivElement>(null);
  const [lottieItem, setLottieItem] = React.useState<AnimationItem | null>(null);
  const lottieRef = React.useRef<AnimationItem | null>(null);
  const [loaded, setLoaded] = React.useState(false);
  const initialNumberOfFrames = React.useRef(0);

  useLottieEventHandler({
    lottieItem,
    eventName: 'complete',
    handler: onComplete,
  });

  useLottieEventHandler({
    lottieItem,
    eventName: 'loopComplete',
    handler: onLoopComplete,
  });

  useLottieEventHandler({
    lottieItem,
    eventName: 'enterFrame',
    handler: onEnterFrame,
  });

  useLottieEventHandler({
    lottieItem,
    eventName: 'segmentStart',
    handler: onSegmentStart,
  });

  React.useEffect(() => {
    lottieRef.current = lottie.loadAnimation({
      container: containerRef.current!,
      animationData,
      initialSegment,
      loop,
      renderer,
      rendererSettings,
    });

    setLottieItem(lottieRef.current);

    const handleLoaded = () => {
      setLoaded(true);
      initialNumberOfFrames.current = lottieRef.current?.totalFrames || 0;
      lottieRef.current?.setSegment(0, initialNumberOfFrames.current);
      lottieRef.current?.goToAndStop(staticFrame, true);
      onLoaded?.(lottieRef.current!);
    };

    lottieRef.current.addEventListener('DOMLoaded', handleLoaded);

    return () => {
      lottieRef.current?.removeEventListener('DOMLoaded', handleLoaded);
      lottieRef.current?.destroy();
      lottieRef.current = null;
      setLottieItem(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [animationData, initialSegment, onLoaded, renderer, rendererSettings]);

  React.useEffect(() => {
    if (!lottieRef.current || !loaded) return;

    if (playing && segments) {
      lottieRef.current.playSegments(segments, true);
    } else if (playing && !segments) {
      lottieRef.current.play();
    } else {
      lottieRef.current.setSegment(0, initialNumberOfFrames.current);
      lottieRef.current.goToAndStop(staticFrame, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loaded, playing, segments]);

  React.useEffect(() => {
    if (!playing && lottieRef.current && loaded) {
      lottieRef.current.setSegment(0, initialNumberOfFrames.current);
      lottieRef.current.goToAndStop(staticFrame, true);
    }
  }, [loaded, playing, staticFrame]);

  React.useEffect(() => {
    if (!lottieItem) return;
    lottieItem.loop = loop;
  }, [loop, lottieItem]);

  React.useEffect(() => {
    lottieItem?.setDirection(direction);
  }, [direction, lottieItem]);

  React.useEffect(() => {
    lottieItem?.setSpeed(speed);
  }, [lottieItem, speed]);

  return <div ref={containerRef} className={style.main} />;
}
