import React, { memo } from 'react';
import { TEXT } from '../../constants';
import styles from './BabyBanner.module.scss';

const urlImageBG = 'https://static.uchi.ru/files/content-1-4/landings/planning/bg_image_2.png';
export const BabyBanner = memo(function BabyBanner() {
  return (
    <section className={styles.babyBannerSection}>
      <div className={styles.innerWrapper}>
        <img className={styles.bgImage} src={urlImageBG} alt="bg" />
        <div className={styles.wrapperContent}>
          <p className={styles.text}>{TEXT.bannerText}</p>
        </div>
      </div>
    </section>
  );
});
