import { FeedbackCardType } from './types';

export const META = {
  title: 'Английский со зверятами',
  description: 'Лэндинг курса наука английский со зверятами',
};

export const TEXT = {
  gameNow: 'Играйте прямо сейчас',
  tryFree: 'Попробовать бесплатно',
  storyHeader: 'Английский — это весело!',
  feedbackHeader: 'Что говорят дети',
  faqHeader: 'Вопросы и ответы',
};

export const FAQ_ACCORDION = [
  {
    header: 'Для учеников каких классов подойдет игра?',
    content:
      'Задания подобраны с учетом программы английского в 1-4 классах. Уровень владения языком может значительно отличаться даже в пределах одного класса. Чтобы разным ученикам было интересно заниматься, мы разработали адаптивную систему сложности. Слова и механики в игре предлагаются\nв зависимости от предыдущих ответов ребенка.',
  },
  {
    header: 'В чем польза игры?',
    content:
      'По данным внутреннего исследования, восемь из десяти преподавателей английского на Учи.ру отмечают нехватку языковой практики. К сожалению, ни групповые, ни индивидуальные занятия не могут дать ребенку этого в полной мере. Для закрепления изученных слов необходима самостоятельная отработка, что может быть скучной и непростой задачей для ученика начальных классов.\n \nТренажер «Английский со зверятами» решает эту проблему, предоставляя ребенку возможность незаметно повторять слова. Ухаживая за питомцами, ученик изучает и запоминает значение слов, их написание и произношение. \n \n Важно отметить, что благодаря игре ребенок знакомится с языком в дружелюбной форме, приобретает навык изучения новых иностранных слов.',
  },
  {
    header: 'Сколько заданий в игре?',
    content: 'Сейчас в игре представлено пять питомцев и более 350 уникальных заданий',
  },
];

export const FOOTER_MARKS = [
  'Запомнить новые слова',
  'Научиться различать\nих на слух',
  'Подружиться с языком\nчерез игру',
];
export const TRAINING_ITEM = [
  { position: 'first', content: 'Запомнить новые английские слова' },
  { position: 'second', content: 'Научиться различать их на слух' },
  { position: 'third', content: 'Подружиться с языком через игру' },
];
export const GOAL = [
  'Много картинок, анимация и забавная озвучка',
  'Главные герои — милые питомцы, каждый со своим характером',
  'Увлекательный сюжет помогает лучше запомнить слова',
  'Сложность заданий подбирается\nв зависимости от предыдущих ответов',
];

export const FEEDBACK: FeedbackCardType[] = [
  {
    id: 1,
    author: 'Виталий, 3 класс',
    text: 'Очень милый котик в начале, слова просто запоминаются.\nВы ОТЛИЧНО постарались!',
    count: 5,
  },
  {
    id: 2,
    author: 'Саша, 1 класс',
    text: 'Понравилось, что рыженький котенок играл на гитаре\nи танцевал под музыку ',
    count: 4,
  },
  {
    id: 3,
    author: 'Юля, 4 класс',
    text: 'Понравилось изучать английские слова, их звучание, написание, покупать для питомцев все необходимое в магазинчике и заботиться о них',
    count: 5,
  },
  {
    id: 4,
    author: 'Арсений, 2 класс',
    text: 'Миленькие зверята 🐱🦊 помогают мне учить английский язык ',
    count: 5,
  },
  {
    id: 5,
    author: 'Богдан, 2 класс',
    text: 'Я бы хотел посмотреть еще дома и котят. Буду за ними ухаживать',
    count: 5,
  },
  {
    id: 6,
    author: 'Костя, 1 класс',
    text: 'Это лучшая игра на Учи.ру! Хорошо получились котята,\nс ними легко запоминаешь английский язык',
    count: 5,
  },
];
export const LINK_COURSE =
  'https://uchi.ru/payments/provider?type=game__english_pet_25_year_990_base_2000';

export const LINK_PLAY = 'https://uchi.ru/english_pet/main';

export const LANDING_URL = `https://${process.env.REACT_APP_LANDING_PAGES_SERVICE}/courses/english_pet`;
