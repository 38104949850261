import { getUchiLoginRedirectUrl } from '../../../../../shared/helpers/getUchiLoginRedirectUrl';
import { UCHI_DOMAIN } from '../../../../../shared/constants';
import { sendEvent } from '../../../../../shared/helpers/requests';
import { ButtonBuyPlace } from './types';

const getClickBuyEventTrigger = (place: ButtonBuyPlace) => () => {
  sendEvent({
    eventType: 'purchase_click',
    eventSource: 'words_battle',
    eventInfo: JSON.stringify({ place }),
  });
};

export const getClickBuyTop = getClickBuyEventTrigger(ButtonBuyPlace.top);
export const getClickBuyBottom = getClickBuyEventTrigger(ButtonBuyPlace.bottom);

export const getDemoHref = (isAuth: boolean): string => {
  const demoHref = `${UCHI_DOMAIN}/words_game`;

  return isAuth ? demoHref : getUchiLoginRedirectUrl(demoHref);
};
