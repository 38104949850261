import React, { memo } from 'react';
import { ReactComponent as IconSVG } from '../../images/logo.svg';
import styles from './Roof.module.scss';
import { Button } from '../../shared/Button';

export const Roof = memo(function Roof({ link }: { link: string | null }) {
  return (
    <section className={styles.section_roof}>
      <div className={styles.container}>
        <IconSVG className={styles.logo} />
        <div className={styles.content}>
          <p className={styles.content_text}>
            Игровой тренажер <br /> для изучения языка с нуля
          </p>
          <div className={styles.label} />
        </div>
        <div className={styles.buy_course}>
          <Button link={link} btnClassName="orange_btn" hasPrice text="Купить курс" />
        </div>
      </div>
    </section>
  );
});
