import React, { memo, useState } from 'react';
import cn from 'classnames';
import styles from './index.module.scss';
import { TEXT } from '../../constants';

export const MainBanner = memo(function MainBanner({ link }: { link: string | null }) {
  const [isActivated, setIsActivated] = useState(false);
  const handleClick = () => {
    setIsActivated(false);
  };

  return (
    <div className={styles.mainBanner}>
      <div className={styles.content}>
        <div className={styles.logo} />
        <div className={styles.text}>{TEXT.mainBanner}</div>
        {link && (
          <a href={link} target="_blank" rel="noreferrer">
            <div
              className={cn(styles.button, isActivated && styles.button_activated)}
              onMouseDown={() => setIsActivated(true)}
              onMouseUp={handleClick}
            >
              {TEXT.buy}
            </div>
          </a>
        )}
      </div>
    </div>
  );
});
