import React, { memo } from 'react';

import stylesCommon from '../../Programming2035.module.scss';
import styles from './SecondScreen.module.scss';

export const SecondScreen = memo(function SecondScreen() {
  return (
    <section className={`${styles['second-screen']} ${styles.bg2}`}>
      <div className={`${styles.laptop} ${styles.child}`} />
      <div className={`${styles.about} ${styles.child}`} >
        <h2 className={stylesCommon.alwaysWhite}>О курсе</h2>
        <p className={stylesCommon.standardP}>
          Восстанавливаем планету, изучая основы{' '}
          <span className={stylesCommon.nowrap}>и возможности</span> Python{' '}
          <span className={stylesCommon.nowrap}>с помощью</span> интерактивных задач{' '}
          <span className={stylesCommon.nowrap}>и создания</span> проектов
        </p>
        <ul className={styles.animated}>
          <li>
            <h3 className={styles.landing__header}>Возраст</h3>
            <span>
              Школьники <br />
              10-17 лет
            </span>
          </li>
          <li>
            <h3 className={`${styles.landing__header} ${stylesCommon.nowrap}`}>Для кого</h3>
            <span>
              Любой <br />
              уровень
            </span>
          </li>
          <li>
            <h3 className={styles.landing__header}>Формат</h3>
            <span>
              Интерактивные <br />
              <span className={stylesCommon.nowrap}>задачи онлайн</span>
            </span>
          </li>
        </ul>
      </div>
    </section>
  );
});
