import React, { memo, useRef, useState } from 'react';
import { useEffectOnMount } from 'shared/hooks/useEffectOnMount';
import { useSearchParams } from 'react-router-dom';
import { Button } from 'shared/components/Button';
import { fetchHasPremium } from 'shared/api/fetchPremiumData';
import { fetchProfileData, isAuthorizedProfile } from 'shared/api/fetchProfileData';
import { fetchPaymentData } from 'shared/api/fetchPaymentData';
import { FooterBlock } from './components/FooterBlock';
import { HeaderBlock } from './components/HeaderBlock';
import { WhatWillNext } from './components/WhatWillNext';
import { Peculiarities } from './components/Peculiarities';
import { ReadingText } from './components/ReadingText';
import { TEXT, Title, BUTTON, UCHIRU_LINK } from './constants';
import styles from './Reading.module.scss';

const PRODUCT_NAME = 'rdng2';

export const Reading = memo(function Reading() {
  const referrer = useRef(document.referrer);
  const [params] = useSearchParams();
  const from = referrer.current || params.get('from') || UCHIRU_LINK;
  const [paymentLink, setPaymentLink] = useState({ link: '', text: '' });
  const [isLoaded, setIsLoaded] = useState(false);

  useEffectOnMount(() => {
    async function fetchData() {
      if (process.env.NODE_ENV !== 'development') {
        try {
          const profileData = await fetchProfileData();

          if (isAuthorizedProfile(profileData)) {
            const hasPremium = await fetchHasPremium('read');
            if (!hasPremium) {
              const { paymentPath } = await fetchPaymentData(PRODUCT_NAME);
              setPaymentLink({ ...BUTTON.default, link: paymentPath });
            } else {
              setPaymentLink(BUTTON.premium);
            }
          } else {
            setPaymentLink(BUTTON.pr);
          }
        } catch (error) {
          // eslint-disable-next-line no-console
          console.log('error:', error);
        }
        setIsLoaded(true);
      } else {
        setPaymentLink(BUTTON.default);
        setIsLoaded(true);
      }
    }

    fetchData();
    document.title = Title.reading;
  });

  if (!isLoaded) return null;

  const getButton = () => (
    <Button
      defaultOnClick
      text={paymentLink.text}
      href={paymentLink.link}
      className={styles.button_Reading}
    />
  );

  return (
    <div className={styles.main}>
      <HeaderBlock
        button={getButton()}
        subtitle={TEXT.subtitles.default}
        title={TEXT.titles.main}
        from={from}
      />
      <WhatWillNext />
      <Peculiarities />
      <ReadingText />
      <FooterBlock button={getButton()} />
    </div>
  );
});
