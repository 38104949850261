import React, { memo } from 'react';
import cn from 'classnames';
import styles from './TrainingItem.module.scss';

interface TrainingItemProps {
  content: string;
  position: string;
}

export const TrainingItem = memo(function TrainingItem({ content, position }: TrainingItemProps) {
  return (
    <div className={cn(styles.block, styles[position])}>
      <p className={styles.text}>{content}</p>
    </div>
  );
});
