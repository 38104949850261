import React from 'react';
import { observer } from 'mobx-react';

import styles from './BottomBlock.module.scss';
import { TEXT } from '../../constants';
import { BuyButton } from '../BuyButton';

const { subtitle } = TEXT.bottomBlock;

export const BottomBlock = observer(function BottomBlock() {
  return (
    <section className={styles.bottomBlock}>
      <div className={styles.inner}>
        <div className={styles.logo} />
        <p className={styles.subtitle}>{subtitle}</p>
        <BuyButton />
        <div className={styles.veggies} />
      </div>
    </section>
  );
});
