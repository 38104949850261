import React, { memo } from 'react';
import cn from 'classnames';

import { UCHIRU_LINK } from 'shared/constants';

import { LK_LINK_TEXT, LOGO_IMAGE_LINKS } from './constants';
import { HeaderProps } from './types';
import styles from './Header.module.scss';

export const Header = memo(function Header({ type, isLkLinkHidden, className }: HeaderProps) {
  return (
    <div className={cn(styles.main, styles[`main_type_${type}`], className)}>
      <div className={styles.logo}>
        <a href={UCHIRU_LINK}>
          <img src={LOGO_IMAGE_LINKS[type]} alt="" />
        </a>
      </div>
      {!isLkLinkHidden && (
        <div className={styles.lkLink}>
          <a href={UCHIRU_LINK}>{LK_LINK_TEXT}</a>
        </div>
      )}
    </div>
  );
});
