import React from 'react';

export const TEXT = {
  documentTitle: 'Уроки для мозга',
  titles: {
    mainBanner: 'Уроки<br/>для мозга',
    CourseContent: 'Из чего состоит курс',
    forWhom: 'Для кого этот курс',
    faq: 'Часто задаваемые вопросы',
    whyItWork: 'Почему курс работает',
  },
  buttonBuy: {
    default: {
      text: 'купить',
      link: 'https://uchi.ru/payments/provider?type=htl_zavr_25_year_990_base_2000',
    },
  },
  basePrice: 2000,
  price: 990,
  buttonLoginText: 'войти и купить',
  mainBannerText: 'Чтобы быстрее думать, понимать и запоминать!',
  mainBannerText2: 'Курс для учеников 1-4 классов',
  ForWhomList: [
    {
      text: 'Для детей, которые долго делают домашнее задание',
      imageLink: 'https://static.uchi.ru/files/content-1-4/landings/neuroCourse/icon5.svg',
    },
    {
      text: 'Кому сложно сконцентрироваться и выучить стихотворение или подготовить пересказ',
      imageLink: 'https://static.uchi.ru/files/content-1-4/landings/neuroCourse/icon4.svg',
    },
    {
      text: 'Кто пропускает буквы в словах или пишет их в другую сторону',
      imageLink: 'https://static.uchi.ru/files/content-1-4/landings/neuroCourse/icon2.svg',
    },
    {
      text: 'Не может успокоиться перед выступлением или контрольной работой',
      imageLink: 'https://static.uchi.ru/files/content-1-4/landings/neuroCourse/icon1.svg',
    },
    {
      text: 'И для тех, у кого нет сложностей с учебой, но есть желание развивать когнитивные способности и узнать больше про свой мозг',
      imageLink: 'https://static.uchi.ru/files/content-1-4/landings/neuroCourse/icon3.svg',
    },
  ],
  accordions: [
    {
      header: 'Что я получу после оплаты?',
      content:
        'Неограниченный доступ к курсу со всеми дальнейшими обновлениями. Когда он выйдет полностью, цена может повыситься, но вам не нужно будет ничего доплачивать.',
    },
    {
      header: 'Когда я получу полный доступ к курсу?',
      content:
        'Мы планируем выпустить курс полностью в ноябре. До этого новые задания будут постепенно открываться тем, кто оплатил курс.',
    },
    {
      header: 'Почему вы уверены в эффективности курса?',
      content:
        'При создании материалов мы сочетаем отработанные методики Учи.ру и данные исследований в области нейрофизиологии и нейропсихологии. Каждое задание тестирует группа учеников 1–4 классов — и по результатам мы корректируем курс.',
    },
  ],
  footerBlockText: ['Доступ навсегда', 'Регулярные обновления', 'Скидка на предзаказ'],
  CourseContentText:
    'Тренировки, разработанные методистами и педагогами Учи.ру совместно с психофизиологом и детским нейропсихологом',
  CourseContentList: ['Логическое мышление', 'Память', 'Внимание', 'Творческое мышление'],
  CourseContent: [
    {
      title: 'Интерактивная карта',
      description:
        'Навигация по курсу устроена в виде дерева. Чтобы переключаться между разделами и заданиями, поворачивайте его влево и вправо, двигайтесь вверх и вниз с помощью стрелок и нажимайте на круглые дверцы. Новый уровень открывается после прохождения четырех заданий предыдущего.',
    },
    {
      title: 'Игровые тренажеры',
      description:
        'Основываясь на исследованиях по нейрофизиологии, мы адаптировали задания для учеников младших классов. Ребята могут самостоятельно тренировать память, внимание, логику и творческое мышление.',
      subTitle1: 'Тренажер для развития креативности',
      subTitle2: 'Тренажер для развития внимательности',
    },
    {
      title: 'Дополнительные материалы',
      description:
        'Наглядные карточки с фактами о работе мозга и красочными мультфильмами учат запоминать большой объем информации и концентрироваться.',
    },
    {
      title: 'Нейрогимнастика',
      description:
        'Ведущий и дети показывают на видео простые физические упражнения, которые активизируют межполушарное взаимодействие, тренируют концентрацию, память, внимание и владение телом. В каждом эпизоде до шести упражнений, объединенных историей: морским путешествием, прогулкой по городу и т. д.',
    },
  ],
  CourseContentDiploma: {
    title: 'Диплом в конце курса',
    text: 'Закончив курс, каждый ребенок получит диплом с любимыми героями',
  },
  valuta: 'руб.',
  whyItWork: (
    <>
      Мы&nbsp;используем отработанные методики Учи.ру и&nbsp;подкрепляем их&nbsp;данными
      исследований в&nbsp;области нейрофизиологии и&nbsp;нейропсихологии.
    </>
  ),
};

export const EVENT_BUY = {
  source: 'how_to_learn',
  type: 'Payments_from_Landing',
};

export const EVENT_LOGIN = {
  source: 'how_to_learn',
  type: 'Login_from_Landing',
};

export const LANDING_URL = `https://${process.env.REACT_APP_LANDING_PAGES_SERVICE}/courses/neurocourse`;

export const EVENT_LOAD = {
  source: 'how_to_learn',
  type: 'Landing_Open',
};

export const defaultPaymentInfo = {
  paymentPath: TEXT.buttonBuy.default.link,
  price: TEXT.price,
  basePrice: TEXT.basePrice,
};
