import React, { memo } from 'react';
import cn from 'classnames';
import { getDeviceData } from 'shared/helpers/getDeviceData';
import styles from './index.module.scss';

interface ButtonProps {
  link: string | null;
  btnClassName: string;
  hasPrice?: boolean;
  text: string;
}

export const Button = memo(function Button({ link, btnClassName, hasPrice, text }: ButtonProps) {
  const { touchType } = getDeviceData();
  return (
    <>
      {link && (
        <a href={link} target="_blank" rel="noreferrer">
          <div className={cn(styles[btnClassName], touchType === 'no-touch' && styles.active)}>
            {text}
          </div>
        </a>
      )}
      {hasPrice && link && <p className={styles.price}>990₽</p>}
    </>
  );
});
