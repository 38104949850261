import React from 'react';
import cn from 'classnames';

import { sendEvent } from 'shared/helpers/requests';
import { observer } from 'mobx-react';
import { Button } from '../Button';
import { TEXT, EVENT } from '../../constants';
import { store } from '../../store';

import styles from './TrialButton.module.scss';

interface TrialButtonProps {
  className?: string;
}

export const TrialButton = observer(function TrialButton({ className }: TrialButtonProps) {
  const { hasPremium } = store;

  const onClick = () => {
    sendEvent({
      eventSource: EVENT.source,
      eventType: EVENT.play,
      eventInfo: '',
    });

    window.location.assign(hasPremium ? TEXT.button.play.link : TEXT.button.tryFree.link);
  };

  return (
    <Button onClick={onClick} color="blue" className={cn(styles.trialButton, className)}>
      {hasPremium ? TEXT.button.play.text : TEXT.button.tryFree.text}
    </Button>
  );
});
