import { UCHI_DOMAIN } from '../constants';

// Источник на бэке ↓ или более свежий
// https://github.com/uchiru/uchiru-login/blob/f477afedc04eacf6d5f0c524aa9f66d5535b4103/app/components/api/v3/common/course_premium_collector.rb#L1-L1
type PremiumData = Record<string, boolean | undefined>;

// Идентификаторы курсов в подсистеме премиумов (в других подсистемах могут быть другие).
// Здесь перечислены не все возможные, а только используемые в данном репозитории.
// Источник на бэке ↓ или более свежий
// https://github.com/uchiru/uchiru-login/blob/f477afedc04eacf6d5f0c524aa9f66d5535b4103/app/components/api/v3/common/course_premium_collector.rb#L2-L18
// https://github.com/uchiru/uchiru-login/blob/f477afedc04eacf6d5f0c524aa9f66d5535b4103/app/models/grade.rb#L79-L118
export type PremiumCourseName =
  | 'advanced_math'
  | 'chemistry'
  | 'chess'
  | 'eq'
  | 'game__magic_math'
  | 'harvest_math'
  | 'magic_math_part_2'
  | 'modern_anatomy'
  | 'game__scary_adventures'
  | 'how_to_learn'
  | 'informatics'
  | 'my_emotions'
  | 'read'
  | 'words_battle'
  | 'english_pet';

export const fetchPremiumDataResponse = (courseName: PremiumCourseName) =>
  fetch(`${UCHI_DOMAIN}/api/v3/course_premium?name=${courseName}`, {
    credentials: 'include',
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  });

export const fetchPremiumData = (
  ...args: Parameters<typeof fetchPremiumDataResponse>
): Promise<PremiumData | { error: string }> =>
  fetchPremiumDataResponse(...args).then((r) => r.json());

export const fetchHasPremium = (
  ...args: Parameters<typeof fetchPremiumDataResponse>
): Promise<boolean> =>
  fetchPremiumData(...args).then((data) => {
    if ('error' in data) {
      throw new Error(data.error as string);
    }

    return !!data[args[0]];
  });
