// eslint-disable-next-line prettier/prettier
import React, { memo } from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import styles from './Peculiarities.module.scss';

import { TEXT } from '../../constants';

export const Peculiarities = memo(function Peculiarities() {
  return (
    <section className={styles.peculiaritiesSection}>
      <h2>Особенности</h2>
      <Container fluid="md" className={styles.containerListIcon}>
        <Row>
          {TEXT.listPeculiarities.map(({ linkImg, alt, text }) => (
            <Col
              key={alt}
              md={{ span: 4, offset: 0 }}
              sm={{ span: 4, offset: 0 }}
              xs={{ span: 8, offset: 2 }}
              className={styles.itemIcon}
            >
              <div className={styles.wrapperImage}>
                <img alt={alt} src={linkImg} />
              </div>
              <p className={styles.textItem}>{text}</p>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
});
