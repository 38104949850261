import React, { useRef } from 'react';
import DocumentMeta from 'react-document-meta';
import { observer } from 'mobx-react';
import { fetchPaymentData } from 'shared/api/fetchPaymentData';
import { fetchHasPremium } from 'shared/api/fetchPremiumData';
import { fetchProfileData, isAuthorizedProfile } from 'shared/api/fetchProfileData';
import { Header } from 'shared/components/Header';
import { Footer } from 'shared/components/Footer';
import {
  redirectToPaymentIfInPaymentRedirectChain,
  isInPaymentRedirectChain,
} from 'shared/helpers/paymentRedirect';
import { useEffectOnMount } from 'shared/hooks/useEffectOnMount';
import { FirstScreen, Preview, Description, Reviews, Checkout, FAQ } from './components';
import { META, COURSE_URL } from './constants';
import { store } from './store';
import styles from './MagicMath.module.scss';

export const MagicMath = observer(function MagicMath() {
  const detailsSectionRef = useRef(null);

  useEffectOnMount(() => {
    async function fetchData() {
      const studentProfile = await fetchProfileData();

      if (isAuthorizedProfile(studentProfile)) {
        const [hasPremium, paymentData] = await Promise.all([
          fetchHasPremium('game__magic_math'),
          fetchPaymentData('magic_math'),
        ]);

        redirectToPaymentIfInPaymentRedirectChain({
          paymentUrl: paymentData.paymentPath,
          courseUrl: COURSE_URL,
          hasPremium,
        });

        store.init({
          studentId: studentProfile.id,
          studentParallel: studentProfile.class_book_parallel,
          hasPremium,
          price: paymentData.price,
          basePrice: paymentData.basePrice,
          paymentUrl: paymentData.paymentPath,
        });
      } else {
        store.setIsReadyNotLoggedIn();
      }
    }

    fetchData();
  });

  // Если в цепочке редиректов к платёжке, не рендерить базовое содержимое
  // страницы, пока инфа по оплате не пришла, когда и произойдёт редирект
  if (isInPaymentRedirectChain()) return null;

  return (
    <div>
      <DocumentMeta {...META} />
      <Header type="gray" />
      <div className={styles.content}>
        <FirstScreen detailsSectionRef={detailsSectionRef} />
        <Preview sectionRef={detailsSectionRef} />
        <Description />
        <Reviews />
        <Checkout />
        <FAQ />
      </div>
      <Footer />
    </div>
  );
});
