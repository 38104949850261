import React, { memo } from 'react';

import { TEXT } from '../../constants';
import { FirstScreenProps } from '../../types';
import styles from './FirstScreen.module.scss';

const urlImage = {
  circle_1: 'https://static.uchi.ru/files/content-1-4/landings/planning/circle.svg',
  circle_2: 'https://static.uchi.ru/files/content-1-4/landings/planning/circle.svg',
  symbol_1: 'https://static.uchi.ru/files/content-1-4/landings/planning/symbol_2.svg',
  symbol_2: 'https://static.uchi.ru/files/content-1-4/landings/planning/symbol_3.svg',
  landscape: 'https://static.uchi.ru/files/content-1-4/landings/planning/landscape_new.png',
};

export const FirstScreen = memo(function FirstScreen({
  basePrice,
  price,
  sale,
  isShowPrice,
}: FirstScreenProps) {
  return (
    <section className={styles.firstScreenSection}>
      <div className={styles.innerWrapper}>
        <div className={styles.leftColumn}>
          <h1 className={styles.title}>{TEXT.titles.firstScreen}</h1>
          <div className={styles.subTitle}>{TEXT.firstScreenSubTitle}</div>
          <div className={styles.listCurs}>
            {TEXT.listCurs.map(({ text, icon }) => (
              <div className={styles.itemCurs} key={text}>
                <div className={styles.wrapperIcon}>
                  <img src={icon} alt="icon" />
                </div>
                <div className={styles.textCurs}>{text}</div>
              </div>
            ))}
          </div>
        </div>
        <div className={styles.rightColumn}>
          <div className={styles.circle_1}>
            <img src={urlImage.circle_1} alt="circle" />
          </div>
          <div className={styles.circle_2}>
            <img src={urlImage.circle_2} alt="circle" />
          </div>
          <div className={styles.symbol_1}>
            <img src={urlImage.symbol_1} alt="symbol_2" />
          </div>
          <div className={styles.symbol_2}>
            <img src={urlImage.symbol_2} alt="symbol_3" />
          </div>
          <div className={styles.wrapperImage}>
            <img src={urlImage.landscape} alt="landscape" />
          </div>

          {isShowPrice && (
            <div className={styles.priceBlock}>
              <div className={styles.sale}>{sale}</div>
              <div className={styles.oldPrice}>
                <del>{`${basePrice} ${TEXT.valuta}`}</del>
              </div>
              <div className={styles.price}>{`${price} ${TEXT.valuta}`}</div>
            </div>
          )}
          <div>button</div>
        </div>
      </div>
    </section>
  );
});
