import React, { memo } from 'react';
import cn from 'classnames';
import ReactHtmlParser from 'react-html-parser';
import styles from './index.module.scss';
import * as I from './types/interfaces';
import { ImageWithDescription } from '../ImageWithDescription';

export const HeroSection = memo(function HeroSection({
  textConfig,
  imageConfig,
  className,
  classNameImage,
}: I.HeroSectionProps) {
  const { imageUrl, description } = imageConfig;
  const { title, text } = textConfig;
  const rootStyles = cn(styles.root, className);

  return (
    <div className={rootStyles}>
      <div className={styles.text__block}>
        <h3 className={styles.title}>{ReactHtmlParser(title)}</h3>
        <p className={styles.text}>{ReactHtmlParser(text)}</p>
      </div>
      <ImageWithDescription
        imageUrl={imageUrl}
        description={description}
        className={styles.image__block}
        classNameImage={classNameImage}
      />
    </div>
  );
});
