import React, { memo } from 'react';
import { TEXT, RESULT } from '../../constants';
import { ResultCard } from '../ResultCard';
import styles from './index.module.scss';

export const InGameBlock = memo(function InGameBlock() {
  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>{TEXT.inGameHeader}</div>
      <div className={styles.results}>
        {RESULT.map((result, i) => (
          <ResultCard key={result} text={result} id={i} />
        ))}
      </div>
    </div>
  );
});
