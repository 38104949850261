import React, { memo, useCallback } from 'react';
import cn from 'classnames';
import { sendEvent } from 'shared/helpers/requests';
import { Button } from 'shared/components/Button';
import { goToUchiLoginAndRedirect } from 'shared/helpers/goToUchiLoginAndRedirect';
import { EVENT_LOGIN, LANDING_URL, TEXT } from '../../constants';
import { LoginButtonProps } from './types';
import styles from './LoginButton.module.scss';

const LoginButton = memo(function LoginButton({ className, place }: LoginButtonProps) {
  const clickLoginButton = useCallback(() => {
    sendEvent({
      eventType: EVENT_LOGIN.type,
      eventSource: EVENT_LOGIN.source,
      eventInfo: JSON.stringify({ place }),
    });
    goToUchiLoginAndRedirect(LANDING_URL);
  }, [place]);

  return (
    <div className={cn(styles.containerButtonPrice, className)}>
      <div className={styles.buttonWrapper}>
        <Button
          callback={clickLoginButton}
          className={styles.innerButton}
          defaultOnClick={false}
          text={TEXT.buttonLoginText}
        />
      </div>
    </div>
  );
});

export { LoginButton };
