import React, { memo } from 'react';
import styles from './WhyItWork.module.scss';
import { TEXT } from '../../constants';

export const WhyItWork = memo(function WhyItWork() {
  return (
    <section className={styles.whyItWorkSection}>
      <h2 className={styles.whyItWorkTitle}>{TEXT.titles.whyItWork}</h2>
      <div className={styles.whyItWorkContainer}>{TEXT.whyItWork}</div>
    </section>
  );
});
