import React, { memo, useEffect, useRef, useState } from 'react';
import { Header } from 'shared/components/Header';
import { Footer } from 'shared/components/Footer';
import { useSearchParams } from 'react-router-dom';
import { useEffectOnMount } from 'shared/hooks/useEffectOnMount';
import { fetchHasPremium } from 'shared/api/fetchPremiumData';
import { fetchProfileData, isAuthorizedProfile, ProfileData } from 'shared/api/fetchProfileData';
import { fetchPaymentData } from 'shared/api/fetchPaymentData';
import { UCHIRU_LINK } from 'shared/constants';
import { ApiErrorResponseData } from 'shared/types';
import type * as I from './types/interfaces';
import styles from './index.module.scss';
import { MainBannerSection } from './components/sections/MainBannerSection';
import { AboutSection } from './components/sections/AboutSection';
import { PartitionsSection } from './components/sections/PartitionsSection';
import { DiplomaSection } from './components/sections/DiplomaSection';
import { ReviewsSection } from './components/sections/ReviewsSection';
import { PromotionSection } from './components/sections/PromotionSection';
import { ContextAdvancedMathProvider } from './context';
import { URL_REG_EXP, TITLE } from './constants';

const PRODUCT_NAME = 'advanced_math';

export const AdvancedMath = memo(function AdvancedMath(props: I.AdvancedMathProps) {
  const referrer = useRef(document.referrer);
  const [params] = useSearchParams();
  const from = referrer.current || params.get('from') || UCHIRU_LINK;
  const [validFrom, setValidFrom] = useState(from);
  const [isLoaded, setIsLoaded] = useState(false);
  const [profile, setProfile] = useState<null | ProfileData | ApiErrorResponseData>(null);
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [isPremium, setIsPremium] = useState(false);
  const [paymentLink, setPaymentLink] = useState('');

  useEffect(() => {
    if (from && URL_REG_EXP.test(from)) {
      setValidFrom(from);
    }
  }, [from]);

  useEffectOnMount(() => {
    async function fetchData() {
      if (process.env.NODE_ENV !== 'development') {
        try {
          const profileData = await fetchProfileData();
          setProfile(profileData);
          const isAuthorizedResponse = isAuthorizedProfile(profileData);
          setIsAuthorized(isAuthorizedResponse);

          if (isAuthorizedResponse) {
            const [hasPremium, paymentLinkData] = await Promise.all([
              fetchHasPremium('advanced_math'),
              fetchPaymentData(PRODUCT_NAME),
            ]);
            setIsPremium(hasPremium);
            if (!hasPremium) {
              setPaymentLink(paymentLinkData.paymentPath);
            }
          }
        } catch (error) {
          // eslint-disable-next-line no-console
          console.log('error:', error);
        }
        setIsLoaded(true);
      } else {
        setIsLoaded(true);
      }
    }

    fetchData();
    document.title = TITLE;
  });

  if (!isLoaded) return null;

  // eslint-disable-next-line no-nested-ternary
  const typeLending = isAuthorized ? (isPremium ? 'bought' : 'notBought') : 'unauthorized';

  return (
    <ContextAdvancedMathProvider
      value={{
        type: typeLending,
        from: validFrom,
        profile,
        isAuthorized,
        isPremium,
        paymentLink,
      }}
    >
      <div className={styles.root}>
        <Header className={styles.header} type="gray" />
        <MainBannerSection />
        <AboutSection />
        <PartitionsSection />
        <DiplomaSection />
        <ReviewsSection />
        <PromotionSection />
        <Footer />
      </div>
    </ContextAdvancedMathProvider>
  );
});
