import React, { memo, useState } from 'react';
import { Header } from 'shared/components/Header';
import { Footer } from 'shared/components/Footer';
import { MainBanner } from './Components/MainBanner';
import styles from './index.module.scss';
import { GameNowBlock } from './Components/GameNowBlock';
import { Goals } from './Components/Goals';
import { InGameBlock } from './Components/InGameBlock';
import { Feedback } from './Components/Feedback';
import { FooterBlock } from './Components/FooterBlock';
import { LINK_COURSE, LINK_PLAY, META } from './constants';
import { fetchHasPremium } from '../../../shared/api/fetchPremiumData';
import { fetchProfileData, isAuthorizedProfile } from '../../../shared/api/fetchProfileData';
import { useEffectOnMount } from '../../../shared/hooks/useEffectOnMount';
import { getUchiLoginRedirectUrl } from '../../../shared/helpers/getUchiLoginRedirectUrl';

export const Science = memo(function Science() {
  const [buyHref, setBuyHref] = useState<string | null>(null);
  const [playGameHref, setPlayGameHref] = useState<string>(LINK_PLAY);

  useEffectOnMount(() => {
    document.title = META.title;

    async function fetchData() {
      try {
        const profileData = await fetchProfileData();

        if (isAuthorizedProfile(profileData)) {
          const hasPremium = await fetchHasPremium('chemistry');

          if (hasPremium) {
            setBuyHref(null);
          } else {
            setBuyHref(LINK_COURSE);
          }
        } else {
          setPlayGameHref(getUchiLoginRedirectUrl(LINK_PLAY));
          setBuyHref(getUchiLoginRedirectUrl(LINK_COURSE));
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log('error fetching profile data:', error);
      }
    }

    fetchData();
  });

  return (
    <div className={styles.contentWrapper}>
      <Header type="gray" />
      <div>
        <MainBanner link={buyHref} />
        <GameNowBlock link={playGameHref} />
        <Goals />
        <InGameBlock />
        <Feedback />
        <FooterBlock link={buyHref} />
      </div>
      <Footer />
    </div>
  );
});
