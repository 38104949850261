import React, { memo, useState } from 'react';
import { Header } from 'shared/components/Header';
import { useEffectOnMount } from 'shared/hooks/useEffectOnMount';
import { fetchProfileData, isAuthorizedProfile } from 'shared/api/fetchProfileData';
import { fetchHasPremium } from 'shared/api/fetchPremiumData';
import { getUchiLoginRedirectUrl } from 'shared/helpers/getUchiLoginRedirectUrl';
import { LINK_COURSE, LINK_PLAY, META } from './constants';
import styles from './EnglishPet.module.scss';
import { Roof } from './components/Roof/Roof';
import { Demo } from './components/Demo/Demo';
import { Training } from './components/Training/Training';
import { Feedback } from './components/Feedback';
import { FAQ } from './components/Faq/Faq';
import { Footer } from './components/Footer/Footer';
import { Goals } from './components/Goals';

export const EnglishPet = memo(function EnglishPet() {
  const [buyHref, setBuyHref] = useState<string | null>(null);
  const [playGameHref, setPlayGameHref] = useState<string>(LINK_PLAY);

  useEffectOnMount(() => {
    document.title = META.title;

    async function fetchData() {
      try {
        const profileData = await fetchProfileData();

        if (isAuthorizedProfile(profileData)) {
          const hasPremium = await fetchHasPremium('english_pet');
          setBuyHref(hasPremium ? null : LINK_COURSE);
        } else {
          setPlayGameHref(getUchiLoginRedirectUrl(LINK_PLAY));
          setBuyHref(getUchiLoginRedirectUrl(LINK_COURSE));
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log('error fetching profile data:', error);
      }
    }

    fetchData();
  });

  return (
    <div>
      <Header type="gray" />
      <div className={styles.main}>
        <h1 className={styles.course_name}>Английский со зверятами</h1>
        <Roof link={buyHref} />
        <Demo link={playGameHref} />
        <Training />
        <Goals />
        <Feedback />
        <FAQ />
        <Footer link={buyHref} />
      </div>
    </div>
  );
});
