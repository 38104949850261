import React from 'react';
import { observer } from 'mobx-react';
import { TEXT } from '../../constants';
import styles from './Preview.module.scss';

interface PreviewProps {
  sectionRef: React.RefObject<HTMLDivElement>;
}

export const Preview = observer(function Preview({ sectionRef }: PreviewProps) {
  return (
    <section className={styles.previewSection} ref={sectionRef}>
      <h2 className={styles.title}>{TEXT.titles.preview}</h2>
      <div className={styles.previewFeatures}>
        {TEXT.previewFeatures.map(({ text, imageUrl }) => (
          <div className={styles.previewFeature} key={text}>
            <img className={styles.previewFeatureImage} src={imageUrl} alt={text} />
            <p className={styles.previewFeatureText}>{text}</p>
          </div>
        ))}
      </div>
    </section>
  );
});
