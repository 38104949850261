import React, { memo } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { Container, Row, Col } from 'react-bootstrap';
import { Slider } from 'shared/components/Slider';

import styles from './WhatWillNext.module.scss';

import { TEXT, SLIDERS } from '../../constants';

const itemsSlider = SLIDERS.map(({ alt, link }) => <img alt={alt} src={link} key={link} />);

export const WhatWillNext = memo(function WhatWillNext() {
  return (
    <section className={styles.whatWillNext}>
      <h2 className={styles.titleSection}>{ReactHtmlParser(TEXT.titles.sectionWhatWillNext)}</h2>
      <div className={styles.wrapperTitleBG}>
        <img
          className={styles.imageBG}
          src="https://static.uchi.ru/files/content-1-4/landings/reading/images/bg3_new1.svg"
          alt="bg"
        />
        <h2>{TEXT.titles.whatWillNext}</h2>
      </div>
      <div className={styles.wrapperWhiteSection}>
        <Container fluid="md" className={styles.containerListIcon}>
          <Row>
            {TEXT.listBukvarik.map(({ link, title, text }) => (
              <Col
                key={title}
                md={{ span: 4, offset: 0 }}
                sm={{ span: 4, offset: 0 }}
                xs={{ span: 8, offset: 2 }}
                className={styles.itemIcon}
              >
                <div className={styles.wrapperBukvarik}>
                  <img alt={title} src={link} />
                </div>
                <p className={styles.textWhatWillNext}>{text}</p>
              </Col>
            ))}
          </Row>
        </Container>
        <Container>
          <Row>
            <Col>
              <Slider itemComponents={itemsSlider} />
            </Col>
          </Row>
        </Container>
      </div>
    </section>
  );
});
